import userModel, { UserModel } from './userModel';
import appModel, { AppModel } from './appModel';
import popupsModel, { PopupsModel } from './popupsModel';

export interface StoreModel {
  userModel: UserModel;
  appModel: AppModel;
  popupsModel: PopupsModel;
}

const state: StoreModel = {
  userModel,
  appModel,
  popupsModel,
};

export default state;
