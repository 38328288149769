import {
  Avatar,
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import { SERVER_URL } from '../../utils/constants';
import BlueLogo from '../../assets/AL-Blue-Logo.png';
import { TopCardsType, TopGoaliesType, TopPlayerType } from '../../utils/types';
import { Link } from 'react-router-dom';

interface Props {
  topPlayers: Array<TopPlayerType | TopGoaliesType | TopCardsType>;
  type: 'topScorer' | 'topAssist' | 'topGoalie' | 'topCard';
}

const TopPlayers = ({ topPlayers, type }: Props) => {
  function returnLabel(
    type: 'topScorer' | 'topAssist' | 'topGoalie' | 'topCard'
  ) {
    switch (type) {
      case 'topAssist':
        return { label: 'Assists', key: 'assists' };
      case 'topGoalie':
        return { label: 'In Goals', key: 'inGoals' };
      case 'topScorer':
        return { label: 'Goals', key: 'goals' };
      default:
        return null;
    }
  }

  return (
    <Box>
      {topPlayers?.length ? (
        <Box>
          <Box mt="20px">
            <HStack alignItems="center" justifyContent="space-between">
              <HStack>
                <Box fontSize="sm" fontStyle={'italic'}>
                  TEAM
                </Box>

                <Box fontSize="sm" fontStyle={'italic'} ml="30px">
                  NAME
                </Box>
              </HStack>
              <HStack>
                <Box>MP</Box>
                <Box
                  fontSize="sm"
                  fontStyle={'italic'}
                  textTransform={'uppercase'}
                >
                  {returnLabel(type) ? (
                    returnLabel(type)?.key
                  ) : (
                    <HStack ml="3px" mr="15px">
                      <Box
                        height="15px"
                        width="10px"
                        borderRadius="3px"
                        bg="orange"
                      />
                      <Box
                        height="15px"
                        width="10px"
                        borderRadius="3px"
                        bg="red"
                      />
                    </HStack>
                  )}
                </Box>
              </HStack>
            </HStack>
          </Box>
          {[...topPlayers].map((ts) => {
            const splittedName = ts.team.split(' ');
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_first, _second, ...rest] = splittedName;
            return (
              <Link key={ts.name + ts.team} to={`/player/${ts.playerId}`}>
                <Box bg="gray.100" mt="5px" rounded="md">
                  <HStack
                    alignItems="center"
                    justifyContent="space-between"
                    cursor="pointer"
                    _hover={{ bg: 'teal.50', color: 'inherit', rounded: 'md' }}
                    px="10px"
                  >
                    <HStack alignItems="center">
                      <Flex
                        h={'58px'}
                        w={'58px'}
                        justifyContent="center"
                        alignItems="center"
                        fontSize="12px"
                        textAlign="center"
                      >
                        <Box>
                          {ts.photo || ts.playerPhoto ? (
                            <Image
                              src={SERVER_URL + (ts.photo || ts.playerPhoto)}
                              boxSize={'50px'}
                              objectFit="cover"
                              m="0 auto"
                              cursor="pointer"
                              fallbackSrc={BlueLogo}
                            />
                          ) : (
                            <Avatar size="sm" name={`${ts.name} `} src={''} />
                          )}
                        </Box>
                      </Flex>

                      <Flex
                        flexDirection="column"
                        h="58px"
                        justifyContent="center"
                      >
                        <Flex
                          noOfLines={1}
                          fontSize={'15px'}
                          fontWeight={'bold'}
                          alignItems="center"
                          textTransform={'capitalize'}
                          justifyContent="left"
                        >
                          <Box textAlign={'left'}>{ts.name}</Box>
                        </Flex>
                        <Box fontSize="14">{rest.join(' ')}</Box>
                      </Flex>
                    </HStack>
                    <HStack gap="0">
                      <Box fontWeight="bold">{ts.PLD}</Box>
                      <Box
                        fontWeight="bold"
                        width="50px"
                        textAlign={'right'}
                        pr="10px"
                      >
                        {returnLabel(type) ? (
                          String(
                            ts[
                              returnLabel(type)?.key as keyof (
                                | TopPlayerType
                                | TopGoaliesType
                              )
                            ]
                          )
                        ) : (
                          <HStack ml="17px">
                            <Text>{(ts as TopCardsType).yellowCard}</Text>
                            <Text>{(ts as TopCardsType).redCard}</Text>
                          </HStack>
                        )}
                      </Box>
                    </HStack>
                  </HStack>
                </Box>
              </Link>
            );
          })}
        </Box>
      ) : (
        <Center>Top {type} are not available</Center>
      )}
    </Box>
  );
};

export default TopPlayers;
