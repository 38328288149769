import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Heading,
  HStack,
  Image,
  Stack,
  StackDivider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
// import QRCode from 'react-qr-code';
import { svgExport } from 'svg-in-png';
import HeadingTitle from '../components/HeadingTitle';
import { greenishColor, redishColor } from '../utils/theme';
import Footer from './Footer';
import Sidebar from './Navigation';

import { SERVER_URL } from '../utils/constants';
import BlueLogo from '../assets/theme-icon.png';
import { Participation } from '../hooks/useLeaguesState';
import usePlayers from '../hooks/usePlayers';
import { useStoreActions, useStoreState } from '../store';
import Loader from './Loader';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { sports } from '../utils/Select';

export interface PlayerInterface {
  firstName: string;
  lastName: string;
  id: string;
  nickname: string;
  playerID: string;
  phoneNumber: string;
  hometown: string;
  weight: number;
  height: number;
  position: string;
  dateOfBirth: string;
  secondPosition: string;
  ability: string;
  skill: string;
  preferredFoot: string;
  jerseyName: string;
  jerseyNumber: number;
  kitSize: string;
  email: string;
  isCaptain: boolean;
  paid: boolean;
  verified: boolean;
  bio: string;
  paymentPlan: string;
  name: string;
  photo: {
    url: string;
  };
  assessment: {
    [key in keyof typeof sports]: { skill: string; value: string }[];
  };
  nationalIdImage: {
    url: string;
  };
  participation: Participation[];
  age: number;
}

const Player = () => {
  const { id } = useParams();

  const { players, loading } = usePlayers({
    where: { id: { equals: id } },
  });

  const existingUser: PlayerInterface | null = players?.[0];

  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const returnAssessment = (assessment: { skill: string; value: string }[]) => {
    return assessment.map((skill, index) => {
      return (
        <Tr key={skill.skill + index}>
          <Td textTransform={'capitalize'} fontWeight="bold">
            {skill.skill}
          </Td>
          <Td>{skill.value}</Td>
        </Tr>
      );
    });
  };

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt={{ base: '125px', md: '200px' }}
        display="flex"
        justifyContent={'space-between'}
      >
        {loading && !existingUser ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <Box>
            <Box textAlign={'center'} mb="50px">
              <HeadingTitle>Player Information</HeadingTitle>
            </Box>

            {existingUser ? (
              <Box maxW="1024px" margin="0 auto">
                <HStack
                  alignItems={['center', 'center', 'flex-start', 'flex-start']}
                  flexDir={['column', 'column', 'row', 'row']}
                  gap={['30px', '30px', '20px', '20px']}
                >
                  <Box
                    flex={['0 0 100%', '0 0 100%', '0 0 350px', '0 0 350px']}
                    w={'100%'}
                    padding={['0 20px', '0 20px', '0px', '0px']}
                  >
                    <Card p={'20px 15px'}>
                      <Box textAlign={'center'} mb="20px">
                        <Heading as="h3" fontWeight="bold" fontSize="2xl">
                          <Text
                            as="span"
                            textTransform={'capitalize'}
                            color={redishColor}
                          >
                            {existingUser.firstName} {existingUser.lastName}
                          </Text>{' '}
                        </Heading>
                      </Box>

                      <HStack alignItems={'center'} justifyContent="center">
                        <Box
                          rounded="10px"
                          border="1px"
                          borderColor={borderColor}
                        >
                          <Image
                            src={
                              existingUser.photo?.url
                                ? SERVER_URL + existingUser.photo.url
                                : BlueLogo
                            }
                            width={'200px'}
                            fallbackSrc={BlueLogo}
                          />
                        </Box>
                      </HStack>

                      {/* <Box
                        rounded="10px"
                        border="1px"
                        borderColor={borderColor}
                        maxW="250px"
                        margin="0 auto 20px auto"
                        id="qr-container"
                        width="0"
                        h="0"
                        overflow={'hidden'}
                      >
                        <Box padding="40px">
                          <QRCode
                            size={170}
                            value={`https://amateursleague.com/player/${existingUser.id}`}
                          />
                        </Box>
                      </Box> */}

                      <Box
                        maxW="fit-content"
                        textAlign={'center'}
                        margin="0 auto"
                      >
                        <HStack justifyContent={'center'}>
                          <Button
                            onClick={() => {
                              svgExport({
                                id: 'qr-container',
                                titleToExport: `qrcode-${existingUser.firstName}-${existingUser.lastName}`,
                                options: {
                                  size: { width: 1500, height: 1500 },
                                },
                              });
                            }}
                          >
                            Download QR Code
                          </Button>
                        </HStack>
                      </Box>
                    </Card>

                    <Card mt="15px">
                      <CardBody>
                        <Heading
                          as="h3"
                          fontSize="lg"
                          textAlign="center"
                          mb="20px"
                        >
                          Participations
                        </Heading>
                        <Stack divider={<StackDivider />}>
                          {existingUser?.participation
                            ?.filter(
                              (part) =>
                                !part.name
                                  .toLowerCase()
                                  .includes('transfer market') ||
                                !part.name.toLowerCase().includes('transfer')
                            )
                            .map((part) => {
                              return (
                                <Box
                                  py="5px"
                                  textDecoration={'underline'}
                                  color={greenishColor}
                                  fontWeight="bold"
                                >
                                  <Link to={`/team/${part.id}`}>
                                    <HStack>
                                      <Image
                                        src={
                                          SERVER_URL +
                                          part?.teams?.[0]?.teamLogo?.url
                                        }
                                        fallbackSrc={BlueLogo}
                                        boxSize="35px"
                                      />
                                      <Text>{part.name}</Text>
                                    </HStack>
                                  </Link>
                                </Box>
                              );
                            })}
                        </Stack>
                      </CardBody>
                    </Card>
                  </Box>
                  <Box
                    flex="1"
                    w={'100%'}
                    padding={['0 20px', '0 20px', '0px', '0px']}
                  >
                    <Card>
                      <Tabs colorScheme="teal">
                        <TabList>
                          <Tab>Player Information</Tab>
                          <Tab>Sports Assesssments</Tab>
                        </TabList>

                        <TabPanels>
                          <TabPanel>
                            <TableContainer>
                              <Table variant="simple">
                                <Tbody>
                                  {Object.keys(existingUser).map((key) => {
                                    const {
                                      participation,
                                      nationalIdImage,
                                      photo,
                                      assessment,
                                      ...destructured
                                    } = existingUser;
                                    if (
                                      key === '__typename' ||
                                      key === 'photo' ||
                                      key === 'participation' ||
                                      key === 'id' ||
                                      key === 'nationalIdImage' ||
                                      key === 'photo' ||
                                      key === 'assessment'
                                    ) {
                                      return null;
                                    }

                                    if (
                                      key === 'verified' ||
                                      key === 'paid' ||
                                      key === 'isCaptain'
                                    ) {
                                      return (
                                        <Tr key={key}>
                                          <Td
                                            textTransform={'capitalize'}
                                            fontWeight="bold"
                                          >
                                            {key.replace(
                                              /([a-z])([A-Z])/g,
                                              '$1 $2'
                                            )}
                                          </Td>
                                          <Td>
                                            {existingUser[
                                              key as keyof typeof existingUser
                                            ]
                                              ? 'Yes'
                                              : 'No'}
                                          </Td>
                                        </Tr>
                                      );
                                    }
                                    return (
                                      <Tr key={key}>
                                        <Td
                                          textTransform={'capitalize'}
                                          fontWeight="bold"
                                        >
                                          {key === 'id'
                                            ? 'Security Code'
                                            : key.replace(
                                                /([a-z])([A-Z])/g,
                                                '$1 $2'
                                              )}
                                        </Td>
                                        <Td>
                                          {
                                            existingUser[
                                              key as keyof typeof destructured
                                            ]
                                          }
                                        </Td>
                                      </Tr>
                                    );
                                  })}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </TabPanel>
                          <TabPanel>
                            {existingUser?.assessment && (
                              <Tabs colorScheme="teal">
                                <TabList>
                                  {Object.keys(existingUser.assessment).map(
                                    (key: string) => {
                                      return (
                                        <Tab
                                          key={key}
                                          textTransform={'capitalize'}
                                        >
                                          {key}
                                        </Tab>
                                      );
                                    }
                                  )}
                                </TabList>

                                <TabPanels>
                                  {Object.keys(existingUser.assessment).map(
                                    (assessment: string) => {
                                      return (
                                        <TabPanel key={assessment + 'tble'}>
                                          <TableContainer>
                                            <Table variant="simple">
                                              <Tbody>
                                                {returnAssessment(
                                                  existingUser.assessment[
                                                    assessment as keyof typeof sports
                                                  ]
                                                )}
                                              </Tbody>
                                            </Table>
                                          </TableContainer>
                                        </TabPanel>
                                      );
                                    }
                                  )}
                                </TabPanels>
                              </Tabs>
                            )}
                          </TabPanel>
                        </TabPanels>
                      </Tabs>
                    </Card>
                  </Box>
                </HStack>
              </Box>
            ) : (
              <Center>Player not found!</Center>
            )}
          </Box>
        )}

        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default Player;
