import { useState } from 'react';
import { MatchInterface } from '../../../utils/types';
import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  Select,
} from '@chakra-ui/react';
import Ranking from './Ranking';
import KnockoutStage from '../KnockoutStage';
import StandAloneStandings from './StandAloneStandings';
import Match from './Match';
// import { officeTypeMatch } from '../../../utils/constants';
import BlueLogo from '../../../assets/AL-Blue-Logo.png';
import DellLogo from '../../../assets/dell-logo.png';
import { useSearchParams } from 'react-router-dom';
import { DellComps } from '../../../utils/Select';

const WideView = ({
  matches,
  allMatches,
}: {
  matches: MatchInterface[];
  allMatches?: MatchInterface[];
}) => {
  const [topView] = useState(['Standing', 'Matches', 'Live']);
  const [bottomView] = useState(['KnockoutStage', 'Ranking']);

  const [searchParams, setSearchParams] = useSearchParams();

  const returnTopView = () => {
    return (
      <HStack
        w="full"
        h={'100%'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        pt={3}
      >
        {topView.includes('Standing') && (
          <Box flex="1" p={3} boxShadow="md" h={'92vh'} pb={'3rem'}>
            <Heading fontSize="2xl" mb="5">
              Standing
            </Heading>
            <Box maxH="100%" overflowY="auto">
              {matches?.length ? (
                <StandAloneStandings
                  matches={matches}
                  removeFP
                  isFull
                  removeLinks={false}
                />
              ) : (
                <Center>No Standing Found</Center>
              )}
            </Box>
          </Box>
        )}
        {topView.includes('Matches') && (
          <Box flex="1" p={3} boxShadow="md" h={'92vh'} pb={'3rem'}>
            <Heading fontSize="2xl" mb="5">
              Matches
            </Heading>
            <Box maxH="100%" overflowY="auto">
              {matches?.length ? (
                matches?.map((m) => <Match allowLinks match={m} key={m.id} />)
              ) : (
                <Center>No Matches Found</Center>
              )}
            </Box>
          </Box>
        )}
        {/* {topView.includes('OverAll') && (
          <Box flex="1" p={3} boxShadow="md" h={'92vh'} pb={'3rem'}>
            <Heading fontSize="2xl" mb="5">
              OverAll Points
            </Heading>
            <Box maxH="100%" overflowY="auto">
              {matches?.length ? (
                <OverAll matches={matches} />
              ) : (
                <Center>No Data Found</Center>
              )}
            </Box>
          </Box>
        )} */}
        {topView.includes('Live') && (
          <Box flex="1" p={3} boxShadow="md" h={'92vh'} pb={'3rem'}>
            <Heading fontSize="2xl" mb="5">
              Live Matches
            </Heading>
            <Box maxH="100%" overflowY="auto">
              {allMatches?.filter((m) => m.isMatchLive).length ? (
                allMatches
                  ?.filter((m) => m.isMatchLive)
                  .map((m) => (
                    <Match match={m} key={m.id + 'live'} addType allowLinks />
                  ))
              ) : (
                <Center>No Live Matches Found</Center>
              )}
            </Box>
          </Box>
        )}
      </HStack>
    );
  };

  const returnBottomView = () => {
    return (
      <HStack
        w="full"
        h={'100%'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        pt={3}
      >
        {bottomView.includes('KnockoutStage') && (
          <Box flex="1" p={3} boxShadow="md" h={'94vh'} pb={'3rem'}>
            <Heading fontSize="2xl" mb="5">
              Knockout
            </Heading>
            <Box maxH="100%" overflowY="auto">
              {matches?.filter((m: MatchInterface) => m.stage !== 'Group Stage')
                ?.length ? (
                <Flex justifyContent="center" w="full">
                  <KnockoutStage
                    matches={matches?.filter(
                      (m: MatchInterface) => m.stage !== 'Group Stage'
                    )}
                  />
                </Flex>
              ) : (
                <Center>No Data Found</Center>
              )}
            </Box>
          </Box>
        )}

        {bottomView.includes('Ranking') && (
          <Box flex="1" p={3} boxShadow="md" h={'94vh'} pb={'3rem'}>
            <Heading fontSize="2xl" mb="5">
              Ranking
            </Heading>
            <Box maxH="100%" overflowY="auto">
              <Box>
                <Ranking
                  matches={matches?.filter((m) => !m.stage.includes('Group'))}
                />
              </Box>
            </Box>
          </Box>
        )}
      </HStack>
    );
  };
  return (
    <Box maxH="100vh" minH="100vh" bg="white">
      <Box boxShadow={'md'} px="5" h={'5vh'} display="flex" alignItems="center">
        <Flex alignItems="center" justifyContent="space-between" w="full">
          <Image src={BlueLogo} w={'120px'} />
          <HStack w="100%" alignItems="center" maxW="400px">
            <Box w="120px">Sport Type</Box>
            <Select
              fontWeight={'bold'}
              fontSize="xl"
              value={searchParams.get('type') || 'Volley'}
              onChange={(e) => {
                searchParams.set('type', e.target.value);
                setSearchParams(searchParams);
              }}
            >
              {DellComps?.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>{' '}
          </HStack>
          <Box overflow={'hidden'} display="flex" alignItems="center" h="53px">
            <Image
              src={DellLogo}
              w={'450px'}
              right="-70px"
              position="relative"
            />
          </Box>
        </Flex>
      </Box>

      <Box w="full" h={'93vh'} px="5">
        {returnTopView()}
      </Box>
      <Box w="full" h={'95vh'} px="5">
        {returnBottomView()}
      </Box>
    </Box>
  );
};

export default WideView;
