import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAdminState from '../../hooks/useAdminState';
import { timeout } from '../../utils/helper';
import { greenishColor } from '../../utils/theme';

export default function Login() {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { authenticatedUser, adminLogin } = useAdminState();

  const toast = useToast();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(false);
    try {
      const res = await adminLogin({
        variables: { email: email?.toLowerCase(), password },
      });
      if (res?.data?.authenticateUserWithPassword?.message) {
        throw Error('Authentication failed: Wrong Email or password');
      }
      if (res?.data?.authenticateUserWithPassword) {
        toast({
          title: 'Welcome back, redirecting to your account ✌️',
          status: 'success',
          isClosable: true,
        });
        await timeout(2000);
        navigate(`/admin/dashboard`);
        setIsSubmitting(false);
      } else {
        setError(true);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: error.message,
        status: 'error',
        isClosable: true,
      });
      setIsSubmitting(false);
    }
  };
  if (authenticatedUser) {
    navigate('/admin/dashboard');
  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Sign in to your account</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            to manage your admin{' '}
            <Text as="span" color={greenishColor}>
              dashboard
            </Text>{' '}
            ✌️
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email" isInvalid={error}>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError(false);
                }}
              />
            </FormControl>
            <FormControl id="securityCode" isInvalid={error}>
              <FormLabel>Your Password</FormLabel>
              <Input
                value={password}
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError(false);
                }}
              />
              {error ? (
                <FormErrorMessage>Wrong Email or password</FormErrorMessage>
              ) : null}
            </FormControl>

            <Stack spacing={10}>
              <Button
                mt="10px"
                bg={greenishColor}
                color={'white'}
                rounded="full"
                _hover={{
                  bg: greenishColor,
                }}
                loadingText={'Submitting...'}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={handleSubmit}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
