import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { Box, Center, Image, Link, useMediaQuery } from '@chakra-ui/react';
import HeadingTitle from '../../../components/HeadingTitle';
import { Fetch_Home_ContentQuery } from '../../../__generated__/graphql';
import { SERVER_URL } from '../../../utils/constants';
import { Autoplay } from 'swiper/modules';
import './main.css';

const Partners = ({ data }: { data: Fetch_Home_ContentQuery | undefined }) => {
  const [mediaMax767] = useMediaQuery('(max-width: 767px)');

  const SwiperConfig: SwiperProps = {
    speed: 3000,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    modules: [Autoplay],
    loop: true,
    slidesPerView: mediaMax767 ? 3 : 5,
    watchSlidesProgress: true,
    spaceBetween: 27,
    grabCursor: true,
  };

  return (
    <Box margin="30px auto 80px" padding="0 20px">
      <Center>
        <HeadingTitle>PARTNERS</HeadingTitle>
      </Center>

      <Box mt="80px">
        {data?.homePage?.partners?.length ? (
          <Box className="partners">
            <Swiper {...SwiperConfig}>
              {data?.homePage?.partners?.map((p, i) => {
                return (
                  <SwiperSlide key={i + 'partner'} virtualIndex={i}>
                    <Link>
                      <Box boxSize={['100px', '150px', '200px', '250px']}>
                        <Image src={SERVER_URL + p?.image?.url} />
                      </Box>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Partners;
