import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Text,
} from '@chakra-ui/react';
import RedCard from '../../components/Icons/RedCard';
import YellowCard from '../../components/Icons/YellowCard';
import Referee from '../../components/Icons/Referee';
import GoalIcon from '../../components/Icons/GoalIcon';
import Sub from '../../components/Icons/Sub';
import { MatchInterface } from '../../utils/types';
import dayjs from 'dayjs';
import {
  isSecondHalfStarted,
  now,
  returnDate,
  returnTime,
  returnTimeAnyway,
} from '../../utils/helper';
import { FaPlus } from 'react-icons/fa';
import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';

interface Props {
  match: MatchInterface;
}
const MatchProgress = ({ match }: Props) => {
  const returnAllProgress = () => {
    if (match?.actionDetails?.length > 0) {
      const copy = [...match?.actionDetails];
      const sorted = copy
        .map((x) => {
          const modTime = match?.stage === 'Group Stage' ? 40 : 45;
          const fixedTime = x.time.includes('+')
            ? String(
                Number(x.time.split('+')[0]) + Number(x.time.split('+')[1])
              )
            : x.time;
          return !x.isFirstHalf
            ? {
                ...x,
                time:
                  parseInt(x.time) > 45
                    ? fixedTime
                    : String(Number(fixedTime) + modTime),
              }
            : x;
        })
        .sort((a, b) => {
          return parseInt(a.time) - parseInt(b.time);
        });

      const firstHalfActions = sorted.filter((x) => x.isFirstHalf);
      const secondHalfActions = sorted.filter((x) => !x.isFirstHalf);
      const firstHalfScore = `${
        firstHalfActions.filter(
          (x) =>
            (x.type === 'Goal' && x.isHomeTeam && !x.isOwnGoal) ||
            (x.type === 'Goal' && !x.isHomeTeam && x.isOwnGoal)
        ).length
      } - ${
        firstHalfActions.filter(
          (x) =>
            (x.type === 'Goal' && !x.isHomeTeam && !x.isOwnGoal) ||
            (x.type === 'Goal' && x.isHomeTeam && x.isOwnGoal)
        ).length
      }`;
      const secondHalfScore = `${
        secondHalfActions.filter(
          (x) =>
            (x.type === 'Goal' && x.isHomeTeam && !x.isOwnGoal) ||
            (x.type === 'Goal' && !x.isHomeTeam && x.isOwnGoal)
        ).length
      } - ${
        secondHalfActions.filter(
          (x) =>
            (x.type === 'Goal' && !x.isHomeTeam && !x.isOwnGoal) ||
            (x.type === 'Goal' && x.isHomeTeam && x.isOwnGoal)
        ).length
      }`;
      return (
        <>
          {match?.isMatchEnded ? (
            <Box
              textAlign={'center'}
              mt={'25px'}
              fontSize="14px"
              fontWeight="bold"
            >
              {`Second Half ${secondHalfScore}`}
            </Box>
          ) : null}

          {secondHalfActions
            .map((progress) => {
              return returnProgress(progress);
            })
            .reverse()}
          <Box
            textAlign={'center'}
            mt={'25px'}
            fontSize="14px"
            fontWeight="bold"
          >
            {isSecondHalfStarted(match?.secondHalfStartTime)
              ? `First Half ${firstHalfScore}`
              : ''}
          </Box>

          {firstHalfActions
            .map((progress) => {
              return returnProgress(progress);
            })
            .reverse()}
        </>
      );
    }
  };
  const returnProgress = (progress: any) => {
    if (!progress) return null;
    if (progress.type.includes('Card')) {
      return (
        <HStack
          alignItems="center"
          mt={'25px'}
          key={Math.random() + progress.time}
          dir="ltr !important"
        >
          <Box w={'calc(50%)'} textAlign="right" fontSize="xs" h={'100%'}>
            {progress.isHomeTeam ? progress.name : progress.time + '`'}
            {progress.isHomeTeam &&
            progress.type === 'Red Card' &&
            !progress.isDirectRedCard ? (
              <Box color="red" fontSize="11px" fontWeight="bold">
                Second Yellow Card
              </Box>
            ) : null}
          </Box>
          <Box w={'60px'} textAlign="center">
            {progress.type.toLowerCase() === 'yellow card' ? (
              <YellowCard boxSize="20px" />
            ) : (
              <RedCard boxSize={'20px'} />
            )}
          </Box>
          <Box w={'calc(50%)'} textAlign="left" fontSize="xs">
            {!progress.isHomeTeam ? progress.name : progress.time + '`'}
            {!progress.isHomeTeam &&
            progress.type === 'Red Card' &&
            !progress.isDirectRedCard ? (
              <Box color="red" fontSize="11px" fontWeight="bold">
                Second Yellow Card
              </Box>
            ) : null}
          </Box>
        </HStack>
      );
    }

    if (progress.type === 'Goal') {
      return (
        <HStack
          alignItems="center"
          mt={'25px'}
          key={Math.random() + progress.time}
          dir="ltr !important"
        >
          <Box w={'calc(50%)'} textAlign="right" fontSize="xs" h={'100%'}>
            {progress.isHomeTeam ? progress.name : progress.time + '`'}
            {progress.isHomeTeam && progress.assistName ? (
              <Box fontSize="11px" color="green">
                {progress.assistName}
              </Box>
            ) : null}
            {progress.isHomeTeam && progress.isOwnGoal ? (
              <Box fontSize="11px" fontWeight="bold" color="red">
                Own Goal
              </Box>
            ) : null}
            {progress.isHomeTeam && progress.isPenaltyGoal ? (
              <Box fontSize="11px" fontWeight="bold" color="green">
                Penalty Goal
              </Box>
            ) : null}
          </Box>
          <Box w={'60px'} textAlign="center" position="relative">
            <GoalIcon boxSize="20px" color={progress.isOwnGoal ? 'red' : ''} />
            {progress.isPenaltyGoal ? (
              <Box
                position="absolute"
                w="15px"
                h="15px"
                bg="orange.300"
                color="black"
                fontWeight="bold"
                rounded="full"
                fontSize="xs"
                textAlign={'center'}
                top="15px"
                ml="25px"
              >
                P
              </Box>
            ) : null}
          </Box>
          <Box w={'calc(50%)'} textAlign="left" fontSize="xs">
            {!progress.isHomeTeam ? progress.name : progress.time + '`'}

            {!progress.isHomeTeam && progress.assistName ? (
              <Box fontSize="11px" color="green">
                {progress.assistName}
              </Box>
            ) : null}
            {!progress.isHomeTeam && progress.isOwnGoal ? (
              <Box fontSize="11px" fontWeight="bold" color="red">
                Own Goal
              </Box>
            ) : null}
            {!progress.isHomeTeam && progress.isPenaltyGoal ? (
              <Box fontSize="11px" fontWeight="bold" color="green">
                Penalty Goal
              </Box>
            ) : null}
          </Box>
        </HStack>
      );
    }

    if (progress.type === 'Penalty Miss') {
      return (
        <HStack
          alignItems="center"
          mt={'25px'}
          key={Math.random() + progress.time}
          dir="ltr !important"
        >
          <Box w={'calc(50%)'} textAlign="right" fontSize="xs" h={'100%'}>
            {progress.isHomeTeam ? progress.name : progress.time + '`'}
          </Box>
          <Box w={'60px'} textAlign="center" position="relative">
            <CloseIcon color="red" boxSize="3" />
          </Box>
          <Box w={'calc(50%)'} textAlign="left" fontSize="xs">
            {!progress.isHomeTeam ? progress.name : progress.time + '`'}
          </Box>
        </HStack>
      );
    }

    if (progress.type === 'Substitution') {
      const sub = progress.name;
      const subArray = sub.split(' - ');
      return (
        <HStack
          alignItems="center"
          mt={'25px'}
          key={Math.random() + progress.time}
          dir="ltr !important"
        >
          <Box w={'calc(50%)'} textAlign="right" fontSize="xs" h={'100%'}>
            {progress.isHomeTeam ? (
              <>
                <Text fontWeight="bold">{subArray[0]}</Text>
                <Text>{subArray[1]}</Text>
              </>
            ) : (
              progress.time + '`'
            )}
          </Box>
          <Box w={'60px'} textAlign="center">
            <HStack justifyContent={'center'} alignItems="center" spacing="2px">
              <Sub boxSize="20px" />
              {progress.isInjurySub ? <FaPlus color={'red'} size={14} /> : null}
            </HStack>
          </Box>
          <Box w={'calc(50%)'} textAlign="left" fontSize="xs">
            {!progress.isHomeTeam ? (
              <>
                <Text fontWeight="bold">{subArray[0]}</Text>
                <Text>{subArray[1]}</Text>
              </>
            ) : (
              progress.time + '`'
            )}
          </Box>
        </HStack>
      );
    }
    return null;
  };

  const returnPenalties = () => {
    if (match?.penalties?.length > 0) {
      const penaltiesCopy = [...match?.penalties];
      // combine objects by penaltyNumber and isHomeTeam
      const combined = penaltiesCopy.reduce((acc: any, curr) => {
        const key = `${curr.penaltyNumber}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(curr);
        return acc;
      }, {});

      const homeTeamPenalties = penaltiesCopy.filter(
        (x) => x.isHomeTeam && x.type === 'Scored'
      );
      const awayTeamPenalties = penaltiesCopy.filter(
        (x) => !x.isHomeTeam && x.type === 'Scored'
      );
      return (
        <Box>
          <Center fontWeight="bold">{`Penalies (${homeTeamPenalties.length}-${awayTeamPenalties.length})`}</Center>
          {Object.keys(combined)
            .reverse()
            .map((key: string, index: number) => {
              const penaltyArr = [...combined[key]].sort((a, b) =>
                a.isHomeTeam ? -1 : 1
              );

              return (
                <HStack
                  alignItems="center"
                  mt={'25px'}
                  key={Math.random() + index}
                  dir="ltr !important"
                >
                  <Box
                    w={'calc(50%)'}
                    textAlign="right"
                    fontSize="xs"
                    h={'100%'}
                  >
                    <Box>
                      <HStack justifyContent={'flex-end'}>
                        <Text fontWeight="bold">{penaltyArr?.[0]?.name}</Text>
                        {penaltyArr?.[0]?.type === 'Scored' ? (
                          <CheckCircleIcon color="green" boxSize="4" />
                        ) : (
                          <CloseIcon color="red" boxSize="3" />
                        )}
                      </HStack>
                    </Box>
                  </Box>
                  <Box w={'60px'} textAlign="center">
                    <HStack
                      justifyContent={'center'}
                      alignItems="center"
                      spacing="2px"
                    >
                      <Flex
                        w="25px"
                        h="25px"
                        rounded="full"
                        bg="orange.400"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {key}
                      </Flex>
                    </HStack>
                  </Box>
                  <Box w={'calc(50%)'} textAlign="left" fontSize="xs">
                    {penaltyArr?.[1] ? (
                      <Box>
                        <HStack position="relative">
                          {penaltyArr?.[1]?.type === 'Scored' ? (
                            <CheckCircleIcon color="green" boxSize="4" />
                          ) : (
                            <CloseIcon color="red" boxSize="3" />
                          )}
                          <Text fontWeight="bold">{penaltyArr?.[1]?.name}</Text>{' '}
                        </HStack>
                      </Box>
                    ) : null}
                  </Box>
                </HStack>
              );
            })}
        </Box>
      );
    }
    return null;
  };
  return (
    <Box>
      <Heading as="h4" fontSize="16px" textAlign="center" my="25px">
        {'Match Progress'}
        <Box
          width="30px"
          h="3px"
          rounded="lg"
          bg={'teal.400'}
          margin="10px auto"
        />
      </Heading>

      {match?.actionDetails?.length > 0 ? (
        <>
          <Box>{returnPenalties()}</Box>
          <Box>{returnAllProgress()}</Box>
        </>
      ) : (
        <Center>-</Center>
      )}

      <Box textAlign="center" mt="30px">
        <Referee boxSize="35px" />
        <Text fontSize="sm" opacity="0.78" mt="5px">
          {dayjs(now).isAfter(dayjs(match?.matchDate))
            ? `Match Started  ${returnDate(
                match?.firstHalfStartTime
              )} ${returnTimeAnyway(match?.firstHalfStartTime)}`
            : `Match Starts ${returnDate(match?.matchDate)} ${returnTime(
                match?.matchDate
              )}`}
        </Text>
      </Box>
      <Divider m={'40px 0 40px 0'} />
    </Box>
  );
};

export default MatchProgress;
