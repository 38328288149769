import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Stack,
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import Sidebar from './Navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStoreActions, useStoreState } from '../store';
import { redishColor } from '../utils/theme';
import usePlayerInfo from '../hooks/usePlayerInfo';
import Footer from './Footer';
import NotFound from './NotFound';
import PaymentInvoice from './PaymentInvoice';
import { verifyHmac } from '../utils/api';
import useEffectOnce from '../hooks/useEffectOnce';
import { timeout } from '../utils/helper';
import Loader from './Loader';

const verifyArray =
  'amount_cents,created_at,currency,error_occured,has_parent_transaction,id,integration_id,is_3d_secure,is_auth,is_capture,is_refunded,is_standalone_payment,is_voided,order,owner,pending,source_data.pan,source_data.sub_type,source_data.type,success';

const PaymentState = () => {
  const playerInfo = usePlayerInfo();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const params = useMemo(() => {
    const obj: any = {};
    searchParams.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }, [searchParams]);

  const neededHMACParams = useMemo(() => {
    return verifyArray.split(',').map((key: string) => {
      return params[key];
    });
  }, [params]);

  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  const verifyHash = useCallback(async () => {
    if (params) {
      if (!params?.hmac || !neededHMACParams.length)
        throw new Error('invalied hmac params');
      const { hmac } = params;
      try {
        setIsLoading(true);
        await timeout(1000);
        setIsLoading(false);
        setIsVerified(false);
        setError(false);
        const response = await verifyHmac(hmac, neededHMACParams.join(''));

        if (response.status !== 200) {
          setError(true);
          return;
        }
        setIsVerified(true);
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setIsLoading(false);
        setIsVerified(false);
      }
    }
  }, [neededHMACParams, params]);

  useEffectOnce(() => {
    verifyHash();
  });

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt={{ base: '125px', md: '200px' }}
        display="flex"
        justifyContent={'space-between'}
      >
        {!playerInfo?.id ? (
          <NotFound />
        ) : (
          <Box maxW="600px" margin="0 auto 0 auto" p="15px">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {(params?.success === 'false' &&
                  params?.pending === 'false' &&
                  !isVerified) ||
                error ? (
                  <Box>
                    <Alert status="error">
                      <AlertIcon />
                      <AlertTitle>Payment failed!</AlertTitle>
                      <AlertDescription>
                        Something happened during your payment.
                      </AlertDescription>
                    </Alert>
                  </Box>
                ) : params?.success === 'false' &&
                  params?.pending === 'true' &&
                  isVerified &&
                  !error ? (
                  <Box>
                    {' '}
                    <Alert status="info">
                      <AlertIcon />
                      <AlertTitle>Payment Pending!</AlertTitle>
                      <AlertDescription>
                        Your payment is pending confirmation this might take
                        awhile to confirm, Do not worry once your payment is
                        successful we will notify you by mail.
                      </AlertDescription>
                    </Alert>
                  </Box>
                ) : (
                  <Box>
                    <PaymentInvoice
                      params={{ ...params, email: playerInfo?.email }}
                    />
                    <Box textAlign="center" mt="30px">
                      <Button
                        variant={'link'}
                        color={redishColor}
                        as="a"
                        href="/"
                      >
                        Go to Home
                      </Button>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default PaymentState;
