import { useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FETCH_APPLICATION_DATA } from '../utils/queries';
import Account from './Account';
import Admin from './Admin';
import AdminDashboard from './Admin/AdminDashboard';
import MatchData from './Admin/MatchData';
import Contact from './Contact';
import ForgotPassword from './ForgotPassword';
import Home from './Home';
import JoinedLeagues from './JoinedLeagues';
import League from './League';
import MatchPage from './League/MatchPage';
import LeagueRules from './LeagueRules';
import Login from './Login';
import Player from './Player';
import Register from './Register';
import RegistrationPage from './RegistrationPage';
import SeasonDetails from './SeasonDetails';
import SeasonPopup from './SeasonPopup';
import TeamPage from './TeamPage';
import Teams from './Teams';
import TransferMarket from './TransferMarket';
import Pricing from './Pricing';
import RegisterPopup from './RegisterPopup';
import PaymentState from './PaymentState';
import Registration from './League/Tournaments/Registration';
import InvoicePage from './InvoicePage';
import Thankyou from './Thankyou';

const Shared = () => {
  useQuery(FETCH_APPLICATION_DATA);
  return (
    <Box>
      <SeasonPopup />
      <RegisterPopup />
      <BrowserRouter>
        <Routes>
          <Route element={<Register />} path="/register" />
          <Route
            element={<Registration />}
            path="/register/tournaments/:comp"
          />
          <Route
            element={<RegistrationPage />}
            path="/register/:type/:plan/:comp"
          />
          <Route
            element={<RegistrationPage />}
            path="/register/:type/:plan/:partCode/:comp"
          />
          <Route element={<Thankyou />} path="/thankyou" />
          <Route element={<PaymentState />} path="/payment_state" />
          <Route element={<Account />} path="/account" />
          <Route element={<League />} path="/league" />
          <Route element={<InvoicePage />} path="/invoice/:id" />
          <Route element={<League />} path="/league/:comp" />
          <Route element={<MatchPage />} path="/match/:id" />
          <Route element={<Pricing />} path="/payments" />
          <Route element={<Pricing />} path="/payments/:comp" />
          <Route element={<ForgotPassword />} path="/forgot-password" />
          <Route element={<SeasonDetails />} path="/season-details" />
          <Route element={<LeagueRules />} path="/league-rules" />
          <Route element={<Login />} path="/login" />
          <Route element={<Player />} path="/player/:id" />
          <Route element={<Contact />} path="/contact" />
          <Route element={<TeamPage />} path="/team/:id" />
          <Route element={<Teams />} path="/standings" />
          <Route element={<TransferMarket />} path="/transfer-market" />
          <Route element={<JoinedLeagues />} path="/joined-leagues" />
          <Route element={<Admin />} path="/admin" />
          <Route element={<AdminDashboard />} path="/admin/dashboard" />
          <Route element={<MatchData />} path="/admin/dashboard/:id" />

          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
};

export default Shared;
