import { Box, Flex } from '@chakra-ui/react';
import { MatchInterface } from '../../utils/types';

interface Props {
  match: MatchInterface;
}
const Stats = ({ match }: Props) => {
  const percentageCalc = (home: string, away: string) => {
    const precentage = (parseInt(home) / parseInt(away)) * 100 + '%';
    return precentage;
  };
  return (
    <Box>
      {match && match?.statistics?.length > 0 ? (
        <Box>
          {match.statistics.map((x: any) => (
            <Box mt={'1rem'} key={x.type}>
              <Flex alignItems="center" m={'25px 0 5px 0'}>
                <Box>{x.homeTeam}</Box>
                <Box flexGrow={1} textAlign="center" fontSize="14px">
                  {x.type}
                </Box>
                <Box>{x.awayTeam}</Box>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between">
                <Box
                  h={'8px'}
                  w={'48%'}
                  bg={'gray.300'}
                  position="relative"
                  borderRadius="4px"
                >
                  <Box
                    h={'100%'}
                    position="absolute"
                    top="0"
                    left={'0'}
                    borderRadius="4px"
                    bg={'#6b9fff'}
                    style={{
                      width:
                        parseInt(x.homeTeam) > parseInt(x.awayTeam)
                          ? '100%'
                          : percentageCalc(x.homeTeam, x.awayTeam),
                    }}
                  />
                </Box>
                <Box
                  h={'8px'}
                  w={'48%'}
                  bg={'gray.300'}
                  position="relative"
                  borderRadius="4px"
                >
                  <Box
                    h={'100%'}
                    position="absolute"
                    top="0"
                    right={'0'}
                    borderRadius="4px"
                    bg={'#f16e6e'}
                    style={{
                      width:
                        parseInt(x.awayTeam) > parseInt(x.homeTeam)
                          ? '100%'
                          : percentageCalc(x.awayTeam, x.homeTeam),
                    }}
                  />
                </Box>
              </Flex>
            </Box>
          ))}
        </Box>
      ) : (
        <Box textAlign="center" minH={'300px'}>
          {'Not Available'}
        </Box>
      )}
    </Box>
  );
};

export default Stats;
