import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'CoalIcon',
  viewBox: '0 0 512 512',
  d: `M437.02,74.98C388.667,26.629,324.38,0,256,0S123.333,26.629,74.98,74.98C26.629,123.333,0,187.62,0,256
       s26.629,132.667,74.98,181.02C123.333,485.371,187.62,512,256,512s132.667-26.629,181.02-74.98
       C485.371,388.667,512,324.38,512,256S485.371,123.333,437.02,74.98z M271,90.92l64.146-46.605
       c38.687,14.512,72.369,39.336,97.683,71.115l-24.464,75.292l-72.659,23.608L271,167.318V90.92z M176.854,44.315L241,90.92v76.398
       l-64.706,47.012l-72.659-23.608L79.171,115.43C104.485,83.651,138.167,58.827,176.854,44.315z M67.638,380.736
       c-22.065-33.209-35.586-72.554-37.411-114.885l64.137-46.598l72.659,23.608l24.716,76.066l-44.906,61.809H67.638z
        M316.388,473.795C297.158,479.134,276.908,482,256,482c-20.908,0-41.158-2.866-60.389-8.205l-24.507-75.425l44.906-61.808h79.979
       l44.906,61.808L316.388,473.795z M365.167,380.737L365.167,380.737l-44.906-61.809l24.716-76.066l72.659-23.608l64.137,46.598
       c-1.825,42.331-15.346,81.676-37.411,114.885H365.167z`,
});
