import { action, Action } from 'easy-peasy';
import { PlayerInterface } from '../containers/Player';
import { AuthenticatedUser } from '../hooks/useAdminState';

export interface UserModel {
  userData: string;
  isDark: boolean;
  playerInfo: PlayerInterface | null;
  authenticatedUser: AuthenticatedUser | undefined;
  setUserData: Action<UserModel, string>;
  setPlayerInfo: Action<UserModel, PlayerInterface>;
  setIsDark: Action<UserModel, boolean>;
  setAuthenticatedUser: Action<UserModel, AuthenticatedUser>;
}

const userModel: UserModel = {
  userData: '',
  isDark: false,
  playerInfo: null,
  authenticatedUser: undefined,
  setUserData: action((state, payload) => {
    state.userData = payload;
  }),
  setIsDark: action((state, payload) => {
    state.isDark = payload;
  }),
  setPlayerInfo: action((state, payload) => {
    state.playerInfo = payload;
  }),
  setAuthenticatedUser: action((state, payload) => {
    state.authenticatedUser = payload;
  }),
};

export default userModel;
