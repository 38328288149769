import { useQuery } from '@apollo/client';
import { Box, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import HeadingTitle from '../components/HeadingTitle';
import SeasonData from '../components/SeasonData';
import { useStoreState, useStoreActions } from '../store';
import { FETCH_APPLICATION_DATA } from '../utils/queries';
import Footer from './Footer';
import Sidebar from './Navigation';
import { ApplicationDataType } from '../utils/types';

const SeasonDetails = () => {
  const { data: applicationData } = useQuery(FETCH_APPLICATION_DATA);
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);
  return (
    <Sidebar>
      {' '}
      <Stack
        bg="white"
        minH="100vh"
        pt={{ base: '125px', md: '200px' }}
        display="flex"
        justifyContent={'space-between'}
      >
        <Box>
          <Box>
            <Box textAlign={'center'} mb="50px">
              <HeadingTitle>{'Season Details'}</HeadingTitle>
            </Box>
            <SeasonData
              data={
                (applicationData as ApplicationDataType)?.competitions?.[0]
                  .seasonDetails
              }
            />
          </Box>
        </Box>
        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default SeasonDetails;
