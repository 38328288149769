import { Link, useParams, useSearchParams } from 'react-router-dom';
import { upperCaseFirstLetterWithSpaces } from '../../../utils/helper';
import {
  Box,
  Button,
  Center,
  ListItem,
  Stack,
  UnorderedList,
} from '@chakra-ui/react';
import Sidebar from '../../Navigation';
import HeadingTitle from '../../../components/HeadingTitle';
import Footer from '../../Footer';
import RegistrationComponent from './RegitrationComponent';
import useLeaguesState from '../../../hooks/useLeaguesState';
import usePlayerInfo from '../../../hooks/usePlayerInfo';
import AlreadyRegistered from '../../AlreadyRegistered';
import { useStoreActions, useStoreState } from '../../../store';
import { useEffect } from 'react';

const Registration = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);
  const playerInfo = usePlayerInfo();
  const { comp } = params;
  const competitionName = upperCaseFirstLetterWithSpaces(comp ?? '');
  const { currentCompetition, notInCurrentSeason, currentParticipation } =
    useLeaguesState(competitionName);

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt="140px"
        display="flex"
        justifyContent={'space-between'}
      >
        <Box maxWidth="767px" margin="100px auto">
          <Center p="15px">
            <HeadingTitle>{competitionName} Registration</HeadingTitle>
          </Center>

          {playerInfo?.id && !notInCurrentSeason ? (
            <Box bg="gray.100" rounded="10px" p="20px" textAlign="center">
              <Box>
                You are already a part of team{' '}
                <strong>{currentParticipation?.teams?.[0]?.name}</strong>
              </Box>
              <Link to={`/team/${currentParticipation?.id}`}>
                <Button colorScheme="teal" mt="20px" rounded="full">
                  View {currentParticipation?.teams?.[0]?.name}
                </Button>
              </Link>
            </Box>
          ) : (
            <Box>
              <Box p="10px">
                <Box bg="gray.100" rounded="10px" p="20px">
                  <strong>How to register:</strong>

                  <UnorderedList mt="10px">
                    <ListItem>
                      <strong>Step one:</strong> Create your team and your
                      personal data, an email with your login password will be
                      sent to you as well as a link with your team information
                    </ListItem>
                    {playerInfo?.id ? null : (
                      <ListItem>
                        <strong>Step two:</strong> Login with your registered
                        email, password and add your team members
                      </ListItem>
                    )}
                    <ListItem>
                      <strong>Step {playerInfo?.id ? 'two' : 'three'}:</strong>{' '}
                      Payment link will be sent to you when you meet the minimum
                      required players -{' '}
                      <strong>
                        ({currentCompetition?.minPlayersCount} Players)
                      </strong>
                    </ListItem>
                  </UnorderedList>
                </Box>
              </Box>
              {playerInfo?.id ? (
                <AlreadyRegistered />
              ) : (
                <RegistrationComponent compName={competitionName} from={from} />
              )}
            </Box>
          )}
        </Box>
        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default Registration;
