import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  useColorMode,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { greenishColor } from '../../utils/theme';

interface Props {
  title?: string;
  initialValue: string;
  handleEdit: (val: string) => void;
  disableEdit?: boolean;
}

const EditBox = ({ title, initialValue, handleEdit, disableEdit }: Props) => {
  const { colorMode } = useColorMode();
  const [val, setVal] = useState(initialValue);
  const [show, setShow] = useState(false);
  const toast = useToast();

  const isDark = colorMode === 'dark';
  const handleCancel = () => {
    setVal(initialValue);
    setShow(!show);
  };

  const handleSubmit = () => {
    if (!val) {
      toast({
        description: 'Cannot be empty',
        status: 'error',
        isClosable: true,
      });
      return;
    }
    handleEdit(val);
    setShow(!show);
  };

  useEffect(() => {
    if (initialValue) {
      setVal(initialValue);
    }
  }, [initialValue]);
  return (
    <Box>
      {title ? (
        <Heading as="h5" fontSize="20px">
          {title}
        </Heading>
      ) : null}
      <Flex alignItems="center" mt="15px">
        <Input
          type="text"
          placeholder={initialValue || title}
          value={`${val}`}
          variant="flushed"
          margin={'0 40px 0 0'}
          onChange={(e) => setVal(e.target.value)}
          isDisabled={!show}
          readOnly={disableEdit}
        />
        {show || disableEdit ? null : (
          <Button
            size="sm"
            variant="outline"
            fontWeight="normal"
            _hover={{ bg: greenishColor, color: 'white' }}
            onClick={() => setShow(!show)}
            color={isDark ? 'gray700' : 'gray.500'}
          >
            {'Edit'}
          </Button>
        )}
        {show ? (
          <Flex alignItems="center">
            <Button
              size="sm"
              variant="outline"
              borderColor={greenishColor}
              color={greenishColor}
              _hover={{ color: greenishColor }}
              fontWeight="normal"
              onClick={handleSubmit}
              margin={'0 10px 0 0'}
            >
              {'Save'}
            </Button>

            <Button
              size="sm"
              variant="outline"
              fontWeight="normal"
              bg={greenishColor}
              color={'white'}
              _hover={{ bg: greenishColor, color: 'white' }}
              onClick={handleCancel}
            >
              {'Cancel'}
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
};

export default EditBox;
