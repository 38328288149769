import { Box, Container, Heading, Text } from '@chakra-ui/react';
import {
  DocumentRenderer,
  DocumentRendererProps,
} from '@keystone-6/document-renderer';

export default function SeasonData({ data }: { data: any }) {
  const renderers: DocumentRendererProps['renderers'] = {
    block: {
      heading({ level, children, textAlign }) {
        return (
          <Heading as={`h${level}`} fontSize="3xl" mt="30px" mb="10px">
            {children}
          </Heading>
        );
      },
      paragraph({ children }) {
        return <Text fontSize="lg">{children}</Text>;
      },
    },
  };
  return (
    <Box p={4}>
      <Container maxW={'5xl'} mt={10}>
        {data ? (
          <DocumentRenderer document={data?.document} renderers={renderers} />
        ) : null}
      </Container>
    </Box>
  );
}
