import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'SubIcon',
  viewBox: '0 0 512 512',
  path: [
    <path
      key={'sub1'}
      d="M72.835 127.999h247.163a21.333 21.333 0 100-42.666H72.835l48.915-48.915a21.333 21.333 0 00-30.17-30.17L6.246 91.581c-.5.5-.967 1.02-1.413 1.564-.2.246-.378.506-.567.759-.228.3-.463.6-.675.918s-.379.618-.565.929c-.171.286-.351.566-.509.861-.17.317-.314.644-.466.968-.145.307-.3.609-.429.924s-.236.637-.35.957c-.121.337-.25.669-.354 1.013-.1.32-.168.645-.249.969-.089.351-.187.7-.258 1.056-.074.375-.118.753-.172 1.13-.044.311-.1.618-.135.933a21.468 21.468 0 000 4.211c.031.315.09.621.135.933.054.377.1.756.173 1.13.071.358.169.7.258 1.055.081.324.152.649.249.969.1.344.233.677.354 1.013.115.32.22.642.35.957s.284.616.429.923c.153.324.3.651.467.969.158.294.337.573.508.859.186.312.362.627.565.931s.446.612.673.916c.19.254.366.514.569.761.443.54.91 1.059 1.4 1.552a.11.11 0 00.01.011l85.333 85.333a21.333 21.333 0 0030.17-30.17z"
      fill="#1a8607"
    />,
    <path
      key={'sub2'}
      d="M507.162 248.188c.2-.248.38-.509.571-.764.226-.3.461-.6.671-.913s.38-.62.566-.932c.17-.285.349-.564.506-.857.17-.318.315-.646.468-.971.145-.306.3-.607.428-.921s.236-.637.35-.957c.121-.337.25-.669.354-1.013.1-.32.168-.646.249-.969.089-.351.187-.7.258-1.055.074-.375.118-.753.173-1.13.044-.311.1-.617.135-.933a21.472 21.472 0 000-4.211c-.031-.315-.09-.621-.135-.933-.054-.377-.1-.756-.173-1.13-.071-.358-.169-.7-.258-1.055-.081-.324-.152-.649-.249-.969-.1-.344-.233-.677-.354-1.013-.115-.32-.22-.642-.35-.957s-.283-.615-.428-.921c-.153-.325-.3-.653-.468-.971-.157-.293-.336-.572-.506-.857-.186-.312-.363-.628-.566-.932s-.445-.611-.671-.913c-.191-.255-.368-.516-.571-.764-.439-.535-.9-1.05-1.392-1.54l-.021-.023-85.333-85.333a21.333 21.333 0 10-30.17 30.17l48.915 48.915H191.998a21.333 21.333 0 000 42.666h247.163l-48.915 48.915a21.333 21.333 0 1030.17 30.17l85.333-85.333.021-.023q.733-.739 1.392-1.543z"
      fill="#b90000"
    />,
  ],
});
