import {
  Box,
  Button,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  useColorModeValue,
  VStack,
  Text,
  Input,
  FormErrorMessage,
  FormControl,
  useToast,
  Select,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { greenishColor, redishColor } from '../utils/theme';
import { FaCheckCircle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import useDebounce from '../hooks/useDebounce';
import { GET_PARTICIPATION_BY_ID } from '../utils/queries';
import { useQuery } from '@apollo/client';
import { pricingPlans } from '../utils/constants';
import useLeaguesState from '../hooks/useLeaguesState';
import { lowerCaseNoSpaces } from '../utils/helper';

const stepsObj = {
  step1: 'Are you a Team Captain ?',
  step2: 'Do you have a team security code and you want to join that team ?',
  step3:
    "It seems you don't have a team, Do you want to register as a free agent Player in AL transfer market?",
};

const answerObj = {
  step1: 'Registering a new team',
  step2: 'Registering for a specific team',
  step3: 'Registering for Transfer Market',
};

const options = [
  { id: 1, desc: 'One time payment (per season)' },
  { id: 2, desc: 'Full Kits with the selected jersey number & name' },
  { id: 3, desc: 'Team & Player Statistics powered by KoraStats' },
  { id: 4, desc: 'Security & Medical Presence' },
];

function PriceWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}
    >
      {children}
    </Box>
  );
}
const Agreement = ({
  price,
  isTeam,
  isPlayer,
  hasSeasonSub,
  subPlanType,
  existingTeam,
  participation,
}: {
  price: string;
  isTeam?: boolean;
  isPlayer?: boolean;
  hasSeasonSub?: boolean;
  subPlanType: 'Half' | 'Full' | 'None';
  existingTeam?: { id: string };
  participation?: { id: string };
}) => {
  const route = isTeam ? 'team' : isPlayer ? 'player' : 'transfer-market';
  const fullRoute =
    route +
    '/' +
    (hasSeasonSub || isPlayer ? subPlanType.toLowerCase() : 'free-agent');

  return (
    <Box w={{ base: '100%', md: '448px' }} maxW="450px" m="0 auto">
      <PriceWrapper>
        <Box py={4} px={12}>
          <Text fontWeight="500" fontSize="2xl">
            {subPlanType} Season Subscription
          </Text>
          <HStack justifyContent="center">
            <Text fontSize="3xl" fontWeight="600">
              EGP
            </Text>
            <Text fontSize="4xl" fontWeight="900">
              {price}
            </Text>
          </HStack>
          {hasSeasonSub && subPlanType ? (
            <>
              <Text fontSize="lg" fontWeight="600" lineHeight={'20px'}>
                {subPlanType === 'Full'
                  ? ' All regular season match day fees upfront'
                  : 'Regular season match day fees over 3 installments'}
              </Text>
              {subPlanType === 'Full' || subPlanType === 'Half' ? (
                <Text fontSize="17px" fontWeight="600" color={redishColor}>
                  EGP 750 Per Player
                </Text>
              ) : null}
              <HStack justifyContent="center">
                <Text fontSize="1xl" fontWeight="600">
                  EGP
                </Text>
                <Text fontSize="2xl" fontWeight="900">
                  {subPlanType === 'Full'
                    ? pricingPlans.fullSeason
                    : pricingPlans.halfSeason}{' '}
                  <Text fontSize="lg" fontWeight="600" as="span">
                    per match
                  </Text>
                </Text>
              </HStack>
            </>
          ) : isPlayer ? null : (
            <Text
              fontSize="lg"
              fontWeight="600"
              lineHeight={'20px'}
              color={redishColor}
            >
              Payment to be requested prior to team assignment
            </Text>
          )}
        </Box>
        <VStack
          bg={useColorModeValue('gray.50', 'gray.700')}
          py={4}
          borderBottomRadius={'xl'}
        >
          <List spacing={3} textAlign="start" px={7}>
            {options.map((desc) => (
              <ListItem key={desc.id}>
                <ListIcon as={FaCheckCircle} color={greenishColor} />
                {desc.desc}
              </ListItem>
            ))}
            {isTeam ? (
              <ListItem key={5}>
                <ListIcon as={FaCheckCircle} color={greenishColor} />
                You get to be a Team Captain
              </ListItem>
            ) : null}
          </List>
          <Box w="80%" pt={7}>
            <Link
              to={`/register/${
                isPlayer && existingTeam && participation
                  ? fullRoute + '/' + participation?.id
                  : fullRoute
              }`}
            >
              <Button
                w="full"
                colorScheme="red"
                variant="outline"
                isDisabled={isPlayer && !existingTeam}
              >
                Register Now
              </Button>
            </Link>
          </Box>
        </VStack>
      </PriceWrapper>
    </Box>
  );
};

const Steps = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [comp, setComp] = useState('');

  const { publicCompetitions } = useLeaguesState();
  const [hide, setHide] = useState(false);
  const [step, setStep] = useState(stepsObj.step1);

  const currentCompetition = useMemo(() => {
    const foundComp = publicCompetitions?.find(
      (x) => x.name.toLowerCase() === comp?.toLowerCase()
    );
    if (foundComp) return foundComp;
    return publicCompetitions?.find((x) => x.name === 'AL Elite');
  }, [publicCompetitions, comp]);

  const handleAnswer = () => {
    if (!comp) {
      toast({
        title: `No competition found, Please choose a competition`,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (step === stepsObj.step1) {
      if (!currentCompetition?.disableRegistration) {
        navigate(`/register/team/full/${lowerCaseNoSpaces(comp)}`);
        setStep(answerObj.step1);
        setHide(true);
      } else {
        toast({
          title:
            'Unfortunately team registration is locked for the current season, you can join one of the current teams only. However you are more than welcome to create your own team in the next season, stay tuned! to check when that happens. Thanks!',
          status: 'error',
          isClosable: true,
        });
        return;
      }
    }
    if (step === stepsObj.step2) {
      setStep(answerObj.step2);
      setHide(true);
    }
    if (step === stepsObj.step3) {
      setStep(answerObj.step3);
      setHide(true);
      navigate(
        `/register/transfer-market/free-agent/${lowerCaseNoSpaces(comp)}`
      );
    }
  };

  const handleRejection = () => {
    if (step === stepsObj.step1) {
      setStep(stepsObj.step2);
    } else if (step === stepsObj.step2) {
      setStep(stepsObj.step3);
    } else {
      setStep(stepsObj.step1);
      setHide(false);
    }
  };

  const [value, setValue] = useState('');

  const debouncedSearch = useDebounce(value, 100);

  const { data: participationData } = useQuery(GET_PARTICIPATION_BY_ID, {
    variables: { id: debouncedSearch, name: currentCompetition?.name },
  });

  const participation = useMemo(() => {
    if (
      participationData?.participation?.name.toLowerCase().includes('transfer')
    )
      return null;
    return participationData?.participation?.seasons[0].seasonNumber ===
      participationData?.seasonsCount &&
      participationData?.participation?.competition?.name ===
        currentCompetition?.name
      ? participationData?.participation
      : null;
  }, [currentCompetition, participationData]);

  const existingTeam = useMemo(() => {
    return participation?.teams[0];
  }, [participation]);

  useEffect(() => {
    if (currentCompetition?.isTournament) {
      navigate(
        `/register/tournaments/${lowerCaseNoSpaces(currentCompetition?.name)}`
      );
    }
  }, [currentCompetition, navigate]);

  return (
    <Box textAlign={'center'} maxW={'880px'} margin={'0px auto'}>
      <Box maxW="350px" m="0 auto 20px">
        <Select fontWeight={'bold'} onChange={(e) => setComp(e.target.value)}>
          <option value="">Choose League</option>
          {publicCompetitions?.map((comp) => (
            <option
              key={comp.id}
              value={comp.name}
              disabled={comp.disableRegistration}
            >
              {comp.name}
            </option>
          ))}
        </Select>
      </Box>
      <Heading as="h2">{step}</Heading>
      <Box m="20px auto" p="0 15px">
        {step === answerObj.step1 ? (
          <HStack maxW="1200px" flexDirection={{ base: 'column', md: 'row' }}>
            <Agreement
              price={pricingPlans.team}
              subPlanType="Full"
              hasSeasonSub
              isTeam
            />
            <Agreement
              price={pricingPlans.team}
              subPlanType="Half"
              hasSeasonSub
              isTeam
            />
          </HStack>
        ) : null}
        {step === answerObj.step2 ? (
          <Box>
            <Box maxW="430px" margin="30px auto">
              <FormControl
                isInvalid={Boolean(debouncedSearch && !existingTeam)}
              >
                <Input
                  size="lg"
                  fontSize="xl"
                  value={value}
                  fontWeight="bold"
                  onChange={(e) => setValue(e.target.value)}
                  placeholder="Enter Team Security Code"
                  p="30px"
                  textAlign={'center'}
                />
                {!existingTeam ? (
                  <FormErrorMessage>
                    Wrong League / Wrong Team Security Code / Team Participation
                    does not exist
                  </FormErrorMessage>
                ) : participation?.playersCount &&
                  participation?.competition?.maxPlayersCount &&
                  participation?.playersCount >=
                    participation?.competition?.maxPlayersCount ? (
                  <FormErrorMessage>Team is Full!</FormErrorMessage>
                ) : null}
              </FormControl>
            </Box>

            <HStack
              maxW="250"
              flexDirection={{ base: 'column', md: 'row' }}
              margin="0 auto"
            >
              <Link
                to={`/register/${
                  existingTeam && participation
                    ? 'player/full/' + participation?.id
                    : 'free-agent'
                }/${lowerCaseNoSpaces(comp)}`}
              >
                <Button
                  w="250px"
                  colorScheme="red"
                  variant="outline"
                  isDisabled={!existingTeam}
                >
                  Register Now
                </Button>
              </Link>
            </HStack>
          </Box>
        ) : null}
        {step === answerObj.step3 ? (
          <Agreement subPlanType="Full" price={pricingPlans.player} />
        ) : null}
      </Box>
      <HStack w={'fit-content'} margin={'30px auto'}>
        <Button
          rounded="full"
          bg={hide ? 'none' : redishColor}
          fontWeight="normal"
          color={hide ? redishColor : 'white'}
          onClick={handleRejection}
          variant={hide ? 'link' : 'solid'}
          _hover={{ bg: hide ? 'none' : redishColor, opacity: '0.9' }}
        >
          {hide
            ? 'I want to choose another option'
            : step === stepsObj.step1
            ? 'No, I am not'
            : "No, I don't"}
        </Button>

        {hide ? null : (
          <Button
            rounded="full"
            bg={greenishColor}
            fontWeight="normal"
            color={'white'}
            onClick={handleAnswer}
            _hover={{ bg: greenishColor, opacity: '0.9' }}
          >
            {step === stepsObj.step1 ? 'Yes, I am' : 'Yes, I do'}
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default Steps;
