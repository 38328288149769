import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Icon, Tag, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../store';
import { returnDate, returnInMatchTime, returnTime } from '../../utils/helper';
import { MatchInterface } from '../../utils/types';

const Match = ({ match }: { match: MatchInterface }) => {
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);
  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  const returnBackground = (match: any) => {
    if (match.isMatchLive === '1') {
      return 'green';
    } else if (
      match.homeTeamScore ||
      match.awayTeamScore ||
      match.awayTeamScore === 0 ||
      match.homeTeamScore === 0
    ) {
      return 'gray.200';
    } else {
      return '';
    }
  };

  const isHomeWinner = (match: any) => {
    const homeTeamPenalties: [] = match?.penalties?.length
      ? match?.penalties?.filter(
          (x: any) => x.isHomeTeam && x.type === 'Scored'
        )
      : [];
    const awayTeamPenalties: [] = match?.penalties?.length
      ? match?.penalties?.filter(
          (x: any) => !x.isHomeTeam && x.type === 'Scored'
        )
      : [];
    if (
      Number(match.homeTeamScore) > Number(match.awayTeamScore) ||
      homeTeamPenalties.length > awayTeamPenalties.length
    ) {
      return 1;
    }
    if (
      Number(match.homeTeamScore) < Number(match.awayTeamScore) ||
      awayTeamPenalties.length > homeTeamPenalties.length
    ) {
      return 2;
    }
    return 0;
  };

  return (
    <Box>
      <Link to={`/match/${match.id}`}>
        <HStack
          alignItems="center"
          justifyContent="space-between"
          cursor="pointer"
          _hover={{ bg: 'teal.50', color: 'inherit' }}
        >
          <HStack alignItems="center">
            <Flex
              h={'58px'}
              w={'100px'}
              justifyContent="center"
              alignItems="center"
              fontSize="12px"
              textAlign="center"
              color={returnBackground(match) === 'green' ? 'white' : ''}
              bg={returnBackground(match) === 'green' ? 'green.400' : ''}
            >
              <Box>
                {match.isMatchLive ? (
                  <Box>
                    <Tag size={'sm'} variant="solid" colorScheme="green">
                      Live
                    </Tag>
                  </Box>
                ) : (
                  returnDate(match.matchDate)
                )}
                <Text mt="3px">
                  {returnTime(match.matchDate, returnInMatchTime(match))}
                </Text>
              </Box>
            </Flex>
            <Flex
              marginInlineStart="0 !important"
              w="25px"
              h="58px"
              justifyContent="center"
              flexDirection="column"
              color={returnBackground(match) === 'green' ? 'white' : ''}
              bg={
                returnBackground(match) === 'green'
                  ? 'green.500'
                  : returnBackground(match)
              }
            >
              <Flex
                fontSize="14px"
                minW="100%"
                fontWeight={isHomeWinner(match) === 1 ? 'bold' : 'normal'}
                alignItems="center"
                justifyContent="center"
              >
                {match.isMatchLive || match.isMatchEnded
                  ? match.homeTeamScore
                  : '-'}
              </Flex>
              <Flex
                minW="100%"
                fontSize="14px"
                fontWeight={isHomeWinner(match) === 2 ? 'bold' : 'normal'}
                alignItems="center"
                justifyContent="center"
              >
                {match.isMatchLive || match.isMatchEnded
                  ? match.awayTeamScore
                  : '-'}
              </Flex>
            </Flex>

            <Flex flexDirection="column" h="58px" justifyContent="center">
              <Box
                noOfLines={1}
                fontSize={['xs', '14px']}
                fontWeight={isHomeWinner(match) === 1 ? 'bold' : 'normal'}
              >
                {match.homeTeam.teams[0].name}
              </Box>
              <Box
                noOfLines={1}
                fontSize={['xs', '14px']}
                fontWeight={isHomeWinner(match) === 2 ? 'bold' : 'normal'}
              >
                {match.awayTeam.teams[0].name}
              </Box>
            </Flex>
          </HStack>
          <Box padding="0 10px">
            <Icon as={ChevronRightIcon} />
          </Box>
        </HStack>
      </Link>
    </Box>
  );
};

export default Match;
