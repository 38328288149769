import { Box, Heading, Flex, Text } from '@chakra-ui/react';
import Calendar from '../../components/Icons/Calendar';
import Coach from '../../components/Icons/Coach';
import Cup from '../../components/Icons/Cup';
import Referee from '../../components/Icons/Referee';
import Stadium from '../../components/Icons/Stadium';
import { returnDate, returnTimeAnyway } from '../../utils/helper';
import { MatchInterface } from '../../utils/types';
import MatchProgress from './MatchProgress';

interface Props {
  match: MatchInterface;
}
const MatchInfo = ({ match }: Props) => {
  const homeLineup = match?.lineUp?.filter((lineup) => lineup.isHomeTeam);
  const awayLineup = match?.lineUp?.filter((lineup) => !lineup.isHomeTeam);
  const homeCaptain = homeLineup?.find((x) => x.isCaptain);
  const awayCaptain = awayLineup?.find((x) => x.isCaptain);
  return (
    <Box dir="ltr !important">
      {match ? <MatchProgress match={match} /> : null}
      <Heading as="h4" fontSize="16px" textAlign="center" my="25px">
        {'Match Info'}
        <Box
          width="30px"
          h="3px"
          rounded="lg"
          bg={'teal.400'}
          margin="10px auto"
        />
      </Heading>
      <Box textAlign="center" mt="30px">
        <Calendar boxSize="25px" />
        <Text fontSize="sm" opacity="0.78" mt="5px">
          {'Start'}
        </Text>
        <Text fontSize="sm">
          {returnDate(match.firstHalfStartTime ?? match.matchDate)}{' '}
          {returnTimeAnyway(match.firstHalfStartTime ?? match.matchDate)}
        </Text>
      </Box>

      <Box textAlign="center" mt="30px">
        <Cup boxSize="30px" />
        <Text fontSize="sm" opacity="0.78" mt="5px">
          {'League'}
        </Text>
        <Text fontSize="sm">{match.competition.name}</Text>
      </Box>

      <Box textAlign="center" mt="30px">
        <Stadium boxSize="30px" />
        <Text fontSize="sm" opacity="0.78" mt="5px">
          {'Stadium'}
        </Text>
        <Text fontSize="sm">{match.stadium}</Text>
      </Box>

      <Box textAlign="center" mt="30px">
        <Referee boxSize="35px" />
        <Text fontSize="sm" opacity="0.78" mt="5px">
          {'Referee'}
        </Text>
        <Text fontSize="sm">{match.referee ?? '-'}</Text>
      </Box>

      <Box textAlign="center" mt="30px">
        <Coach boxSize="45px" />
        <Text fontSize="sm" opacity="0.78" mt="5px">
          {'Coach'}
        </Text>

        <Flex
          fontSize="sm"
          alignItems="center"
          justifyContent="center"
          dir="ltr !important"
          mt={'10px'}
        >
          <Box margin="0 10px" w={'calc(40%)'} textAlign={'right'} pr={'20px'}>
            {homeCaptain?.name ?? '-'}
          </Box>
          <Box margin="0 10px" w={'calc(40%)'} textAlign={'left'} pl={'20px'}>
            {awayCaptain?.name ?? '-'}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default MatchInfo;
