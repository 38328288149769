import { createIcon } from '@chakra-ui/icons';

export default createIcon({
  displayName: 'YellowCardIcon',
  viewBox: '0 0 512 512',
  path: (
    <rect
      fill="#fc0"
      fillRule="evenodd"
      height="100%"
      rx="2.4"
      width="70%"
      x="16.5"
      y="12.5"
    />
  ),
});
