import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import './main.css';
import 'swiper/css';
import 'swiper/css/pagination';
import { Box, Button, Heading, Image, Text } from '@chakra-ui/react';
import gsap from 'gsap';
import ThemeIcon from '../../../assets/theme-icon.png';
import { Fetch_Home_ContentQuery } from '../../../__generated__/graphql';
import { SERVER_URL } from '../../../utils/constants';

const HomeSlider = ({
  data,
}: {
  data: Fetch_Home_ContentQuery | undefined;
}) => {
  const onSlideChange = (swiper: any) => {
    if (!swiper) return;

    const activeIndex = swiper.activeIndex;
    const activeSlide = swiper.el.querySelector(
      `.swiper-slide:nth-child(${activeIndex + 1})`
    );

    const h2 = activeSlide?.querySelector('h2');
    const p = activeSlide?.querySelector('p');

    const img = activeSlide?.querySelector('.slider-img');

    gsap.from(img, { scale: 1.2, duration: 0.8, ease: 'power2.inOut' });

    gsap.from(h2, { opacity: 0, y: -50, duration: 0.8, ease: 'power2.inOut' });
    gsap.from(p, { opacity: 0, y: 50, duration: 0.8, ease: 'power2.inOut' });
  };
  return (
    <Box>
      {data?.homePage?.slidesImages?.length ? (
        <Swiper
          pagination={{
            renderBullet: (_: number, className: string) => {
              return `<span class="rounded-full transition ${className}"></span>`;
            },
            clickable: true,
          }}
          navigation={{
            nextEl: `.swiper-button-next`,
            prevEl: `.swiper-button-prev`,
          }}
          spaceBetween={0}
          slidesPerView={1}
          className="h-full w-full"
          modules={[Autoplay, Pagination, Navigation]}
          onSlideChangeTransitionStart={onSlideChange}
          loop
          autoplay={{ delay: 5000 }}
        >
          {data?.homePage?.slidesImages?.map((img, index: number) => (
            <SwiperSlide key={index + 'slide'} className="pb-10">
              <Box
                position="relative"
                minH={['30vh', '50vh', '80vh', '80vh']}
                w="full"
                overflow={'hidden'}
              >
                <Box className="slide-overlay" />
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  w="100%"
                  right="0"
                  height="100%"
                  className="slider-img"
                >
                  <Image
                    src={SERVER_URL + img?.image?.url}
                    alt={'slider img' + index}
                    objectFit={['cover', 'cover', 'cover', 'cover']}
                    w="100%"
                    height="100%"
                  />
                </Box>

                <Box
                  zIndex="2"
                  color="white"
                  position="absolute"
                  top="0"
                  left="0"
                  w="100%"
                  right="0"
                  height="100%"
                  display="flex"
                  alignItems="center"
                >
                  <Box
                    minW={['80%', '80%', '85%', '75%']}
                    maxW={['80%', '80%', '85%', '75%']}
                    margin="0 auto"
                    pt="10%"
                  >
                    <Heading
                      as="h2"
                      fontSize="clamp(2rem, -1.5rem + 8vw, 5rem)"
                    >
                      {data?.homePage?.slidesContent?.[index]?.title}
                    </Heading>
                    <Text
                      fontSize="clamp(1rem, -1.5rem + 8vw, 2rem)"
                      maxW="700px"
                    >
                      {data?.homePage?.slidesContent?.[index]?.description}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
          ))}
          {data?.homePage?.slidesImages?.length > 1 ? (
            <Box display={['none', 'none', 'block', 'block']}>
              <Button
                variant="ghost"
                className="swiper-button-prev"
                position="absolute"
                top="50%"
                zIndex="30"
                left="10px"
                opacity={0.7}
                transition="all 0.3s ease"
                _hover={{ bg: 'none', opacity: 1, transform: 'scale(1.2)' }}
              >
                <Image src={ThemeIcon} w="50px" transform={'rotate(180deg)'} />
              </Button>

              <Button
                variant="ghost"
                className="swiper-button-next"
                position="absolute"
                top="50%"
                right="10px"
                zIndex="30"
                opacity={0.5}
                transition="all 0.3s ease"
                _hover={{ bg: 'none', opacity: 1, transform: 'scale(1.2)' }}
              >
                <Image src={ThemeIcon} w="50px" />
              </Button>
            </Box>
          ) : null}
        </Swiper>
      ) : (
        <Box minH={['30vh', '50vh', '80vh', '80vh']} />
      )}
    </Box>
  );
};

export default HomeSlider;
