import { MatchInterface } from '../../utils/types';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  HStack,
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useMediaQuery,
} from '@chakra-ui/react';
import { position as PlayerPositions } from '../../utils/Select';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { FiRefreshCcw } from 'react-icons/fi';
import {
  ApolloQueryResult,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { GET_PARTICIPATION_BY_ID, GET_TEAM_PLAYERS } from '../../utils/queries';
import { useCallback, useMemo, useRef, useState } from 'react';
import { UPDATE_MATCH, UPDATE_PARTICIPATION } from '../../utils/mutations';
import { timeout } from '../../utils/helper';
import useAdminState from '../../hooks/useAdminState';
import { useNavigate } from 'react-router-dom';

interface Props {
  match: MatchInterface;
  refetch: (
    variables?:
      | Partial<{
          id: string | undefined;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
}
interface MatchDetail {
  team: string;
  type: string;
  name: string;
  isHomeTeam: boolean;
  isSub: boolean;
  isCaptain: boolean;
  position: string;
  playerPhoto: string;
}
const Lineup = ({ match: matchData, refetch: refetchMatch }: Props) => {
  const { authenticatedUser } = useAdminState();
  const navigate = useNavigate();
  const [isLessThan500] = useMediaQuery('(max-width: 500px)');
  const match = useMemo(() => {
    return matchData;
  }, [matchData]);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: openAlert,
    onClose: closeAlert,
  } = useDisclosure();
  const [type, setType] = useState('');
  const [team, setTeam] = useState('');
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [updateMatch] = useMutation(UPDATE_MATCH);
  const [updateParticipation] = useMutation(UPDATE_PARTICIPATION);
  const [getPartById] = useLazyQuery(GET_PARTICIPATION_BY_ID);

  const [index, setIndex] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const homeTeamName = match?.homeTeam?.teams?.[0].name;
  const awayTeamName = match?.awayTeam?.teams?.[0].name;
  const homeTeamId = match?.homeTeam?.id;
  const awayTeamId = match?.awayTeam?.id;

  const { data: homeTeamPlayers } = useQuery(GET_TEAM_PLAYERS, {
    variables: { id: homeTeamId },
  });

  const { data: awayTeamPlayers } = useQuery(GET_TEAM_PLAYERS, {
    variables: { id: awayTeamId },
  });

  const cancelRef = useRef<any>(null);

  const returnTeamPlayers = useMemo(() => {
    const players =
      team === match?.homeTeam?.name
        ? homeTeamPlayers?.players
        : awayTeamPlayers?.players;
    if (players?.length) {
      return players.map((player: any) => {
        const value = `${player.firstName} ${player.lastName} (${player.jerseyNumber})`;
        const exists = match?.lineUp?.find((x) => x.name === value);
        return (
          <option
            key={player?.id}
            value={value}
            disabled={exists || player?.suspended ? true : false}
          >
            {value}
          </option>
        );
      });
    }
    return [];
  }, [team, homeTeamPlayers, awayTeamPlayers, match]);

  const returnPlayer = useMemo(() => {
    const isHomeTeam = team === match?.homeTeam?.name;
    const players = isHomeTeam
      ? homeTeamPlayers?.players
      : awayTeamPlayers?.players;

    if (players?.length) {
      const foundPlayer = players.find((x: any) => {
        const value = `${x.firstName} ${x.lastName} (${x.jerseyNumber})`;
        return name === value;
      });

      if (foundPlayer) {
        return foundPlayer;
      }
    }
    return '';
  }, [awayTeamPlayers, homeTeamPlayers, match, name, team]);

  const saveLineup = useCallback(
    async (
      dataToSave?: MatchDetail,
      itemIndex?: number,
      action?: 'delete' | 'edit'
    ) => {
      if (!authenticatedUser) {
        toast({
          title: `Login Required!, You don't have permission to perform this action`,
          status: 'error',
          position: 'top-right',
        });
        await timeout(1000);
        navigate('/admin');
        return;
      }
      setIsSubmitting(true);
      try {
        let newLineup: Array<MatchDetail>;
        if (itemIndex !== undefined && dataToSave) {
          const lineupCopy = [...match?.lineUp];
          lineupCopy[itemIndex] = dataToSave;
          newLineup = lineupCopy;
        } else if (index !== null && action === 'delete') {
          const lineupCopy = [...match?.lineUp];
          lineupCopy.splice(index, 1);
          newLineup = lineupCopy;
        } else {
          const modLineup = {
            team,
            type,
            name,
            playerId: returnPlayer?.id ?? '',
            isHomeTeam: team === match?.homeTeam?.name ? true : false,
            isSub: type === 'Substitution' ? true : false,
            isCaptain: false,
            position,
            playerPhoto: returnPlayer?.photo?.url ?? '',
          };
          if (index !== null) {
            const lineupCopy = [...match?.lineUp];
            lineupCopy[index] = modLineup;
            newLineup = lineupCopy;
          } else {
            const lineupCopy = [...match?.lineUp];
            newLineup = [...lineupCopy, modLineup];
          }
        }

        await updateMatch({
          variables: {
            id: match?.id,
            data: {
              lineUp: newLineup,
            },
          },
        });

        toast({
          title: `Lineup saved Successfully!`,
          status: 'success',
          position: 'top-right',
        });
        await timeout(1000);
        // window.location.reload();
      } catch (error) {
        console.log(error);
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          position: 'top-right',
        });
      } finally {
        setIsSubmitting(false);
        onClose();
        closeAlert();
        refetchMatch();
      }
    },
    [
      index,
      refetchMatch,
      navigate,
      authenticatedUser,
      match,
      name,
      onClose,
      closeAlert,
      position,
      returnPlayer,
      team,
      toast,
      type,
      updateMatch,
    ]
  );
  const clearAll = () => {
    setTeam('');
    setName('');
    setType('');
    setPosition('');
  };

  const saveTeamsLineup = async () => {
    try {
      setIsSubmitting(true);

      const lineupToSaveHome: MatchDetail[] = match?.lineUp.filter(
        (m: MatchDetail) => m.isHomeTeam
      );
      const lineupToSaveAway: MatchDetail[] = match?.lineUp.filter(
        (m: MatchDetail) => !m.isHomeTeam
      );

      if (lineupToSaveHome.length) {
        await updateParticipation({
          variables: { id: homeTeamId, data: { lineUp: lineupToSaveHome } },
        });
      }

      if (lineupToSaveAway.length) {
        await updateParticipation({
          variables: { id: awayTeamId, data: { lineUp: lineupToSaveAway } },
        });
      }

      toast({
        title: `Team Lineup saved Successfully!`,
        status: 'success',
        position: 'top-right',
      });
    } catch (error) {
      console.log(error);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setIsSubmitting(false);
      onClose();
      closeAlert();
    }
  };

  const refetchLineup = async () => {
    try {
      setIsSubmitting(true);
      const homePlayers = homeTeamPlayers?.players;
      const awayPlayers = awayTeamPlayers?.players;

      const homeMadeLineup = homePlayers.map((p: any) => {
        return {
          team: match?.homeTeam?.name,
          type: 'Substitution',
          name: `${p.firstName} ${p.lastName} (${p.jerseyNumber})`,
          isHomeTeam: true,
          isSub: true,
          isCaptain: Boolean(p.isCaptain),
          position: p.position,
          playerPhoto: p.photo?.url ?? '',
        };
      });

      const awayMadeLineup = awayPlayers.map((p: any) => {
        return {
          team: match?.awayTeam?.name,
          type: 'Substitution',
          name: `${p.firstName} ${p.lastName} (${p.jerseyNumber})`,
          isHomeTeam: false,
          isSub: true,
          isCaptain: Boolean(p.isCaptain),
          position: p.position,
          playerPhoto: p.photo?.url ?? '',
        };
      });
      const homePartRes = await getPartById({ variables: { id: homeTeamId } });
      const awayPartRes = await getPartById({ variables: { id: awayTeamId } });

      const homeLineup = homePartRes?.data?.participation?.lineUp?.length
        ? homePartRes?.data?.participation?.lineUp.map((h: MatchDetail) => ({
            ...h,
            isHomeTeam: match?.homeTeam?.name === h.team,
          }))
        : homeMadeLineup;

      const awayLineup = awayPartRes?.data?.participation?.lineUp?.length
        ? awayPartRes?.data?.participation?.lineUp.map((a: MatchDetail) => ({
            ...a,
            isHomeTeam: match?.homeTeam?.name === a.team,
          }))
        : awayMadeLineup;

      const constructedLineup = [...homeLineup, ...awayLineup];
      let data: any = {
        lineUp: constructedLineup,
      };

      await updateMatch({ variables: { id: match?.id, data } });
      toast({
        title: 'Done!, Lineup refetched',
        status: 'success',
        position: 'top-right',
      });
    } catch (error) {
      console.log(error);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const disableLineup = () => {
    if (!match?.lineUp.length) return false;
    if (team === match?.homeTeam?.name) {
      const homeCount = match?.lineUp?.filter(
        (x) => x.type === 'Line up' && x.isHomeTeam
      ).length;

      return homeCount === 11;
    }
    const awayCount = match?.lineUp?.filter(
      (x) => x.type === 'Line up' && !x.isHomeTeam
    ).length;

    return awayCount === 11;
  };

  return (
    <Box>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Lineup
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeAlert}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => saveLineup(undefined, index as number, 'delete')}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add/Edit Lineup</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select onChange={(e) => setTeam(e.target.value)} value={team}>
              <option value="">Select Team</option>
              <option value={match?.homeTeam.name}>
                {match?.homeTeam.name}
              </option>
              <option value={match?.awayTeam.name}>
                {match?.awayTeam.name}
              </option>
            </Select>
            <Select
              mt="10px"
              onChange={(e) => setName(e.target.value)}
              value={name}
              disabled={!team}
            >
              <option value="">Select Player</option>
              {returnTeamPlayers}
            </Select>
            <Select
              mt="10px"
              onChange={(e) => setType(e.target.value)}
              value={type}
              disabled={!name}
            >
              <option value="">Select Type</option>
              <option value={'Line up'} disabled={disableLineup()}>
                {'Line up'}
              </option>
              <option value={'Substitution'}>{'Substitution'}</option>
            </Select>
            <Select
              mt="10px"
              onChange={(e) => setPosition(e.target.value)}
              value={position}
              disabled={!type}
            >
              <option value="">Select Position</option>
              {PlayerPositions.map((p) => {
                return (
                  <option value={p} key={p}>
                    {p}
                  </option>
                );
              })}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
            <Button
              ml="10px"
              colorScheme="blue"
              disabled={!team || !name || !position || !type || isSubmitting}
              isLoading={isSubmitting}
              onClick={() => saveLineup()}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <TableContainer>
        <HStack mb="10px" justifyContent={'space-between'}>
          <Box>
            <Button
              size={isLessThan500 ? 'sm' : 'md'}
              onClick={() => {
                clearAll();
                onOpen();
              }}
              colorScheme="blue"
            >
              Add Lineup
            </Button>
          </Box>

          <Box>
            <Button
              size={isLessThan500 ? 'sm' : 'md'}
              onClick={saveTeamsLineup}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Save Lineup
            </Button>
            <Button
              size={isLessThan500 ? 'sm' : 'md'}
              ml="10px"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              onClick={refetchLineup}
            >
              Refetch Lineup
            </Button>
          </Box>
        </HStack>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>Team</Th>
              <Th>Name</Th>
              <Th>Position</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {match?.lineUp?.map((lineup, i) => {
              return (
                <Tr key={lineup.name}>
                  <Td>{lineup.type}</Td>
                  <Td fontWeight="bold">
                    {lineup.isHomeTeam ? homeTeamName : awayTeamName}
                  </Td>
                  <Td>{lineup.name}</Td>
                  <Td>{lineup.position}</Td>
                  <Td>
                    <IconButton
                      colorScheme="blue"
                      aria-label="Edit Lineup"
                      onClick={() => {
                        setIndex(i);
                        setTeam(lineup.team);
                        setName(lineup.name);
                        setType(lineup.type);
                        setPosition(lineup.position);
                        onOpen();
                      }}
                      icon={<EditIcon />}
                    />
                    <IconButton
                      mx="5px"
                      colorScheme="green"
                      aria-label="Change Lineup"
                      icon={<FiRefreshCcw />}
                      onClick={() => {
                        const newData = {
                          ...lineup,
                          isSub: !lineup.isSub,
                          type:
                            lineup?.type === 'Substitution'
                              ? 'Line up'
                              : 'Substitution',
                        };

                        saveLineup(newData, i);
                      }}
                    />
                    <IconButton
                      colorScheme="red"
                      aria-label="Delete Lineup"
                      icon={<DeleteIcon />}
                      onClick={() => {
                        setIndex(i);
                        openAlert();
                      }}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Lineup;
