import { Avatar, Box, HStack, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../utils/constants';
import { MatchInterface } from '../../utils/types';
import { greenishColor } from '../../utils/theme';
interface Props {
  match: MatchInterface;
}
const MatchBanner = ({ match }: Props) => {
  const returnImage = (team: any) => {
    return team.teamLogo && team.teamLogo?.url
      ? team.teamLogo.url
      : team.mascotLogo;
  };
  return (
    <Box padding="35px 0" position="relative" background="black" dir="ltr">
      {/* <Image
        src={StadiumBanner}
        width="100%"
        height="100%"
        objectFit="cover"
        position="absolute"
        top="0"
        left="0"
        opacity="0.3"
      /> */}
      <Box
        width="100%"
        height="100%"
        position="absolute"
        top="0"
        left="0"
        bg={greenishColor}
      />
      <Box
        p={3}
        w="100%"
        maxW={'600px'}
        m={'auto'}
        position="relative"
        zIndex="2"
      >
        <HStack alignItems="center" justifyContent="space-between">
          <Link to={`/team/${match.homeTeam?.id}`}>
            <Box
              margin="0 0 0 20px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              width={'calc(45%)'}
              cursor="pointer"
            >
              <Box
                boxSize={'60px'}
                rounded="full"
                bg={'white'}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {match.homeTeam.teams[0].teamLogo?.url ? (
                  <Image
                    boxSize="60px"
                    src={SERVER_URL + returnImage(match.homeTeam.teams[0])}
                  />
                ) : (
                  <Avatar
                    boxSize={{ base: '60px', md: '60px' }}
                    name={`${match.homeTeam.teams[0].name} `}
                    src={''}
                  />
                )}
              </Box>
              <Box
                color={'white'}
                mt={'10px'}
                fontSize={['sm', 'md']}
                textAlign="center"
              >
                {match.homeTeam.teams[0].name}
              </Box>
            </Box>
          </Link>

          <Box width="85px" textAlign="center">
            <Text fontSize="25px" fontWeight="bold" color="white">
              {match.homeTeamScore || '0'} - {match.awayTeamScore || '0'}
            </Text>

            <Text color="white">{''}</Text>
          </Box>
          <Link to={`/team/${match.awayTeam?.id}`}>
            <Box
              margin="0 0 0 20px"
              display="flex"
              cursor="pointer"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              width={'calc(45%)'}
            >
              <Box
                boxSize={'60px'}
                rounded="full"
                bg={'white'}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {match.awayTeam.teams[0].teamLogo?.url ? (
                  <Image
                    boxSize="60px"
                    src={SERVER_URL + returnImage(match.awayTeam.teams[0])}
                  />
                ) : (
                  <Avatar
                    boxSize={{ base: '60px', md: '60px' }}
                    name={`${match.awayTeam.teams[0].name} `}
                    src={''}
                  />
                )}
              </Box>
              <Box
                color={'white'}
                mt={'10px'}
                fontSize={['sm', 'md']}
                textAlign="center"
              >
                {match.awayTeam.teams[0].name}
              </Box>
            </Box>
          </Link>
        </HStack>
      </Box>
    </Box>
  );
};

export default MatchBanner;
