import {
  Box,
  Stack,
  Tab,
  TableContainer,
  TabList,
  Table,
  Td,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Text,
  Tr,
  Button,
} from '@chakra-ui/react';
import Footer from './Footer';
import Sidebar from './Navigation';
import usePlayerInfo from '../hooks/usePlayerInfo';
import { useStoreState, useStoreActions } from '../store';
import { useEffect, useState } from 'react';
import HeadingTitle from '../components/HeadingTitle';
import { greenishColor, redishColor } from '../utils/theme';
import useLeaguesState, { Participation } from '../hooks/useLeaguesState';
import { useNavigate } from 'react-router-dom';
import { mapKeyToName } from '../utils/helper';
import NotFound from './NotFound';

const JoinedLeagues = () => {
  const playerInfo = usePlayerInfo();
  const navigate = useNavigate();
  const [comp, setComp] = useState('');
  const { joinedLeagues, notInCurrentSeason } = useLeaguesState(comp);
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const {
    userModel: userActions,
    appModel: appActions,
    popupsModel: popupsActions,
  } = useStoreActions((state) => state);
  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  const handleViewMore = (url: string, compName: string) => {
    appActions.setCompetitionName(compName);
    navigate(url, {
      state: { private: true, isCaptain: playerInfo?.isCaptain },
    });
  };

  const handlePopup = () => {
    popupsActions.setShowSeasonPopup(true);
  };

  useEffect(() => {
    if (joinedLeagues && !comp) {
      setComp(Object.keys(joinedLeagues)?.[0]);
    }
  }, [joinedLeagues, comp]);

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt={{ base: '125px', md: '200px' }}
        display="flex"
        justifyContent={'space-between'}
      >
        {playerInfo?.id ? (
          <Box px={'20px'}>
            <Box textAlign={'center'} mb="50px">
              <HeadingTitle>{'Joined Leagues'}</HeadingTitle>
            </Box>
            <Box maxW="700px" m="auto">
              <Tabs variant="line" colorScheme="green" mt="20px">
                <TabList>
                  {Object.keys(joinedLeagues).map((key) => {
                    return (
                      <Tab
                        key={key + 'tab'}
                        fontSize={['xs', 'sm', 'md', 'md']}
                        padding={['10px', '15px']}
                        onClick={() => setComp(key)}
                      >
                        {mapKeyToName(key)}
                      </Tab>
                    );
                  })}
                </TabList>

                <TabPanels>
                  {Object.keys(joinedLeagues).length > 0 ? (
                    Object.keys(joinedLeagues).map((key) => {
                      return (
                        <TabPanel key={key + 'TabPanel'} padding={'20px 0'}>
                          {notInCurrentSeason ? (
                            <Box
                              textAlign="center"
                              py={10}
                              px={6}
                              maxW="800px"
                              m="0 auto"
                              border={'1px solid #dadada'}
                              borderRadius={'15px'}
                            >
                              You are not joining any team in this season for{' '}
                              <b>{mapKeyToName(key)}</b>
                              <Text color={'gray.500'}>
                                Please click down below to join a team if you
                                have it's security code
                              </Text>
                              <Box textAlign="center" mt="20px">
                                <Button
                                  color={'white'}
                                  background={greenishColor}
                                  _hover={{
                                    background: greenishColor,
                                    color: 'white',
                                  }}
                                  onClick={handlePopup}
                                >
                                  Join Team
                                </Button>
                              </Box>
                            </Box>
                          ) : null}

                          <TableContainer>
                            <Table variant="simple">
                              <Tbody>
                                {joinedLeagues[key].map(
                                  (item: Participation) => {
                                    return (
                                      <Tr key={item.id + 'InnerTabPanel'}>
                                        <Td>{item?.teams[0].name}</Td>
                                        <Td>{item?.seasons[0].name}</Td>
                                        <Td>
                                          {item?.teams[0].name.includes(
                                            'Transfer'
                                          ) ? null : (
                                            <Button
                                              variant="link"
                                              onClick={() =>
                                                handleViewMore(
                                                  `/team/${item?.id}`,
                                                  key
                                                )
                                              }
                                              color={redishColor}
                                            >
                                              View more
                                            </Button>
                                          )}
                                        </Td>
                                      </Tr>
                                    );
                                  }
                                )}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                      );
                    })
                  ) : (
                    <Box
                      textAlign="center"
                      py={10}
                      px={6}
                      maxW="800px"
                      m="20px auto"
                      border={'1px solid #dadada'}
                      borderRadius={'15px'}
                    >
                      You are not joining any team or any league.
                      <Text color={'gray.500'}>
                        Please click down below to join a team if you have it's
                        security code, or if you want to create a new one.
                      </Text>
                      <Box textAlign="center" mt="20px">
                        <Button
                          color={'white'}
                          background={greenishColor}
                          _hover={{
                            background: greenishColor,
                            color: 'white',
                          }}
                          onClick={handlePopup}
                        >
                          Join Team
                        </Button>
                      </Box>
                    </Box>
                  )}
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        ) : (
          <NotFound needsLogin />
        )}
        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default JoinedLeagues;
