import React, { useEffect } from 'react';
import PaymentInvoice from './PaymentInvoice';
import { usePayments } from '../hooks/usePaymentState';
import { useParams } from 'react-router-dom';
import Sidebar from './Navigation';
import { Box, Stack } from '@chakra-ui/react';
import { useStoreState, useStoreActions } from '../store';
import Footer from './Footer';
import NotFound from './NotFound';
import Loader from './Loader';

const InvoicePage = () => {
  const params = useParams();

  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  const { payments, loading } = usePayments({ id: { equals: params?.id } });

  const payment = payments?.[0];

  const invoiceParams = {
    amount_cents: String(Number(payment?.amount) * 100),
    created_at: payment?.createdAt,
    currency: payment?.currency,
    order: payment?.orderId,
    email: payment?.player.email,
    source_data: { type: payment?.paymentMethod },
    success: payment?.success,
  };

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt="200px"
        display="flex"
        justifyContent={'space-between'}
      >
        <Box maxW="600px" margin="0 auto 0 auto" p="15px">
          {loading ? (
            <Loader />
          ) : !payment ? (
            <NotFound />
          ) : (
            <PaymentInvoice params={invoiceParams} />
          )}
        </Box>
        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default InvoicePage;
