import { Box } from '@chakra-ui/react';

import { useEffect } from 'react';

import { useStoreActions, useStoreState } from '../../store';
import Sidebar from '../Navigation';
import HomeMatchesWidget from './HomeMatchesWidget';
import HomeSlider from './HomeSections/HomeSlider';
import Leagues from './Leagues';
import Footer from '../Footer';
// import About from './HomeSections/About';
import Partners from './HomeSections/Partners';
import { useQuery } from '@apollo/client';
import { gql } from '../../__generated__/gql';

const FETCH_HOME_CONTENT = gql(`
  query FETCH_HOME_CONTENT {
    homePage {
      id
      slidesContent
      slidesImages {
        image {
          id
          url
        }
      }
      about {
        image {
          id
          url
        }
      }
      partners {
        image {
          id
          url
        }
      }
    }
  }
`);
const Home = () => {
  const { data } = useQuery(FETCH_HOME_CONTENT);

  const { userModel: userState } = useStoreState((state) => state);

  const { userModel: userActions } = useStoreActions((state) => state);

  useEffect(() => {
    userActions.setIsDark(false);
  }, [userState, userActions]);

  return (
    <Sidebar>
      <Box bg="white" w="full" minH="100vh" pt={{ base: '84px', md: '95px' }}>
        <HomeSlider data={data} />
        <Box mt="20px">
          <HomeMatchesWidget />
        </Box>
        <Box>
          <Leagues />
        </Box>
        {/* <HomeNews /> */}
        {/* <Box mt="200px">
          <About data={data} />
        </Box> */}
        <Partners data={data} />
        <Footer />
      </Box>
    </Sidebar>
  );
};

export default Home;
