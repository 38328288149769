import { useQuery } from '@apollo/client';
import { Box, Center, Container, HStack, Select, Tag } from '@chakra-ui/react';
import { useState } from 'react';
import useLeaguesState from '../../hooks/useLeaguesState';
import { MAIN_LEAGUE } from '../../utils/constants';
import { FETCH_LEAGUE_MATCHES } from '../../utils/queries';
import Loader from '../Loader';
import Layout from './Layout';
import { MatchInterface } from '../../utils/types';
import { returnDate, returnTime } from '../../utils/helper';
import { Link } from 'react-router-dom';

const AdminDashboard = () => {
  const [comp, setComp] = useState(MAIN_LEAGUE);
  const { competitionsList, currentCompetition } = useLeaguesState(comp);
  const [seasonId, setSeasonId] = useState('');

  const { data: matchesData, loading } = useQuery(FETCH_LEAGUE_MATCHES, {
    variables: {
      id: currentCompetition?.id,
      seasonId: seasonId,
    },
  });

  return (
    <div>
      <Layout />
      <Container maxW="1200px" mt="10">
        <Container>
          <HStack>
            <Select onChange={(e) => setComp(e.target.value)} value={comp}>
              {competitionsList?.map((competition) => (
                <option key={competition.id} value={competition.name}>
                  {competition.name}
                </option>
              ))}
            </Select>
            <Select
              onChange={(e) => setSeasonId(e.target.value)}
              value={seasonId}
            >
              <option value="">Select Season</option>
              {currentCompetition?.seasons.map((season) => (
                <option key={season.id} value={season.id}>
                  {season.name}
                </option>
              ))}
            </Select>
          </HStack>
        </Container>

        <Container mt="40px" maxW="800px">
          {loading ? (
            <Loader />
          ) : matchesData?.matches?.length ? (
            matchesData?.matches?.map((match: MatchInterface) => {
              return (
                <Link to={`/admin/dashboard/${match.id}`} key={match.id}>
                  <Box
                    rounded="10px"
                    p="5"
                    border="1px solid #dadada"
                    mb="15px"
                    _hover={{ boxShadow: 'lg' }}
                  >
                    <HStack justifyContent={'center'}>
                      <Box fontWeight="bold">
                        {match?.homeTeam?.teams?.[0]?.name}
                      </Box>
                      <Box>vs</Box>
                      <Box fontWeight="bold">
                        {match?.awayTeam?.teams?.[0]?.name}
                      </Box>
                      {match?.isMatchEnded ? (
                        <Box color="gray.500">Ended</Box>
                      ) : match.isMatchLive ? (
                        <Box>
                          <Tag size={'sm'} variant="solid" colorScheme="green">
                            Live
                          </Tag>
                        </Box>
                      ) : (
                        <Box>
                          {returnDate(match.matchDate)} -{' '}
                          {returnTime(match.matchDate)}
                        </Box>
                      )}
                    </HStack>
                  </Box>
                </Link>
              );
            })
          ) : (
            <Center>No matches found</Center>
          )}
        </Container>
      </Container>
    </div>
  );
};

export default AdminDashboard;
