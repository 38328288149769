import { Box, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import HeadingTitle from '../components/HeadingTitle';
import usePlayerInfo from '../hooks/usePlayerInfo';
import { useStoreActions, useStoreState } from '../store';
import Footer from './Footer';
import Sidebar from './Navigation';
import Steps from './Steps';
import AlreadyRegistered from './AlreadyRegistered';

const Register = () => {
  const playerInfo = usePlayerInfo();
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt="200px"
        display="flex"
        justifyContent={'space-between'}
      >
        <Box>
          <Box>
            <Box textAlign={'center'} mb="50px">
              <HeadingTitle>
                {playerInfo?.id ? 'Join / Migrate League' : 'Register'}
              </HeadingTitle>
            </Box>
            {playerInfo?.id ? <AlreadyRegistered /> : <Steps />}
          </Box>
        </Box>
        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default Register;
