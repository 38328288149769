import dayjs from 'dayjs';
import { useMemo } from 'react';
import LeagueTable from '../LeagueTable';
import { MatchInterface, ScoreSheet, TableType } from '../../../utils/types';
import { returnName } from '.';

export interface TableTeamdata extends TableType {
  group?: string;
  id?: string;
  logo?: string;
  teamData?: {
    id: string;
    name: string;
    group: string;
    teams: {
      id: string;
      name: string;
      teamLogo: {
        url: string;
      };
      mascotLogo: string;
    }[];
  };
}

const StandAloneStandings = ({
  matches,
  logo,
  isFull,
  removeFP,
  removeLinks,
}: {
  matches: MatchInterface[];
  logo?: string;
  isFull?: boolean;
  removeFP?: boolean;
  removeLinks?: boolean;
}) => {
  const insertIntoArr = (
    partTableData: TableType,
    match: MatchInterface,
    isPartHomeTeam: boolean
  ) => {
    const nowToTZ = dayjs(new Date()).utc().tz('Africa/Cairo');
    const dateToTZ = dayjs(match.matchDate).utc().tz('Africa/Cairo');
    const diff = nowToTZ.isAfter(dateToTZ);

    if (diff) {
      partTableData = {
        ...partTableData,
        MP: String(Number(partTableData.MP) + 1),
      };

      // checks for Lost matches if you are homeTeam or awayTeam
      if (
        (isPartHomeTeam && match.awayTeamScore > match.homeTeamScore) ||
        (!isPartHomeTeam && match.homeTeamScore > match.awayTeamScore)
      ) {
        partTableData = {
          ...partTableData,
          L: String(Number(partTableData.L) + 1),
        };
      }

      //checks for draw matches
      if (match.homeTeamScore === match.awayTeamScore) {
        partTableData = {
          ...partTableData,
          D: String(Number(partTableData.D) + 1),
          PTS: String(Number(partTableData.PTS) + 1),
        };
      }

      // checks or won matches if you are homeTeam or awayTeam
      if (
        (match.homeTeamScore > 0 &&
          isPartHomeTeam &&
          match.homeTeamScore > match.awayTeamScore) ||
        (match.awayTeamScore > 0 &&
          !isPartHomeTeam &&
          match.awayTeamScore > match.homeTeamScore)
      ) {
        partTableData = {
          ...partTableData,
          W: String(Number(partTableData.W) + 1),
          PTS: String(Number(partTableData.PTS) + 3),
        };
      }

      // adds GoalsFor
      if (match.homeTeamScore > 0 && isPartHomeTeam) {
        partTableData = {
          ...partTableData,
          GF: String(Number(partTableData.GF) + match.homeTeamScore),
        };
      }
      if (match.awayTeamScore > 0 && !isPartHomeTeam) {
        partTableData = {
          ...partTableData,
          GF: String(Number(partTableData.GF) + match.awayTeamScore),
        };
      }

      // adds GoalsAgainst
      if (match.awayTeamScore > 0 && isPartHomeTeam) {
        partTableData = {
          ...partTableData,
          GA: String(Number(partTableData.GA) + match.awayTeamScore),
        };
      }
      if (match.homeTeamScore > 0 && !isPartHomeTeam) {
        partTableData = {
          ...partTableData,
          GA: String(Number(partTableData.GA) + match.homeTeamScore),
        };
      }

      // adds goals diff
      partTableData = {
        ...partTableData,
        GD: String(Number(partTableData.GF) - Number(partTableData.GA)),
      };
    }

    return partTableData;
  };

  const tableData = useMemo(() => {
    const table = matches.reduce(
      (acc: { [key: string]: TableType }, match: MatchInterface) => {
        for (const team of [match.awayTeam, match.homeTeam]) {
          let initialPartTableData: TableTeamdata = {
            team: '',
            MP: '0',
            W: '0',
            L: '0',
            D: '0',
            GF: '0',
            GA: '0',
            GD: '0',
            FP: '0',
            PTS: '0',
            h2h: 0,
            partId: '',
            group: '',
            logo: '',
            id: '',
            scoreSheet: [],
          };
          let score: ScoreSheet = {
            isHomeTeam: false,
            homeTeam: {
              id: match.homeTeam.id,
              name: match.homeTeam.teams?.[0].name,
              teams: [{ name: match.homeTeam.teams?.[0].name }],
            },
            awayTeam: {
              id: match.awayTeam.id,
              name: match.awayTeam.teams?.[0].name,
              teams: [{ name: match.awayTeam.teams?.[0].name }],
            },
            homeTeamId: match.homeTeam.id,
            awayTeamId: match.awayTeam.id,
            homeTeamScore: match.homeTeamScore,
            awayTeamScore: match.awayTeamScore,
          };

          const name = returnName(team?.teams?.[0]?.name);

          const isPartHomeTeam = team.id === match.homeTeam.id;
          if (acc[name]) {
            const matchExists = acc[name].scoreSheet.find(
              (x) =>
                match.homeTeam.id === x.homeTeam.id &&
                match.awayTeam.id === x.awayTeam.id
            );
            if (!matchExists) {
              score.isHomeTeam = isPartHomeTeam;
              acc[name].scoreSheet.push(score);
            }
            acc[name] = insertIntoArr(acc[name], match, isPartHomeTeam);
          } else {
            initialPartTableData.team = `${match.season?.name} ${name}`;
            initialPartTableData.group = match.group;
            initialPartTableData.id = team?.teams?.[0]?.id;
            initialPartTableData.logo = team.teams?.[0]?.teamLogo?.url;
            score.isHomeTeam = isPartHomeTeam;
            initialPartTableData.scoreSheet.push(score);
            initialPartTableData.teamData = team;
            initialPartTableData.partId = team.id;
            //@ts-expect-error
            initialPartTableData.participation = isPartHomeTeam
              ? match.homeTeam
              : match.awayTeam;

            acc[name] = insertIntoArr(
              initialPartTableData,
              match,
              isPartHomeTeam
            );
          }
        }

        return acc;
      },
      {}
    );

    return Object.keys(table)
      .map((key: string) => table[key])
      .reduce((acc: { [key: string]: TableType[] }, item: TableTeamdata) => {
        const groupName = item.group || 'null';
        if (acc[groupName as string]) {
          acc[groupName as string].push(item);
        } else {
          acc[groupName as string] = [item];
        }

        return acc;
      }, {});
  }, [matches]);

  const returnSortedGroups = (data: { [key: string]: TableType[] }) => {
    return Object.keys(data)
      .sort()
      .reduce((acc: { [key: string]: TableType[] }, key: string) => {
        acc[key] = data[key];
        return acc;
      }, {});
  };

  return (
    <LeagueTable
      groups={returnSortedGroups(tableData)}
      isFull={isFull}
      removeFP={removeFP}
      removeLinks={removeLinks}
      fallbackLogo={logo}
      selectedComp={matches?.[0].competition}
    />
  );
};

export default StandAloneStandings;
