import {
  Alert,
  Box,
  Button,
  Center,
  FormLabel,
  HStack,
  Input,
  ListItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
  UnorderedList,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import HeadingTitle from '../components/HeadingTitle';
import { useStoreActions, useStoreState } from '../store';
import {
  ability,
  hometown,
  kitSize,
  mascot,
  paymentPlan,
  position,
  preferredFoot,
  skills,
} from '../utils/Select';
import { greenishColor, redishColor } from '../utils/theme';
import Footer from './Footer';
import Sidebar from './Navigation';
import {
  CREATE_PARTICIPATION,
  CREATE_PLAYER,
  CREATE_TEAM,
  UPDATE_PLAYER,
} from '../utils/mutations';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  lowerCaseNoSpaces,
  mapKeyToName,
  timeout,
  upperCaseFirstLetterWithSpaces,
} from '../utils/helper';
import {
  GET_PARTICIPATION_BY_ID,
  GET_SEASONS,
  GET_TEAM_PLAYERS,
} from '../utils/queries';
import EmptyRouteAlert from '../components/EmptyRouteAlert';
import TeamCard from '../components/TeamCard';
import useLeaguesState from '../hooks/useLeaguesState';
import { MAIN_LEAGUE } from '../utils/constants';
import { useRegistration } from '../hooks/useRegistration';

const TeamRegList = () => {
  return (
    <UnorderedList>
      <ListItem>Has to consist of one word</ListItem>
      <ListItem>Has to be in Arabic and English</ListItem>
      <ListItem>The name has to be not offensive</ListItem>
      <ListItem>The Teams logo is not mandatory</ListItem>
    </UnorderedList>
  );
};

const RegistrationPage = () => {
  const location = useLocation();

  const { type, partCode, plan, comp } = useParams();

  const [jerseyNumbers, setJerseyNumbers] = useState<Array<number>>([]);
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const [radioValue, setRadioValue] = useState('1');
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);
  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  const toast = useToast();
  const [selectedCompetition, setSelectedCompetition] = useState(MAIN_LEAGUE);

  const { currentSeason: latestSeason, publicCompetitions } = useLeaguesState();

  const [createPlayer] = useMutation(CREATE_PLAYER);
  const [createTeam] = useMutation(CREATE_TEAM);
  const [createParticipation] = useMutation(CREATE_PARTICIPATION);
  const [updatePlayer] = useMutation(UPDATE_PLAYER);
  const { checkExistanceCallback } = useRegistration();
  const currentCompetition = useMemo(() => {
    const foundComp = publicCompetitions?.find(
      (x) => x.name === upperCaseFirstLetterWithSpaces((comp as string) ?? '')
    );
    if (foundComp) return foundComp;
    return publicCompetitions?.find((x) => x.name === 'AL Elite');
  }, [publicCompetitions, comp]);

  const [getTeamPlayers, { data: teamPlayersData }] =
    useLazyQuery(GET_TEAM_PLAYERS);

  const navigate = useNavigate();
  const { data: sData } = useQuery(GET_SEASONS);

  const { data: pData, loading } = useQuery(GET_PARTICIPATION_BY_ID, {
    variables: { id: partCode ?? '' },
  });

  const existingTeam = useMemo(() => {
    return pData?.participation?.teams[0];
  }, [pData]);
  const teamCode = useMemo(() => {
    return existingTeam?.id;
  }, [existingTeam]);

  const currentSeason = useMemo(() => {
    return currentCompetition?.currentSeason ?? latestSeason;
  }, [currentCompetition?.currentSeason, latestSeason]);

  const teamJerseyNumbers = useMemo(() => {
    return teamPlayersData?.data?.players.map((x: any) => x.jerseyNumber);
  }, [teamPlayersData]);

  const fetchJerseyNumbers = useCallback(async () => {
    const allJerseyNumbers = Array.from(Array(99).keys()).map((x) => x + 1);
    const participationId =
      pData && pData.participation ? pData.participation.id : '';
    if (participationId) {
      const playersData = await getTeamPlayers({
        variables: { id: participationId },
      });
      if (playersData?.data?.players.length) {
        const numbers = playersData?.data?.players.map(
          (x: any) => x.jerseyNumber
        );
        return allJerseyNumbers.filter((x) => !numbers.includes(x));
      }
    }
    return allJerseyNumbers;
  }, [getTeamPlayers, pData]);

  useEffect(() => {
    if (!jerseyNumbers.length) {
      fetchJerseyNumbers()
        .then((nunbers: Array<number>) => {
          setJerseyNumbers(nunbers);
        })
        .catch((err) => console.log(err));
    }
  }, [existingTeam, jerseyNumbers.length, fetchJerseyNumbers]);

  useEffect(() => {
    if (comp) {
      setSelectedCompetition(upperCaseFirstLetterWithSpaces(comp));
    } else {
      navigate(`${location.pathname}/al-elite`);
    }
  }, [location, navigate, comp]);

  useEffect(() => {
    if (currentCompetition?.isTournament) {
      navigate(
        `/register/tournaments/${lowerCaseNoSpaces(currentCompetition?.name)}`
      );
    }
  }, [currentCompetition, navigate]);

  const leaguePath = useMemo(() => {
    if (comp && location.pathname) {
      const path = location.pathname.split('/');
      return path.splice(0, path.length - 1).join('/');
    }
    return location.pathname;
  }, [location, comp]);

  const invalidRoute = useMemo(() => {
    if (
      (type && !['team', 'player', 'transfer-market'].includes(type)) ||
      (partCode && !existingTeam && !loading) ||
      (plan && !['full', 'half', 'free-agent'].includes(plan))
    ) {
      return true;
    }
    return false;
  }, [existingTeam, loading, plan, partCode, type]);

  const leftMascots = useMemo(() => {
    if (
      pData &&
      sData &&
      pData.participations &&
      sData.seasons &&
      sData.seasons.length &&
      pData.participations.length
    ) {
      const seasonsCount = sData.seasons.length;
      return mascot.filter(
        (m) =>
          !pData.participations.some(
            (p: any) =>
              p.name.toLowerCase().includes(m.toLowerCase()) &&
              p.seasons.some(
                (season: any) => season.seasonNumber === seasonsCount
              )
          )
      );
    } else {
      return mascot;
    }
  }, [pData, sData]);

  const [teamData, setTeamData] = useState({
    name: '',
    mascot: '',
    secondMascot: '',
  });
  const [errorMsg, setErrorMsg] = useState('');
  const [arabicName, setAraiicName] = useState('');
  const [nationalIdImage, setNationalIdImage] = useState<File | null>(null);
  const [teamLogo, setTeamLogo] = useState<File | null>(null);
  const [photo, setPhoto] = useState<File | null>(null);
  const nationalIdRef = useRef(null);
  const teamLogoRef = useRef(null);
  const photoRef = useRef(null);
  const handleChangeRadioValue = (nextValue: string) => {
    setRadioValue(nextValue);
    if (nextValue === '2') {
      setTeamData({ ...teamData, mascot: '', secondMascot: '' });
    }
    if (nextValue === '1') {
      setTeamLogo(null);
    }
  };
  const [playerData, setPlayerData] = useState({
    firstName: '',
    lastName: '',
    nickname: '',
    playerID: '',
    phoneNumber: '',
    hometown: '',
    weight: '',
    height: '',
    position: '',
    dateOfBirth: '',
    secondPosition: '',
    ability: '',
    skill: '',
    preferredFoot: '',
    jerseyName: '',
    jerseyNumber: '',
    kitSize: '',
    email: '',
    bio: '',
  });

  const playerSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    nickname: yup.string().required(),
    playerID: yup.string().required(),
    phoneNumber: yup.string().required(),
    hometown: yup.string().required(),
    weight: yup.number().required(),
    height: yup.number().required(),
    position: yup.string().required(),
    dateOfBirth: yup.string().required(),
    secondPosition: yup.string().required(),
    ability: yup.string().required(),
    skill: yup.string().required(),
    jerseyName: yup.string().required(),
    preferredFoot: yup.string().required(),
    jerseyNumber: yup.number().required(),
    kitSize: yup.string().required(),
    email: yup.string().email().required(),
    bio: yup.string(),
  });
  const teamSchema = yup.object().shape({
    name: yup.string().required(),
    mascot: yup.string().required(),
    secondMascot: yup.string().required(),
  });

  const ownTeamSchema = yup.object().shape({
    name: yup.string().required(),
    arabicName: yup.string().required(),
  });

  const handleTeamChange = (value: string, type: string) => {
    setErrorMsg('');
    setTeamData({ ...teamData, [type]: value });
  };

  const handlePlayerChange = (value: string, type: string) => {
    setErrorMsg('');
    setPlayerData({
      ...playerData,
      [type]: value,
    });
  };

  const handleSubmit = async () => {
    setErrorMsg('');
    if (currentCompetition?.disableRegistration) {
      toast({
        title: `Regostration is disabled for this league`,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (teamJerseyNumbers?.length) {
      if (teamJerseyNumbers.includes(playerData.jerseyNumber)) {
        toast({
          title: `This jersey number is already taken`,
          status: 'error',
          isClosable: true,
        });
        return;
      }
    }

    try {
      const adjustedPlayerData = {
        ...playerData,
        email: playerData.email.toLowerCase(),
        jerseyNumber: Number(playerData.jerseyNumber),
        weight: Number(playerData.weight),
        height: Number(playerData.height),
        isCaptain: Boolean(type === 'team'),
      };

      await checkExistanceCallback({
        email: playerData.email.toLowerCase(),
        phoneNumber: playerData.phoneNumber,
        teamName: teamData.name,
        playerID: playerData.playerID,
      });

      if (!teamCode && !existingTeam && plan !== 'free-agent') {
        const checkedSchema = radioValue === '1' ? teamSchema : ownTeamSchema;
        const ObjToValidate =
          radioValue === '1' ? teamData : { name: teamData.name, arabicName };
        const teamValidationResult = await checkedSchema
          .validate(ObjToValidate)
          .catch((err) => {
            return err;
          });

        if (teamValidationResult.message) {
          setErrorMsg(teamValidationResult.message);
          return;
        }
      }

      const validationResult = await playerSchema
        .validate(adjustedPlayerData)
        .catch((err) => {
          return err;
        });

      if (validationResult.message) {
        setErrorMsg(validationResult.message);
        return;
      }

      toast({
        title: 'Creating Player...',
        status: 'loading',
        isClosable: true,
      });
      await timeout(2000);
      if (
        teamData?.name.toLowerCase().includes('transfer') ||
        pData?.participation?.name.toLowerCase().includes('transfer market')
      ) {
        throw new Error(
          'You can not use this name, this is a reserved word, please choose another name'
        );
      }

      if (
        pData?.participation?.playersCount &&
        pData?.participation?.competition?.maxPlayersCount &&
        pData?.participation?.playersCount >=
          pData?.participation?.competition?.maxPlayersCount
      ) {
        throw new Error('Team is full');
      }

      const createPlayerResponse = await createPlayer({
        variables: {
          ...adjustedPlayerData,
          paymentPlan: plan
            ? paymentPlan[plan as 'full' | 'half' | 'free-agent']
            : 'Free Agent',
          nationalIdImage: {
            upload: nationalIdImage as string | Blob,
          },
          photo: {
            upload: photo as string | Blob,
          },
        },
      });
      if (!createPlayerResponse.data.createPlayer.id) {
        throw new Error('Failed to create player');
      }
      toast({
        title: 'Player created successfully!',
        status: 'success',
        isClosable: true,
      });
      await timeout(300);

      if (type === 'player' && pData && currentSeason) {
        const participationId = pData?.participation?.id ?? '';

        if (!participationId) {
          throw new Error('No participation found');
        }
        const updatedPlayerData = {
          participation: {
            connect: [{ id: participationId }],
          },
        };
        await updatePlayer({
          variables: {
            id: createPlayerResponse.data.createPlayer.id,
            data: updatedPlayerData,
          },
        });
      }
      if (type === 'team' && currentSeason) {
        await timeout(1000);
        toast({
          title: 'Creating Team...',
          status: 'loading',
          isClosable: true,
        });

        const ownTeam = {
          name: teamData.name,
          arabicName,
        };

        const adjustedTeamData =
          radioValue === '2'
            ? teamLogo
              ? {
                  ...ownTeam,
                  teamLogo: {
                    upload: teamLogo as string | Blob,
                  },
                }
              : ownTeam
            : teamData;

        const res = await createTeam({
          variables: { ...adjustedTeamData },
        });
        if (!res.data.createTeam.id) {
          throw new Error('failed to create team');
        }
        toast({
          title: `Team ${teamData.name} created successfully!`,
          status: 'success',
          isClosable: true,
        });
        await timeout(2000);
        toast.closeAll();
        let participationData = {
          name: `${currentSeason.name} ${teamData.name}${
            currentCompetition?.name !== 'AL Elite'
              ? ` ${currentCompetition?.name}`
              : ''
          }`,
          seasons: { connect: [{ id: currentSeason.id }] },
          teams: { connect: [{ id: res.data.createTeam.id }] },
          competition: { connect: { id: currentCompetition?.id } },
          teamAdmin: {
            connect: { id: createPlayerResponse.data.createPlayer.id },
          },
          isVerified: false,
        };
        if (!currentCompetition?.id) {
          toast({
            title: `No competition found`,
            status: 'error',
            isClosable: true,
          });
          return;
        }
        const pRes = await createParticipation({
          variables: { data: participationData },
        });
        const updatedPlayerData = {
          participation: {
            connect: [{ id: pRes.data.createParticipation.id }],
          },
        };
        await updatePlayer({
          variables: {
            id: createPlayerResponse.data.createPlayer.id,
            data: updatedPlayerData,
          },
        });
      }

      toast({
        title: 'Registration complete!, please login to your account ✌️',
        status: 'success',
        isClosable: true,
      });
      navigate(`/login`);
    } catch (err) {
      console.log(err instanceof Error);
      if (err) {
        if (
          err.message &&
          err.message.includes('Unique constraint failed on the fields:')
        ) {
          const splitErr = err.message.split(
            'Unique constraint failed on the fields: '
          );

          const field = splitErr[1];

          toast({
            title: `This ${field} is already being used and associated with another player`,
            status: 'error',
            isClosable: true,
          });
          return;
        }
        toast({
          title: err.message,
          status: 'error',
          isClosable: true,
        });
      }
    }
  };

  return (
    <Sidebar>
      <Stack
        bg={'white'}
        minH="100vh"
        display="flex"
        justifyContent={'space-between'}
      >
        {invalidRoute ? (
          <Box mt="150px">
            <EmptyRouteAlert
              title={
                teamCode && existingTeam
                  ? 'WRONG TEAM SECURITY CODE, OR TEAM DOES NOT EXIST'
                  : ''
              }
            />
          </Box>
        ) : (
          <Box w={{ base: '100%', md: '750px' }} m="0 auto" pt="200px">
            <Box textAlign={'center'}>
              <HeadingTitle>
                {type === 'player'
                  ? 'Player'
                  : type === 'team'
                  ? 'Team'
                  : 'Free Agent'}{' '}
                Registration
              </HeadingTitle>
            </Box>
            <Box w="100%">
              <FormLabel>League</FormLabel>
              <Select
                mt="4"
                fontWeight={'bold'}
                fontSize="lg"
                value={selectedCompetition}
                onChange={(e) => {
                  navigate(
                    `${leaguePath}/${lowerCaseNoSpaces(e.target.value)}`
                  );
                }}
              >
                {publicCompetitions?.map((league) => (
                  <option
                    key={league.id}
                    value={league.name}
                    disabled={league?.disableRegistration}
                  >
                    {mapKeyToName(league.name)}
                  </option>
                ))}
              </Select>
            </Box>
            {currentCompetition?.disableRegistration ? (
              <Center my="30px">
                Registration is disabled for this league
              </Center>
            ) : (
              <Box>
                <Box fontWeight="600" textAlign={'center'} mt="30px">
                  <Text>Payment to be requested prior to team assignment</Text>
                </Box>
                <Box mt="20px">
                  {existingTeam ? (
                    <TeamCard
                      team={existingTeam}
                      competition={pData?.participation?.competition}
                    />
                  ) : null}
                  {type === 'transfer-market' ||
                  type === 'player' ||
                  existingTeam ? null : (
                    <Box
                      border="1px"
                      borderColor={borderColor}
                      p="20px"
                      borderRadius={'15px'}
                    >
                      <Stack mb="15px">
                        <FormLabel fontSize="lg" fontWeight={'bold'}>
                          Team Information
                        </FormLabel>
                        <RadioGroup
                          onChange={handleChangeRadioValue}
                          value={radioValue}
                        >
                          <Stack direction="row">
                            <Radio value="1">Choose from system Mascots</Radio>
                            <Radio value="2">Create my own team</Radio>
                          </Stack>
                        </RadioGroup>
                        {radioValue === '2' ? (
                          <Box>
                            <Text fontWeight="bold">Team's Name:</Text>
                            <Alert status="info" rounded="10px">
                              <TeamRegList />
                            </Alert>
                          </Box>
                        ) : null}
                        {teamData.name && teamData.mascot ? (
                          <Text>
                            Team Name will be:{' '}
                            <Text
                              as="span"
                              color={redishColor}
                              fontWeight="bold"
                            >
                              {teamData.name}{' '}
                              {radioValue === '1' ? teamData.mascot : ''}
                            </Text>
                          </Text>
                        ) : null}
                      </Stack>

                      <HStack mt="20px">
                        <Box w="100%">
                          <FormLabel>Please Enter your Team Name</FormLabel>
                          <Input
                            value={
                              existingTeam ? existingTeam.name : teamData.name
                            }
                            disabled={Boolean(existingTeam)}
                            placeholder={'Team Name'}
                            _placeholder={{ color: 'black' }}
                            onChange={(e) =>
                              handleTeamChange(e.target.value, 'name')
                            }
                          />
                        </Box>
                        {radioValue === '2' ? (
                          <Box w="100%">
                            <FormLabel>Please Enter Team Arabic Name</FormLabel>
                            <Input
                              value={arabicName}
                              type="text"
                              disabled={Boolean(existingTeam)}
                              placeholder={'Team Arabic Name'}
                              _placeholder={{ color: 'black' }}
                              onChange={(e) => {
                                setAraiicName(e.target.value);
                              }}
                            />
                          </Box>
                        ) : null}
                      </HStack>

                      {radioValue === '2' ? (
                        <Box w="100%" mt="15px">
                          <FormLabel>Please Upload your Team Logo</FormLabel>
                          <Input
                            ref={teamLogoRef}
                            type="file"
                            onChange={(e) => {
                              if (e.target.files) {
                                if (e.target.files.length) {
                                  setTeamLogo(e.target.files[0]);
                                }
                              }
                            }}
                          />
                        </Box>
                      ) : null}
                      {radioValue === '1' ? (
                        <HStack mt="10px">
                          <Select
                            placeholder="Select Mascot"
                            value={
                              existingTeam
                                ? existingTeam.mascot
                                : teamData.mascot
                            }
                            disabled={Boolean(existingTeam)}
                            onChange={(e) =>
                              handleTeamChange(e.target.value, 'mascot')
                            }
                          >
                            {leftMascots?.map((x) => (
                              <option value={x} key={x}>
                                {x}
                              </option>
                            ))}
                          </Select>
                          <Select
                            placeholder="Select Second Mascot"
                            value={
                              existingTeam
                                ? existingTeam.secondMascot
                                : teamData.secondMascot
                            }
                            disabled={Boolean(existingTeam)}
                            onChange={(e) =>
                              handleTeamChange(e.target.value, 'secondMascot')
                            }
                          >
                            {leftMascots?.map((x) => (
                              <option value={x} key={x}>
                                {x}
                              </option>
                            ))}
                          </Select>
                        </HStack>
                      ) : null}
                    </Box>
                  )}

                  <Box
                    border="1px"
                    borderColor={borderColor}
                    p="20px"
                    borderRadius={'15px'}
                    mt="20px"
                  >
                    <FormLabel fontSize="lg" mb="15px" fontWeight={'bold'}>
                      Player Information
                    </FormLabel>

                    <HStack mt="10px">
                      <Input
                        name="firstName"
                        placeholder={'First Name'}
                        _placeholder={{ color: 'black' }}
                        value={playerData.firstName}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'firstName')
                        }
                      />
                      <Input
                        name="lastName"
                        placeholder={'Last Name'}
                        _placeholder={{ color: 'black' }}
                        value={playerData.lastName}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'lastName')
                        }
                      />
                    </HStack>
                    <HStack mt="10px">
                      <Input
                        name="nickname"
                        placeholder={'Nickname/Preferred Name'}
                        _placeholder={{ color: 'black' }}
                        value={playerData.nickname}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'nickname')
                        }
                      />
                      <Input
                        name="playerID"
                        placeholder={'National ID/Official ID'}
                        _placeholder={{ color: 'black' }}
                        value={playerData.playerID}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'playerID')
                        }
                      />
                    </HStack>
                    <HStack mt="10px">
                      <Box w="100%">
                        <FormLabel>Please Scan Official ID/Doc</FormLabel>
                        <Input
                          ref={nationalIdRef}
                          type="file"
                          onChange={(e) => {
                            if (e.target.files) {
                              if (e.target.files.length) {
                                setNationalIdImage(e.target.files[0]);
                              }
                            }
                          }}
                        />
                      </Box>
                      <Box w="100%">
                        <FormLabel>Please Upload your Photo</FormLabel>
                        <Input
                          ref={photoRef}
                          type="file"
                          onChange={(e) => {
                            if (e.target.files) {
                              if (e.target.files.length) {
                                setPhoto(e.target.files[0]);
                              }
                            }
                          }}
                        />
                      </Box>
                    </HStack>

                    <HStack mt="10px">
                      <Box w="100%">
                        <FormLabel>Email address</FormLabel>

                        <Input
                          name="email"
                          value={playerData.email}
                          onChange={(e) =>
                            handlePlayerChange(e.target.value, 'email')
                          }
                          placeholder={'Email Address'}
                          _placeholder={{ color: 'black' }}
                        />
                      </Box>
                      <Box w="100%">
                        <FormLabel>Date of Birth</FormLabel>

                        <Input
                          name="dateOfBirth"
                          value={playerData.dateOfBirth}
                          onChange={(e) =>
                            handlePlayerChange(e.target.value, 'dateOfBirth')
                          }
                          type="date"
                          _placeholder={{ color: 'black' }}
                        />
                      </Box>
                    </HStack>
                    <HStack>
                      <Box w="100%">
                        <FormLabel>Phone Number</FormLabel>

                        <Input
                          name="phoneNumber"
                          value={playerData.phoneNumber}
                          onChange={(e) =>
                            handlePlayerChange(e.target.value, 'phoneNumber')
                          }
                          placeholder={'Phone Number'}
                          _placeholder={{ color: 'black' }}
                        />
                      </Box>

                      <Select
                        mt="32px"
                        name="skill"
                        placeholder="Strongest Skill"
                        value={playerData.skill}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'skill')
                        }
                      >
                        {skills.map((x) => (
                          <option value={x} key={x}>
                            {x}
                          </option>
                        ))}
                      </Select>
                    </HStack>

                    <HStack mt="10px">
                      <Input
                        name="weight"
                        value={playerData.weight}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'weight')
                        }
                        placeholder={'Weight in KG'}
                        _placeholder={{ color: 'black' }}
                      />
                      <Input
                        name="height"
                        value={playerData.height}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'height')
                        }
                        placeholder={'Height in CM'}
                        _placeholder={{ color: 'black' }}
                      />
                    </HStack>

                    <HStack mt="10px">
                      <Box w="100%">
                        <Input
                          name="jerseyName"
                          value={playerData.jerseyName}
                          onChange={(e) =>
                            handlePlayerChange(e.target.value, 'jerseyName')
                          }
                          placeholder={'Jersey Name'}
                          _placeholder={{ color: 'black' }}
                        />
                      </Box>
                      <Box w="100%">
                        <Select
                          name="jerseyNumber"
                          placeholder="Jersey Number"
                          value={playerData.jerseyNumber}
                          onChange={(e) =>
                            handlePlayerChange(e.target.value, 'jerseyNumber')
                          }
                        >
                          {jerseyNumbers.map((x) => (
                            <option value={x} key={x}>
                              {x}
                            </option>
                          ))}
                        </Select>
                      </Box>
                    </HStack>

                    <HStack mt="10px">
                      <Select
                        name="hometown"
                        placeholder="Hometown"
                        value={playerData.hometown}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'hometown')
                        }
                      >
                        {hometown.map((x) => (
                          <option value={x} key={x}>
                            {x}
                          </option>
                        ))}
                      </Select>
                      <Select
                        name="kitSize"
                        placeholder="Kit Size"
                        value={playerData.kitSize}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'kitSize')
                        }
                      >
                        {kitSize.map((x) => (
                          <option value={x} key={x}>
                            {x}
                          </option>
                        ))}
                      </Select>
                    </HStack>

                    <HStack mt="10px">
                      <Select
                        name="position"
                        placeholder="Position"
                        value={playerData.position}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'position')
                        }
                      >
                        {position.map((x) => (
                          <option value={x} key={x}>
                            {x}
                          </option>
                        ))}
                      </Select>
                      <Select
                        name="secondPosition"
                        placeholder="Secondary Position"
                        value={playerData.secondPosition}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'secondPosition')
                        }
                      >
                        {position.map((x) => (
                          <option value={x} key={x}>
                            {x}
                          </option>
                        ))}
                      </Select>
                    </HStack>

                    <HStack mt="10px">
                      <Select
                        name="ability"
                        placeholder="Ability"
                        value={playerData.ability}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'ability')
                        }
                      >
                        {ability.map((x) => (
                          <option value={x} key={x}>
                            {x}
                          </option>
                        ))}
                      </Select>
                      <Select
                        name="preferredFoot"
                        placeholder="Preferred Foot"
                        value={playerData.preferredFoot}
                        onChange={(e) =>
                          handlePlayerChange(e.target.value, 'preferredFoot')
                        }
                      >
                        {preferredFoot.map((x) => (
                          <option value={x} key={x}>
                            {x}
                          </option>
                        ))}
                      </Select>
                    </HStack>

                    {plan === 'free-agent' ? (
                      <Box mt="10px">
                        <Textarea
                          placeholder={
                            'Describe your self as a player for potential clubs'
                          }
                          _placeholder={{ color: 'black' }}
                          onChange={(e) =>
                            handlePlayerChange(e.target.value, 'bio')
                          }
                          value={playerData.bio}
                        ></Textarea>
                      </Box>
                    ) : null}

                    <Box mt="20px" fontStyle="italic">
                      I here declare that I've read and that I agree to the
                      <Link to="/league-rules">
                        <Text
                          as="span"
                          mx="5px"
                          color={redishColor}
                          textDecoration="underline"
                        >
                          league/matchday rules and player code of conduct
                        </Text>
                      </Link>
                    </Box>
                    {errorMsg ? (
                      <Box color={'red.400'} textAlign="center">
                        {errorMsg}
                      </Box>
                    ) : null}
                    <Box textAlign={'center'} mt="20px">
                      <Button
                        rounded="full"
                        minW="300px"
                        bg={greenishColor}
                        fontWeight="normal"
                        color={'white'}
                        onClick={handleSubmit}
                      >
                        Register
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default RegistrationPage;
