import { Box, HStack, Link, Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FiMail } from 'react-icons/fi';
import HeadingTitle from '../components/HeadingTitle';
import { useStoreActions, useStoreState } from '../store';
import { greenishColor } from '../utils/theme';
import Footer from './Footer';
import Sidebar from './Navigation';

const Contact = () => {
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt={{ base: '125px', md: '200px' }}
        display="flex"
        justifyContent={'space-between'}
      >
        <Box>
          <Box textAlign={'center'} mb="50px">
            <HeadingTitle>Get in touch</HeadingTitle>
          </Box>
          <Text
            margin="30px auto"
            fontSize="lg"
            maxW="900px"
            textAlign="center"
            px="15px"
          >
            If you have any questions or queries a member of staff will always
            be happy to help. Feel free to contact us by telephone or email and
            we will be sure to get back to you as soon as possible.
          </Text>
          <HStack
            maxW="800px"
            m="100px auto 0"
            justifyContent="center"
            flexDir={{ base: 'column', md: 'row' }}
          >
            {/* <Stack
              textAlign={'center'}
              alignItems={'center'}
              w="350px"
              mb={{ base: '30px', md: '0' }}
            >
              <FiTablet size="40" />
              <Text fontSize="xl">Phone Number</Text>
              <Link
                href="tel:+20 1019 1877 77"
                fontSize="lg"
                fontWeight={'bold'}
                color={greenishColor}
              >
                +20 1019 1877 77
              </Link>
            </Stack> */}

            <Stack textAlign={'center'} alignItems={'center'} w="350px">
              <FiMail size="40" />
              <Text fontSize="xl">Email Address</Text>
              <Link
                href="mailto:contact@amateursleague.com"
                fontSize="lg"
                fontWeight={'bold'}
                color={greenishColor}
              >
                contact@amateursleague.com
              </Link>
            </Stack>
          </HStack>
        </Box>

        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default Contact;
