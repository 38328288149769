const { REACT_APP_SERVER_URL, REACT_APP_API_URL, REACT_APP_IS_TEST_PAYMENT } =
  process.env;

export const API_URL = REACT_APP_API_URL ?? 'http://localhost:3000/api/graphql';

export const SERVER_URL = REACT_APP_SERVER_URL ?? 'http://localhost:3000';

export const pricingPlans = {
  player: '750',
  team: '550',
  halfSeason: '2,550 ',
  fullSeason: '2,400',
};

export const paymentLinks = {
  team: 'https://cowpay.me/PRYAUC',
  player: 'https://cowpay.me/P95ID0',
};

export const MAIN_LEAGUE = 'AL Elite';

export const IS_TEST_PAYMET = Boolean(REACT_APP_IS_TEST_PAYMENT);

export const officeTypeMatch = ['Chess', 'Pingpong', 'Backgammon'];
