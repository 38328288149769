import Login from './Login';

const Admin = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default Admin;
