import { action, Action } from 'easy-peasy';

export interface PopupsModel {
  showSeasonPopup: boolean;
  setShowSeasonPopup: Action<PopupsModel, boolean>;
  showRegisterPopup: boolean;
  setShowRegisterPopup: Action<PopupsModel, boolean>;
  showPlanPopupChecker: boolean;
  setShowPlanPopupChecker: Action<PopupsModel, boolean>;
}

const popupsModel: PopupsModel = {
  showSeasonPopup: false,
  setShowSeasonPopup: action((state, payload) => {
    state.showSeasonPopup = payload;
  }),
  showRegisterPopup: false,
  setShowRegisterPopup: action((state, payload) => {
    state.showRegisterPopup = payload;
  }),
  showPlanPopupChecker: false,
  setShowPlanPopupChecker: action((state, payload) => {
    state.showPlanPopupChecker = payload;
  }),
};

export default popupsModel;
