import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import useLeaguesState, { Participation } from '../hooks/useLeaguesState';
import useDebounce from '../hooks/useDebounce';

import usePlayerInfo from '../hooks/usePlayerInfo';
import { useStoreActions, useStoreState } from '../store';
import { mapKeyToName, timeout } from '../utils/helper';
import {
  CREATE_PARTICIPATION,
  CREATE_TEAM,
  UPDATE_PLAYER,
} from '../utils/mutations';
import { GET_PARTICIPATION_BY_ID, GET_PLAYER } from '../utils/queries';
import { greenishColor } from '../utils/theme';

const SeasonPopup = () => {
  const { popupsModel: popupsState } = useStoreState((state) => state);
  const { popupsModel: popupsActions } = useStoreActions((state) => state);

  const [comp, setComp] = useState('');

  const {
    currentSeason,
    currentTeam,
    notInCurrentSeason,
    isTransferMarket,
    currentParticipation,
    participatedLastSeason,
    publicCompetitions,
    currentCompetition,
    joinedTeams,
  } = useLeaguesState(comp);

  const playerInfo = usePlayerInfo();
  const [createTeam] = useMutation(CREATE_TEAM);
  const [createParticipation] = useMutation(CREATE_PARTICIPATION);
  const [updatePlayer] = useMutation(UPDATE_PLAYER);
  const [getPlayer] = useLazyQuery(GET_PLAYER);
  const [value, setValue] = useState('');
  const [teamName, setTeamName] = useState('');
  const [choiceValue, setChoiceValue] = useState('1');
  const debouncedSearch = useDebounce(value, 200);
  const [selectedPart, setSelectedPart] = useState('');
  const handleSelectComp = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setComp(e.target.value);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { data: participationData } = useQuery(GET_PARTICIPATION_BY_ID, {
    variables: { id: debouncedSearch, name: comp },
  });
  const existingTeam = useMemo(() => {
    return participationData?.participation?.seasons[0].seasonNumber ===
      participationData?.seasonsCount
      ? participationData?.participation.teams[0]
      : null;
  }, [participationData]);

  const handleClick = async () => {
    try {
      setIsSubmitting(true);
      if (!playerInfo) return;
      if (
        participationData?.participation?.name
          .toLowerCase()
          .includes('transfer')
      ) {
        throw new Error(
          'You can not use this name, this is a reserved word, please create a new name'
        );
      }
      let updatedPlayerData: any = {};
      let toastTitle = '';
      if (existingTeam && existingTeam.id) {
        if (
          participationData?.participation?.playersCount &&
          participationData?.participation?.competition?.maxPlayersCount &&
          participationData?.participation?.playersCount >=
            participationData?.participation?.competition?.maxPlayersCount
        ) {
          throw new Error(
            `Team is full, max players count is ${participationData?.participation?.competition?.maxPlayersCount}`
          );
        }
        updatedPlayerData = {
          participation: {
            connect: [{ id: debouncedSearch }],
          },
        };
        if (isTransferMarket && currentParticipation) {
          updatedPlayerData = {
            isCaptain: false,
            participation: {
              connect: [{ id: debouncedSearch }],
              disconnect: [{ id: currentParticipation.id }],
            },
          };
        }
        toastTitle = `Joining team ${existingTeam.name}...`;
      }
      if (
        (choiceValue === '1' && playerInfo.isCaptain && selectedPart) ||
        (teamName && choiceValue === '2')
      ) {
        if (teamName.toLowerCase().includes('transfer')) {
          throw new Error(
            'You can not use this name, this is a reserved word, please create a new name'
          );
        }

        let theTeamName = '';
        let teamId = '';
        if (choiceValue === '1' && selectedPart) {
          const curPart = joinedTeams.find((p: Participation) => {
            return p.id === selectedPart;
          });
          if (!curPart) throw new Error('Participation not found');
          theTeamName = curPart?.teams[0].name;
          teamId = curPart?.teams[0].id;
        } else if (choiceValue === '2' && teamName) {
          theTeamName = teamName;
          const res = await createTeam({
            variables: { name: teamName, arabicName: '' },
          });

          teamId = res?.data?.createTeam?.id;
        }

        const participationData = {
          name: `${currentSeason?.name} ${theTeamName} ${mapKeyToName(
            currentCompetition?.name
          )}`,
          seasons: { connect: [{ id: currentSeason?.id }] },
          teams: { connect: [{ id: teamId }] },
          competition: { connect: { id: currentCompetition?.id } },
          isVerified: false,
          teamAdmin: { connect: { id: playerInfo.id } },
        };

        toastTitle = `Migrating team ${theTeamName} to ${currentSeason?.name} in ${currentCompetition?.name} league...`;

        const pRes = await createParticipation({
          variables: { data: participationData },
        });

        updatedPlayerData = {
          isCaptain: true,
          participation: {
            connect: [{ id: pRes.data.createParticipation.id }],
          },
        };
      }

      toast({
        title: toastTitle,
        status: 'loading',
        isClosable: true,
      });

      if (updatedPlayerData) {
        await updatePlayer({
          variables: {
            id: playerInfo.id,
            data: updatedPlayerData,
          },
        });
      }

      const res = await getPlayer({ variables: { id: playerInfo.id } });
      if (res && res?.data) {
        const values = res?.data?.player ?? res?.data;
        const newPlayerInfo = values;
        localStorage.setItem('playerInfo', JSON.stringify(newPlayerInfo));
      }
      await timeout(2000);
      toast({
        title: `Team Joined${!existingTeam ? '/Created' : ''} Successfully!`,
        status: 'success',
        isClosable: true,
      });
      await timeout(1000);
      window.location.href = '/joined-leagues';
      handleClose();
    } catch (error) {
      console.log(error);
      toast({
        title: error.message,
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    onClose();
    setTeamName('');
    setChoiceValue('1');
    setValue('');
    setComp('');
    popupsActions.setShowSeasonPopup(false);
  };

  useEffect(() => {
    if (popupsState.showSeasonPopup) {
      onOpen();
    }
  }, [onOpen, popupsState.showSeasonPopup]);

  const handleChangeChoiceValue = (nextValue: string) => {
    setChoiceValue(nextValue);
    if (nextValue === '1') {
      setTeamName('');
    }
    if (nextValue === '2') {
      setValue('');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Join / Migrate League</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box maxW="350px" m="0 auto 20px">
            <Select fontWeight={'bold'} onChange={handleSelectComp}>
              <option value="">Choose League</option>
              {publicCompetitions?.map((comp) => (
                <option
                  key={comp.id}
                  value={comp.name}
                  disabled={comp.disableRegistration}
                >
                  {comp.name}
                </option>
              ))}
            </Select>
          </Box>
          {comp && !notInCurrentSeason ? (
            <Box>
              You are currently a player of{' '}
              <Text as="span" color={greenishColor} fontWeight="bold">
                {currentTeam?.name}
              </Text>{' '}
              for {currentSeason?.name} and you cannot migrate or create any
              team to this league until you leave your current team
            </Box>
          ) : comp && currentCompetition ? (
            <Box>
              <Box>
                You are currently not joining any team in this league for
                current season
              </Box>

              <>
                <Box mt="10px">
                  <Box mt="15px">
                    <RadioGroup
                      onChange={handleChangeChoiceValue}
                      value={choiceValue}
                      colorScheme="green"
                    >
                      <Stack>
                        {playerInfo?.isCaptain && joinedTeams?.length ? (
                          <>
                            <Radio value="1">
                              Join {currentSeason?.name} with one of my teams{' '}
                            </Radio>
                            {choiceValue === '1' && joinedTeams?.length ? (
                              <Select
                                my="10px"
                                onChange={(e) =>
                                  setSelectedPart(e.target.value)
                                }
                                value={selectedPart}
                              >
                                <option value="">Select Team</option>
                                {joinedTeams.map((p: Participation) => {
                                  return <option value={p.id}>{p.name}</option>;
                                })}
                              </Select>
                            ) : null}
                          </>
                        ) : null}
                        <Radio value="2">Create a new team</Radio>
                        {choiceValue === '2' ? (
                          <Box mt="15px">
                            <Input
                              size="lg"
                              fontSize="xl"
                              value={teamName}
                              fontWeight="bold"
                              onChange={(e) => setTeamName(e.target.value)}
                              placeholder="Enter Team Name"
                              p="30px"
                              textAlign={'center'}
                            />
                          </Box>
                        ) : null}
                        <Radio value="3">Join other team</Radio>
                        {choiceValue === '3' ? (
                          <Box>
                            <FormControl
                              isInvalid={Boolean(
                                debouncedSearch && !existingTeam
                              )}
                            >
                              <Input
                                size="lg"
                                fontSize="xl"
                                value={value}
                                fontWeight="bold"
                                onChange={(e) => setValue(e.target.value)}
                                placeholder="Enter Team Security Code"
                                p="30px"
                                textAlign={'center'}
                              />
                              {!existingTeam ? (
                                <FormErrorMessage>
                                  Wrong Team Security Code / Team Participation
                                  does not exist
                                </FormErrorMessage>
                              ) : null}
                            </FormControl>
                          </Box>
                        ) : null}
                      </Stack>
                    </RadioGroup>
                  </Box>
                </Box>
              </>
            </Box>
          ) : null}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
          {!comp || participatedLastSeason ? null : (
            <Button
              ml={3}
              bg={greenishColor}
              fontWeight="normal"
              color={'white'}
              onClick={handleClick}
              isLoading={isSubmitting}
              isDisabled={
                isSubmitting ||
                (choiceValue === '1' && !selectedPart) ||
                (choiceValue === '3' && !existingTeam) ||
                (choiceValue === '2' && !teamName)
              }
              _hover={{ bg: greenishColor, opacity: '0.9' }}
            >
              {choiceValue === '1'
                ? 'Join now'
                : choiceValue === '2'
                ? 'Creare new team'
                : 'Join other team'}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SeasonPopup;
