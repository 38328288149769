import {
  Box,
  Image,
  Text,
  Heading,
  Flex,
  Stack,
  Button,
  HStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { redishColor, greenishColor } from '../utils/theme';
import Logo from '../assets/AL-Blue-Logo.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DownloadIcon } from '@chakra-ui/icons';

export interface Params {
  amount_cents: string;
  created_at: string;
  currency: string;
  order: string;
  email: string;
  source_data: {
    type: string;
  };
  success: string;
}

const PaymentInvoice = ({ params }: { params: Params | undefined }) => {
  const downloadInvoice = (): void => {
    const input = document.getElementById('invoiceId');
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        // pdf.output('dataurlnewwindow');
        pdf.save(
          `AL-Invoice-${dayjs(params?.created_at).format('DD/MM/YYYY')}.pdf`
        );
      });
    }
  };
  return (
    <Box position="relative">
      <Box position="absolute" top="10px" left="10px">
        <Button
          leftIcon={<DownloadIcon />}
          variant="outline"
          size="sm"
          onClick={downloadInvoice}
        >
          Download Invoice
        </Button>
      </Box>
      <Box
        textAlign={'left'}
        padding="40px"
        rounded="lg"
        bg="gray.50"
        id="invoiceId"
      >
        <Box height="40px" overflow="hidden" mb="20px">
          <Image
            src={Logo}
            width="120px"
            position="relative"
            top="-40px"
            ml="auto"
          />
        </Box>

        <Text
          as="span"
          color={Boolean(params?.success) ? redishColor : 'red.500'}
        >
          Order {Boolean(params?.success) ? 'Successful' : 'Failed'}
        </Text>
        <Text as="span" ml="20px">
          {dayjs(params?.created_at).format('DD/MM/YYYY')}
        </Text>
        <Heading
          as="h2"
          color={Boolean(params?.success) ? greenishColor : 'red.500'}
        >
          {Boolean(params?.success)
            ? 'Thanks for the payment'
            : 'Payment Failed!'}
        </Heading>
        <Text mt="10px">
          The order{' '}
          {Boolean(params?.success) ? 'was' : <strong>WAS NOT</strong>}{' '}
          processed successfully. We've sent{' '}
          {Boolean(params?.success) ? 'a reciept' : 'an email'} with the order
          details to <strong>{params?.email}</strong>
        </Text>

        <Box mt="50px">
          <HStack gap="0 80px">
            <Box>
              <Text>Order no.</Text>
              <Box fontSize="lg" fontWeight="bold" mt="5px">
                {params?.order}
              </Box>
            </Box>
            <Box>
              <Text>Payment Method</Text>
              <Box fontSize="lg" fontWeight="bold" mt="5px">
                {params?.source_data?.type ?? 'Card'}
              </Box>
            </Box>
          </HStack>
          <Box as="hr" my="20px" />
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            fontSize="sm"
          >
            <Box>Subtotal</Box>
            <Text fontWeight="medium">
              {params?.currency} {Number(params?.amount_cents) / 100}
            </Text>
          </Flex>
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            mt="20px"
            fontSize="sm"
          >
            <Box>Transaction fees</Box>
            <Text fontWeight="medium">{params?.currency} 0</Text>
          </Flex>
          <Box as="hr" my="20px" />
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            mt="20px"
            fontSize="md"
          >
            <Box fontWeight="bold">Total</Box>
            <Text fontWeight="bold" color={greenishColor}>
              {params?.currency} {Number(params?.amount_cents) / 100}
            </Text>
          </Flex>
          <Stack
            gap="50px"
            flexDirection={['column', 'column', 'row', 'row']}
            mt="50px"
          >
            <Box>
              <Box fontSize="sm" fontWeight="bold" mb="5px">
                Shipped To
              </Box>
              <Box fontSize="sm">{params?.email}</Box>
            </Box>

            <Box>
              <Box fontSize="sm" fontWeight="bold" mb="5px">
                Order Status
              </Box>
              <Box fontSize="sm">
                Payment{Boolean(params?.success) ? ' Successful' : ' Failed!'}
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentInvoice;
