import React, { useMemo } from 'react';
import { returnImage, returnName } from '.';
import { Avatar, Box, Flex, HStack, Image } from '@chakra-ui/react';
import { MatchInterface } from '../../../utils/types';

interface OlympicTeam {
  [key: string]: {
    pts: number;
    team: {
      id: string;
      name: string;
      teamLogo: {
        url: string;
      } | null;
    };
  };
}

const OverAll = ({ matches }: { matches: MatchInterface[] }) => {
  const olympicsPoints = useMemo(() => {
    return matches?.reduce((acc: OlympicTeam, match: MatchInterface) => {
      const { homeTeam, awayTeam, stage } = match;

      const homeTeamName = homeTeam.teams?.[0]?.name;
      const awayTeamName = awayTeam.teams?.[0]?.name;
      const homeTeamPoints = acc[homeTeamName] || {
        pts: 0,
        team: homeTeam?.teams?.[0],
      };
      const awayTeamPoints = acc[awayTeamName] || {
        pts: 0,
        team: homeTeam?.teams?.[0],
      };
      const homeTeamPointsValue = homeTeamPoints.pts;
      const awayTeamPointsValue = awayTeamPoints.pts;

      if (stage.includes('Final')) {
        if (match.homeTeamScore > match.awayTeamScore) {
          homeTeamPoints['pts'] =
            homeTeamPointsValue + match.homeTeamExtraPoints + 20;
          awayTeamPoints['pts'] =
            awayTeamPointsValue + match.awayTeamExtraPoints + 12;
        } else {
          homeTeamPoints['pts'] =
            homeTeamPointsValue + match.homeTeamExtraPoints + 12;
          awayTeamPoints['pts'] =
            awayTeamPointsValue + match.awayTeamExtraPoints + 20;
        }
      } else if (stage.includes('Third-place play-off')) {
        if (match.homeTeamScore > match.awayTeamScore) {
          homeTeamPoints['pts'] =
            homeTeamPointsValue + match.homeTeamExtraPoints + 7;
          awayTeamPoints['pts'] =
            awayTeamPointsValue + match.awayTeamExtraPoints + 1;
        } else {
          homeTeamPoints['pts'] =
            homeTeamPointsValue + match.homeTeamExtraPoints + 1;
          awayTeamPoints['pts'] =
            awayTeamPointsValue + match.awayTeamExtraPoints + 7;
        }
      }

      acc[homeTeamName] = { ...homeTeamPoints, team: homeTeam?.teams?.[0] };
      acc[awayTeamName] = { ...awayTeamPoints, team: awayTeam?.teams?.[0] };
      return acc;
    }, {});
  }, [matches]);

  const returnSortedPoints = (data: OlympicTeam) => {
    return Object.keys(data)
      .sort((a, b) => data[b].pts - data[a].pts)
      .reduce((acc: OlympicTeam, key: string) => {
        acc[key] = data[key];
        return acc;
      }, {});
  };

  return (
    <Box>
      <Box p="5px 5px 5px 10px" fontSize="14px" mb="10px" background="gray.100">
        <Flex justifyContent="space-between">
          <Box fontWeight={'bold'}>{'Team'}</Box>

          <Box textAlign={'center'} fontWeight={'bold'}>
            Overall PTS
          </Box>
        </Flex>
      </Box>
      {Object.keys(returnSortedPoints(olympicsPoints)).map((key) => {
        const team = olympicsPoints[key].team;
        const pts = olympicsPoints[key].pts;
        return (
          <HStack
            alignItems="center"
            justifyContent="space-between"
            cursor="pointer"
            _hover={{ bg: 'teal.50', color: 'inherit' }}
          >
            <HStack alignItems="center">
              <Flex
                h={'58px'}
                w={'58px'}
                justifyContent="center"
                alignItems="center"
                fontSize="12px"
                textAlign="center"
              >
                <Box>
                  {returnImage(team?.teamLogo?.url) ? (
                    <Image
                      src={returnImage(team?.teamLogo?.url)}
                      boxSize={'50px'}
                      objectFit="cover"
                      m="0 auto"
                      cursor="pointer"
                    />
                  ) : (
                    <Avatar
                      size="sm"
                      name={`${returnName(team.name)} `}
                      src={''}
                    />
                  )}
                </Box>
              </Flex>

              <Flex flexDirection="column" h="58px" justifyContent="center">
                <Flex
                  noOfLines={[1, 1, 2, 2]}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                  alignItems="center"
                  textTransform={'capitalize'}
                  maxW={['83px', '83px', 'auto', 'auto']}
                >
                  {returnName(team.name)}
                </Flex>
              </Flex>
            </HStack>
            <Box px="5px">
              <Box w="30px" textAlign={'center'} fontWeight={'bold'}>
                {pts}
              </Box>
            </Box>
          </HStack>
        );
      })}
    </Box>
  );
};

export default OverAll;
