export const notes = [
  {
    name: 'note1',
    content: "Payments are non-refundable and can't be traded.",
  },
  {
    name: 'note2',
    content: 'Payments are including taxes and online payment fees.',
  },
];

export const faqs = [
  {
    question: 'What is the Amateur League?',
    answer:
      'The Amateur League is a football league that is open to all players. It is a platform for players to showcase their skills and talent. The league is open to all players.',
  },
  {
    question: 'What is the Amateur League?',
    answer:
      'The Amateur League is a football league that is open to all players. It is a platform for players to showcase their skills and talent. The league is open to all players.',
  },
  {
    question: 'What is the Amateur League?',
    answer:
      'The Amateur League is a football league that is open to all players. It is a platform for players to showcase their skills and talent. The league is open to all players.',
  },
  {
    question: 'What is the Amateur League?',
    answer:
      'The Amateur League is a football league that is open to all players. It is a platform for players to showcase their skills and talent. The league is open to all players.',
  },
  {
    question: 'What is the Amateur League?',
    answer:
      'The Amateur League is a football league that is open to all players. It is a platform for players to showcase their skills and talent. The league is open to all players.',
  },
];
