import { useQuery } from '@apollo/client';
import {
  Box,
  Stack,
  Text,
  useColorModeValue,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeadingTitle from '../components/HeadingTitle';
import PlayerPhoto from '../components/PlayerData';
import { useStoreActions, useStoreState } from '../store';
import { GET_PARTICIPATIONS_BY_SEASON_ID, GET_SEASONS } from '../utils/queries';
import { redishColor } from '../utils/theme';
import Footer from './Footer';
import Loader from './Loader';
import Sidebar from './Navigation';

const Teams = () => {
  const { data: sData, loading: sLoading } = useQuery(GET_SEASONS);
  const currentSeason = sData?.seasons[sData.seasons.length - 1];
  const currentSeasonId = currentSeason?.id;
  const { data: pData, loading: pLoading } = useQuery(
    GET_PARTICIPATIONS_BY_SEASON_ID,
    {
      variables: { id: currentSeasonId },
    }
  );
  const participations = pData?.participations;

  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);
  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const isLoading = sLoading || pLoading;

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt={{ base: '125px', md: '200px' }}
        display="flex"
        justifyContent={'space-between'}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Box textAlign={'center'} mb="50px">
            <HeadingTitle>{`${currentSeason?.name} Standings`}</HeadingTitle>
          </Box>
        )}

        {participations ? (
          <Box padding="0 15px" mt="20px">
            <Box
              border="1px"
              borderColor={borderColor}
              maxW="500px"
              margin="0 auto"
              rounded="10px"
            >
              <TableContainer>
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th>Team Name</Th>
                      <Th>Team Logo</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {participations
                      .filter(
                        (x: any) =>
                          x.isVerified &&
                          !x.teams[0].name.includes('Transfer Market')
                      )
                      .map((participation: any) => {
                        return (
                          <Tr key={participation.id}>
                            <Td textTransform={'capitalize'} fontWeight="bold">
                              <Link
                                to={`/team/${participation?.id}`}
                                state={{ public: true }}
                              >
                                <Text
                                  as="span"
                                  color={redishColor}
                                  _hover={{ textDecor: 'underline' }}
                                >
                                  {participation.teams[0].name}{' '}
                                </Text>
                              </Link>
                            </Td>
                            <Td>
                              <PlayerPhoto
                                size="60px"
                                img={
                                  participation.teams[0].teamLogo &&
                                  participation.teams[0].teamLogo?.url
                                    ? participation.teams[0].teamLogo.url
                                    : participation.teams[0].mascotLogo
                                }
                              />
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        ) : null}

        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default Teams;
