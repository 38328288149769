import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import Shared from './containers/Shared';
import { useGraphClient } from './hooks/useGraphql';
import { theme } from './utils/theme';

export const App = () => {
  const client = useGraphClient();

  return (
    <ChakraProvider theme={theme}>
      <ApolloProvider client={client}>
        <Shared />
      </ApolloProvider>
    </ChakraProvider>
  );
};
