import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Heading,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  Input,
  Checkbox,
  HStack,
  Select,
  useToast,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UPDATE_MATCH } from '../../utils/mutations';
import { FETCH_MATCH_BY_ID } from '../../utils/queries';
import { formations } from '../../utils/Select';
import { MatchInterface } from '../../utils/types';
import Loader from '../Loader';
import ActionDetails from './ActionsDetails';
import Layout from './Layout';
import Lineup from './Lineup';

interface MatchInfo {
  matchDate: string;
  firstHalfStartTime: string;
  secondHalfStartTime: string;
  homeTeamFormation: string;
  awayTeamFormation: string;
  referee: string;
  stadium: string;
  isMatchLive: boolean;
  isMatchEnded: boolean;
  homeTeamFairPlayPoints: boolean;
  awayTeamFairPlayPoints: boolean;
}
const MatchData = () => {
  const { onClose, isOpen, onOpen } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updateMatch] = useMutation(UPDATE_MATCH);
  const [matchInfo, setMatchInfo] = useState<MatchInfo | null>(null);
  const toast = useToast();
  const { id } = useParams();
  const {
    data: matchData,
    loading,
    refetch,
  } = useQuery(FETCH_MATCH_BY_ID, {
    variables: { id },
    pollInterval: 1000,
  });

  const match: MatchInterface | null = useMemo(() => {
    return matchData?.match;
  }, [matchData]);
  useEffect(() => {
    if (match && !matchInfo) {
      setMatchInfo({
        matchDate: match.matchDate,
        firstHalfStartTime: match.firstHalfStartTime,
        secondHalfStartTime: match.secondHalfStartTime,
        homeTeamFormation: match.homeTeamFormation ?? '',
        awayTeamFormation: match.awayTeamFormation ?? '',
        referee: match.referee,
        stadium: match.stadium,
        isMatchLive: match.isMatchLive,
        isMatchEnded: match.isMatchEnded,
        homeTeamFairPlayPoints: match.homeTeamFairPlayPoints,
        awayTeamFairPlayPoints: match.awayTeamFairPlayPoints,
      });
    }
  }, [match, matchInfo]);

  const handleSaveMatchInfo = async () => {
    try {
      setIsSubmitting(true);
      await updateMatch({
        variables: { id: match?.id, data: { ...matchInfo } },
      });
      toast({
        title: `Match data saved Successfully!`,
        status: 'success',
        position: 'top-right',
      });
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        position: 'top-right',
      });
    } finally {
      setIsSubmitting(false);
      onClose();
      refetch();
    }
  };
  const dateToDateTimeLocal = (date: string) => {
    return dayjs(date).format('YYYY-MM-DDTHH:mm');
  };
  const dateToISOString = (date: string) => {
    return dayjs(date).toISOString();
  };

  return (
    <div>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add/Edit Match Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {match && matchInfo ? (
              <>
                {' '}
                <HStack justifyContent={'space-between'} mb="5">
                  <Checkbox
                    colorScheme="blue"
                    value={match?.isMatchLive ? '1' : '0'}
                    onChange={(e) =>
                      setMatchInfo({
                        ...matchInfo,
                        isMatchLive: Boolean(e.target.value),
                      })
                    }
                  >
                    Is Match Live
                  </Checkbox>
                  <Checkbox
                    colorScheme="blue"
                    value={match?.isMatchEnded ? '1' : '0'}
                    onChange={(e) =>
                      setMatchInfo({
                        ...matchInfo,
                        isMatchEnded: Boolean(e.target.value),
                      })
                    }
                  >
                    Is Match Ended
                  </Checkbox>
                </HStack>
                <Box mb="5">
                  <Checkbox
                    colorScheme="blue"
                    value={match?.homeTeamFairPlayPoints ? '1' : '0'}
                    onChange={(e) =>
                      setMatchInfo({
                        ...matchInfo,
                        homeTeamFairPlayPoints: Boolean(e.target.value),
                      })
                    }
                  >
                    <b>{match?.homeTeam?.teams[0]?.name}</b> Fair Play Points
                  </Checkbox>
                </Box>
                <Box mb="5">
                  <Checkbox
                    colorScheme="blue"
                    value={match?.awayTeamFairPlayPoints ? '1' : '0'}
                    onChange={(e) =>
                      setMatchInfo({
                        ...matchInfo,
                        awayTeamFairPlayPoints: Boolean(e.target.value),
                      })
                    }
                  >
                    <b>{match?.awayTeam?.teams[0]?.name}</b> Fair Play Points
                  </Checkbox>
                </Box>
                <InputGroup mb="3">
                  <InputLeftAddon children="Match Date" />
                  <Input
                    type="datetime-local"
                    value={dateToDateTimeLocal(matchInfo.matchDate)}
                    onChange={(e) =>
                      setMatchInfo({
                        ...matchInfo,
                        matchDate: dateToISOString(e.target.value),
                      })
                    }
                  />
                </InputGroup>
                <InputGroup mb="3">
                  <InputLeftAddon children="FH Start Time" />
                  <Input
                    type="datetime-local"
                    value={dateToDateTimeLocal(matchInfo.firstHalfStartTime)}
                    onChange={(e) =>
                      setMatchInfo({
                        ...matchInfo,
                        firstHalfStartTime: dateToISOString(e.target.value),
                      })
                    }
                  />
                </InputGroup>
                <InputGroup mb="3">
                  <InputLeftAddon children="SH Start Time" />
                  <Input
                    type="datetime-local"
                    value={dateToDateTimeLocal(matchInfo.secondHalfStartTime)}
                    onChange={(e) =>
                      setMatchInfo({
                        ...matchInfo,
                        secondHalfStartTime: dateToISOString(e.target.value),
                      })
                    }
                  />
                </InputGroup>
                <InputGroup mb="3">
                  <InputLeftAddon
                    children={`${match?.homeTeam?.teams[0]?.name} Formation`}
                  />
                  <Select
                    onChange={(e) =>
                      setMatchInfo({
                        ...matchInfo,
                        homeTeamFormation: e.target.value,
                      })
                    }
                    value={matchInfo.homeTeamFormation}
                  >
                    <option>Select Formation</option>
                    {formations.map((formation) => (
                      <option key={'home' + formation} value={formation}>
                        {formation}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
                <InputGroup mb="3">
                  <InputLeftAddon
                    children={`${match?.awayTeam?.teams[0]?.name} Formation`}
                  />
                  <Select
                    onChange={(e) =>
                      setMatchInfo({
                        ...matchInfo,
                        awayTeamFormation: e.target.value,
                      })
                    }
                    value={matchInfo.awayTeamFormation}
                  >
                    <option>Select Formation</option>
                    {formations.map((formation) => (
                      <option key={'away' + formation} value={formation}>
                        {formation}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
                <InputGroup mt="3">
                  <InputLeftAddon children="Referee" />
                  <Input
                    type="text"
                    placeholder="Referee name"
                    value={matchInfo.referee}
                    onChange={(e) =>
                      setMatchInfo({ ...matchInfo, referee: e.target.value })
                    }
                  />
                </InputGroup>
                <InputGroup mt="3">
                  <InputLeftAddon children="Stadium" />
                  <Input
                    type="text"
                    placeholder="Stadium name"
                    value={matchInfo.stadium}
                    onChange={(e) =>
                      setMatchInfo({ ...matchInfo, stadium: e.target.value })
                    }
                  />
                </InputGroup>
              </>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
            <Button
              ml="10px"
              colorScheme="blue"
              disabled={false}
              isLoading={isSubmitting}
              onClick={handleSaveMatchInfo}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Layout />
      <Container mt="10" maxW="1024">
        {!match && loading ? (
          <Loader />
        ) : (
          <Box>
            <Box>
              <Box>
                <Center>
                  <Heading as="h1" size="lg" mb="2">
                    {match?.homeTeam?.teams?.[0]?.name}{' '}
                    <Text as="span" fontSize="lg" color="green">
                      vs
                    </Text>{' '}
                    {match?.awayTeam.teams?.[0]?.name}
                  </Heading>
                </Center>
              </Box>
              <Box mb="10">
                <Center>
                  <Button colorScheme="blue" onClick={onOpen}>
                    Match Info
                  </Button>
                </Center>
              </Box>
            </Box>
            <Tabs isFitted colorScheme="green">
              <TabList>
                <Tab>Lineup</Tab>
                <Tab>Actions</Tab>
                <Tab>Penalties</Tab>
                <Tab>Statistics</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  {match ? <Lineup match={match} refetch={refetch} /> : null}
                </TabPanel>
                <TabPanel>
                  {match ? (
                    <ActionDetails match={match} refetch={refetch} />
                  ) : null}
                </TabPanel>
                <TabPanel>
                  <p>three!</p>
                </TabPanel>
                <TabPanel>
                  <p>Statistics</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}
      </Container>
    </div>
  );
};

export default MatchData;
