import { useMutation } from '@apollo/client';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { timeout } from '../utils/helper';
import { FORGOT_PASSWORD } from '../utils/mutations';
import { greenishColor } from '../utils/theme';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);
  const toast = useToast();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(false);
    try {
      const res = await forgotPassword({
        variables: { email: email?.toLowerCase() },
      });
      if (
        res.data &&
        res.data.forgetPlayerPassword &&
        res.data.forgetPlayerPassword.id
      ) {
        toast({
          title: `An email with a new password has been sent to ${email} ✌️`,
          status: 'success',
          isClosable: true,
        });
        await timeout(2000);
        navigate(`/login`);
        setIsSubmitting(false);
      } else {
        setError(true);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Forgot your password?</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            Do not worry, we've got you{' '}
            <Text as="span" color={greenishColor}>
              covered
            </Text>{' '}
            ✌️
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email" isInvalid={error}>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError(false);
                }}
              />

              {error ? (
                <FormErrorMessage>Wrong Email address</FormErrorMessage>
              ) : null}
            </FormControl>

            <Stack spacing={10}>
              <Button
                mt="10px"
                bg={greenishColor}
                color={'white'}
                rounded="full"
                _hover={{
                  bg: greenishColor,
                }}
                loadingText={'Submitting...'}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={handleSubmit}
              >
                Send me new password
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
