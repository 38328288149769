import { Heading, Button, Text, Box } from '@chakra-ui/react';
import { greenishColor } from '../utils/theme';
import { useStoreActions } from '../store';

const AlreadyRegistered = () => {
  const { popupsModel: popupsActions } = useStoreActions((state) => state);
  const handlePopup = () => {
    popupsActions.setShowSeasonPopup(true);
  };
  return (
    <Box
      textAlign="center"
      py={10}
      px={6}
      maxW="800px"
      m="0 auto"
      border={'1px solid #dadada'}
      borderRadius={'15px'}
    >
      <Text fontSize="5xl">✌️</Text>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Welcome! this is a process for already registered players with AL
      </Heading>
      <Text color={'gray.500'}>
        Now you can choose which league to join with the same team or new team
        or even to other league and if you are a player you have the luxury to
        create a new team or join other team if you are not already hooked up
        with a team in the same season and same league{' '}
      </Text>

      <Box textAlign="center" mt="20px">
        <Button
          color={'white'}
          background={greenishColor}
          _hover={{ background: greenishColor, color: 'white' }}
          onClick={handlePopup}
        >
          Got it, Proceed!
        </Button>
      </Box>
    </Box>
  );
};

export default AlreadyRegistered;
