import { Box, Center, HStack, Spinner } from '@chakra-ui/react';
const Loader = () => {
  return (
    <Center>
      <HStack>
        <Spinner size="sm" /> <Box fontSize="lg">Loading...</Box>
      </HStack>
    </Center>
  );
};

export default Loader;
