import {
  Avatar,
  AvatarBadge,
  Box,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import { returnImage, returnName } from '.';
import UserImg from '../../../assets/user.png';
import CrownImg from '../../../assets/crown.png';
import { useMemo } from 'react';
import { MatchInterface } from '../../../utils/types';

const Ranking = ({ matches }: { matches: MatchInterface[] }) => {
  const finalMatch = useMemo(() => {
    return matches?.find((m) => m.stage === 'Final');
  }, [matches]);
  const thirdPlace = useMemo(() => {
    return matches?.find((m) => m.stage === 'Third-place play-off');
  }, [matches]);

  return (
    <Box>
      <HStack gap={0} justifyContent={'center'} alignItems={'flex-end'}>
        <Box textAlign={'center'}>
          <Avatar
            size="xl"
            border="2px solid skyblue"
            src={
              finalMatch &&
              finalMatch?.homeTeamScore > finalMatch?.awayTeamScore
                ? returnImage(finalMatch?.awayTeam?.teams?.[0]?.teamLogo?.url)
                : finalMatch &&
                  finalMatch?.homeTeamScore < finalMatch?.awayTeamScore
                ? returnImage(finalMatch?.homeTeam?.teams?.[0]?.teamLogo?.url)
                : UserImg
            }
            bg="skyblue"
          >
            <AvatarBadge
              borderColor="skyblue"
              bg="skyblue"
              boxSize="1.7em"
              fontSize="lg"
              left="0"
              right="0"
              m="auto"
              transform="translate(0%, 35%)"
            >
              2
            </AvatarBadge>
          </Avatar>

          <Box
            mt="-25px"
            borderRadius="30px 30px 0 0"
            bg="gray.700"
            p="60px 10px 30px 10px"
            w={['115px', '140px']}
          >
            <Text fontSize={['md', 'lg']} fontWeight="bold" color="white">
              {finalMatch &&
              finalMatch?.homeTeamScore > finalMatch?.awayTeamScore
                ? returnName(finalMatch?.awayTeam.name)
                : finalMatch &&
                  finalMatch?.homeTeamScore < finalMatch?.awayTeamScore
                ? returnName(finalMatch?.homeTeam.name)
                : 'TBD'}
            </Text>
            <Text fontSize={['md', 'lg']} fontWeight="bold" color="skyblue">
              12 Points
            </Text>
          </Box>
        </Box>

        <Box textAlign={'center'}>
          <Image src={CrownImg} w="70px" m="0 auto" />

          <Avatar
            size="xl"
            border="2px solid gold"
            src={
              finalMatch &&
              finalMatch?.homeTeamScore > finalMatch?.awayTeamScore
                ? returnImage(finalMatch?.homeTeam?.teams?.[0]?.teamLogo?.url)
                : finalMatch &&
                  finalMatch?.homeTeamScore < finalMatch?.awayTeamScore
                ? returnImage(finalMatch?.awayTeam?.teams?.[0]?.teamLogo?.url)
                : UserImg
            }
            bg="gold"
          >
            <AvatarBadge
              borderColor="gold"
              bg="gold"
              boxSize="1.7em"
              fontSize="lg"
              left="0"
              right="0"
              m="auto"
              transform="translate(0%, 35%)"
            >
              1
            </AvatarBadge>
          </Avatar>

          <Box
            mt="-25px"
            borderRadius="30px 30px 0 0"
            bg="gray.600"
            p="70px 10px 50px 10px"
            w={['115px', '140px']}
          >
            <Text fontSize={['md', 'lg']} fontWeight="bold" color="white">
              {finalMatch &&
              finalMatch?.homeTeamScore > finalMatch?.awayTeamScore
                ? returnName(finalMatch?.homeTeam.name)
                : finalMatch &&
                  finalMatch?.homeTeamScore < finalMatch?.awayTeamScore
                ? returnName(finalMatch?.awayTeam.name)
                : 'TBD'}
            </Text>
            <Text fontSize={['md', 'lg']} fontWeight="bold" color="gold">
              20 Points
            </Text>
          </Box>
        </Box>

        <Box textAlign={'center'}>
          <Avatar
            size="xl"
            border="2px solid lightgreen"
            src={
              thirdPlace &&
              thirdPlace?.homeTeamScore > thirdPlace?.awayTeamScore
                ? returnImage(thirdPlace?.homeTeam?.teams?.[0]?.teamLogo?.url)
                : thirdPlace &&
                  thirdPlace?.homeTeamScore < thirdPlace?.awayTeamScore
                ? returnImage(thirdPlace?.awayTeam?.teams?.[0]?.teamLogo?.url)
                : UserImg
            }
            bg="lightgreen"
          >
            <AvatarBadge
              borderColor="lightgreen"
              bg="lightgreen"
              boxSize="1.7em"
              fontSize="lg"
              left="0"
              right="0"
              m="auto"
              transform="translate(0%, 35%)"
            >
              3
            </AvatarBadge>
          </Avatar>

          <Box
            mt="-25px"
            borderRadius="30px 30px 0 0"
            bg="gray.700"
            p="40px 10px 30px 10px"
            w={['115px', '140px']}
          >
            <Text fontSize={['md', 'lg']} fontWeight="bold" color="white">
              {thirdPlace &&
              thirdPlace?.homeTeamScore > thirdPlace?.awayTeamScore
                ? returnName(thirdPlace?.homeTeam.name)
                : thirdPlace &&
                  thirdPlace?.homeTeamScore < thirdPlace?.awayTeamScore
                ? returnName(thirdPlace?.awayTeam.name)
                : 'TBD'}
            </Text>
            <Text fontSize={['md', 'lg']} fontWeight="bold" color="lightgreen">
              7 Points
            </Text>
          </Box>
        </Box>
      </HStack>
    </Box>
  );
};

export default Ranking;
