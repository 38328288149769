import {
  Avatar,
  AvatarBadge,
  Text,
  Box,
  Flex,
  Heading,
  Image,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import { MatchInterface, PlayerPosition } from '../../utils/types';
import Playground from '../../assets/playground.jpg';
import { SERVER_URL } from '../../utils/constants';
import { Link } from 'react-router-dom';

interface Props {
  match: MatchInterface;
}
const LineupPosition = {
  GK: 1,
  LB: 2,
  SW: 3,
  CB: 3,
  RB: 5,
  RWB: 5,
  LWB: 2,
  CDM: 6,
  CM: 6,
  RM: 7,
  LM: 7,
  LW: 8,
  CAM: 9,
  RW: 11,
  CF: 10,
  ST: 10,
};
const Lineup = ({ match }: Props) => {
  const [beforeSmall] = useMediaQuery(['(max-width: 767px)']);
  const homeLineup = match?.lineUp?.filter(
    (lineup) => lineup.isHomeTeam && !lineup.isSub
  );

  const awayLineup = match?.lineUp?.filter(
    (lineup) => !lineup.isHomeTeam && !lineup.isSub
  );

  const homeSub = match?.lineUp?.filter(
    (lineup) => lineup.isHomeTeam && lineup.isSub
  );
  const awaySub = match?.lineUp?.filter(
    (lineup) => !lineup.isHomeTeam && lineup.isSub
  );

  const returnImage = (team: any) => {
    return team.teamLogo && team.teamLogo?.url
      ? team.teamLogo.url
      : team.mascotLogo;
  };
  const formationBuilder = (
    formation: string,
    lineup: Array<any>,
    isHome: boolean
  ) => {
    if (!formation) {
      formation = '4-2-3-1';
    }
    const formationToArray = formation
      .split('-')
      .map((number: string) => parseInt(number));
    formationToArray.unshift(1);
    // const sortedLineup = lineup.sort(
    //   (a, b) => parseInt(a.lineup_position) - parseInt(b.lineup_position)
    // );
    // const GK = lineup.filter((x) => x.position === 'GK');
    // const sortedLineup = [...GK, ...lineup.filter((x) => x.position !== 'GK')];
    const mapLineupWithPositionNumber = lineup.map((x) => ({
      ...x,
      positionNumber: LineupPosition[x.position as PlayerPosition],
    }));
    const sortedLineup = mapLineupWithPositionNumber.sort(
      (a, b) => a.positionNumber - b.positionNumber
    );

    let offset = 0;
    return (
      <Box
        position={'absolute'}
        top={isHome ? '3.5%' : 'auto'}
        bottom={!isHome ? '3.5%' : 'auto'}
        left={'5.5%'}
        right={'5.5%'}
        height="calc(50% - 4%)"
        display="flex"
        flexDirection={isHome ? 'column' : 'column-reverse'}
        justifyContent="space-between"
      >
        {formationToArray.map((length: number, i: number) => {
          const arr = sortedLineup.slice(offset, offset + length);
          offset = offset + length;
          const players = isHome ? arr : arr.reverse();
          return (
            <Flex key={i} justifyContent={'center'}>
              {players.map((n: any, i: number) => {
                const mySubString = n.name.substring(
                  n.name.indexOf('(') + 1,
                  n.name.lastIndexOf(')')
                );
                return (
                  <Box
                    key={Math.random() + i}
                    textAlign="center"
                    maxW={'50%'}
                    width={'100%'}
                  >
                    <Link to={`/player/${n.playerId}`}>
                      <Avatar
                        key={i}
                        size={beforeSmall ? 'sm' : 'md'}
                        src={SERVER_URL + n.playerPhoto}
                      >
                        <AvatarBadge
                          boxSize={beforeSmall ? '1.3em' : '1.7em'}
                          bg={isHome ? 'blue.400' : 'teal.400'}
                          border={'0'}
                          color={'white'}
                          fontSize={beforeSmall ? 'xs' : 'sm'}
                          fontWeight={'bold'}
                        >
                          {mySubString}
                        </AvatarBadge>
                      </Avatar>
                      <Box fontSize={['10px', 'xs']} margin={'5px auto 0 auto'}>
                        {n.name}
                      </Box>
                    </Link>
                  </Box>
                );
              })}
            </Flex>
          );
        })}
      </Box>
    );
  };

  const returnSubs = (lineup: Array<any>, teamName: string, isHome = true) => {
    return (
      <Box>
        <Heading as="h4" fontSize="16px" textAlign="center" my="25px">
          {'Substitutions'} {teamName}
          <Box
            width="30px"
            h="3px"
            rounded="lg"
            bg={'teal.400'}
            margin="10px auto"
          />
        </Heading>
        {lineup?.length > 0 ? (
          lineup.map((sub: any) => {
            const mySubString = sub.name.substring(
              sub.name.indexOf('(') + 1,
              sub.name.lastIndexOf(')')
            );

            return (
              <Link to={`/player/${sub.playerId}`}>
                <Flex
                  alignItems="center"
                  mt="20px"
                  key={sub.name + mySubString}
                >
                  <Avatar size={'sm'} src={SERVER_URL + sub.playerPhoto}>
                    <AvatarBadge
                      boxSize={'1.3em'}
                      bg={isHome ? 'blue.400' : 'teal.400'}
                      border={'0'}
                      color={'white'}
                      fontSize={'xs'}
                      fontWeight={'bold'}
                    >
                      {mySubString}
                    </AvatarBadge>
                  </Avatar>
                  <Text margin="0 15px" fontSize="sm">
                    {sub.name}
                  </Text>
                </Flex>
              </Link>
            );
          })
        ) : (
          <Box textAlign="center">{'Not Available'}</Box>
        )}
      </Box>
    );
  };
  return (
    <Box>
      <Heading as="h4" fontSize="16px" textAlign="center" my="25px">
        {'Lineup'}
        <Box
          width="30px"
          h="3px"
          rounded="lg"
          bg={'teal.400'}
          margin="10px auto"
        />
      </Heading>
      {match?.lineUp?.length > 0 ? (
        <Box position={'relative'} dir={'rtl'}>
          <Box
            position="absolute"
            top="2px"
            left="2px"
            bg={'#fff'}
            padding={'2px 5px'}
            fontSize={beforeSmall ? '10px' : 'sm'}
            zIndex="2"
            color={'black'}
            fontWeight="bold"
            display="flex"
            alignItems="center"
          >
            <Image
              boxSize={'20px'}
              src={SERVER_URL + returnImage(match?.homeTeam.teams[0])}
              mr={'3px'}
            />{' '}
            {match?.homeTeam.teams[0].name}
          </Box>

          <Box
            position="absolute"
            bottom="2px"
            right="2px"
            bg={'#fff'}
            padding={'2px 5px'}
            fontSize={beforeSmall ? '10px' : 'sm'}
            zIndex="2"
            color={'black'}
            fontWeight="bold"
            display="flex"
            alignItems="center"
          >
            {match?.awayTeam.teams[0].name}
            <Image
              boxSize={'20px'}
              src={SERVER_URL + returnImage(match?.awayTeam.teams[0])}
              ml={'3px'}
            />
          </Box>
          <Image
            opacity={0.5}
            src={Playground}
            width={'100%'}
            height={'100%'}
            objectFit="fill"
          />
          {homeLineup?.length > 0
            ? formationBuilder(match?.homeTeamFormation, homeLineup, true)
            : null}
          {awayLineup?.length > 0
            ? formationBuilder(match?.awayTeamFormation, awayLineup, false)
            : null}
        </Box>
      ) : (
        <Box textAlign="center">{'Not Available'}</Box>
      )}

      {returnSubs(homeSub, match?.homeTeam?.teams[0].name, true)}
      <Divider m="40px 0 30px 0" />
      {returnSubs(awaySub, match?.awayTeam?.teams[0].name, false)}
    </Box>
  );
};

export default Lineup;
