import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';

import { useStoreActions, useStoreState } from '../../../store';
import { useEffect, useRef, useState } from 'react';
import { greenishColor } from '../../../utils/theme';
import { useMutation } from '@apollo/client';
import {
  CREATE_PARTICIPATION,
  CREATE_TEAM_MINIMAL,
  CREATE_PLAYER_MINIMAL,
  UPDATE_PLAYER,
} from '../../../utils/mutations';
import useLeaguesState from '../../../hooks/useLeaguesState';
import {
  ability,
  kitSize,
  position,
  preferredFoot,
  skills,
  sportTypes,
} from '../../../utils/Select';
import { timeout } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { useRegistration } from '../../../hooks/useRegistration';

interface State {
  firstName: string;
  lastName: string;
  age: number;
  playerID: string;
  jerseyNumber: string;
  kitSize: string;
  work: string;
  teamName: string;
  phoneNumber: string;
  email: string;
  knowsUsFrom: string;
  sportType: string;
}
const inputs = [
  { label: 'Team Name', type: 'text', stateName: 'teamName', required: true },
  { label: 'First Name', type: 'text', stateName: 'firstName', required: true },
  { label: 'Last Name', type: 'text', stateName: 'lastName', required: true },
  { label: 'Email Address', type: 'email', stateName: 'email', required: true },
  {
    label: 'Phone Number',
    type: 'text',
    stateName: 'phoneNumber',
    required: true,
  },
  { label: 'Age', type: 'number', stateName: 'age', required: true },
  { label: 'ID Number', type: 'text', stateName: 'playerID', required: true },
  { label: 'Work', type: 'text', stateName: 'work', required: false },
];
const jNumbers = Array.from(Array(99).keys()).map((x) => x + 1);

const RegistrationComponent = ({
  compName,
  teamId: incomingTeamId,
  participationId: incomingParticipationId,
  from,
  unwantedFields,
  onComplete,
  btnText = 'Register',
}: {
  compName: string | undefined;
  teamId?: string;
  participationId?: string;
  unwantedFields?: String[];
  type?: string;
  from: string | null;
  btnText?: string;
  onComplete?: () => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const { currentSeason, currentCompetition, isTeamFull } =
    useLeaguesState(compName);
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { checkExistanceCallback } = useRegistration();
  const { userModel: userActions } = useStoreActions((state) => state);
  const [createPlayer] = useMutation(CREATE_PLAYER_MINIMAL);
  const [createTeam] = useMutation(CREATE_TEAM_MINIMAL);
  const [createParticipation] = useMutation(CREATE_PARTICIPATION);
  const [updatePlayer] = useMutation(UPDATE_PLAYER);
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    firstName: '',
    lastName: '',
    age: 0,
    playerID: '',
    jerseyNumber: '',
    kitSize: '',
    work: '',
    teamName: '',
    phoneNumber: '',
    email: '',
    knowsUsFrom: '',
    sportType: 'Handball',
  });
  const [photo, setPhoto] = useState<File | null>(null);

  const photoRef = useRef(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  const handleRegister = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isTeamFull) {
      throw new Error('Max players count reached for this team');
    }

    if (formRef?.current) {
      const isValid = formRef.current.checkValidity();
      formRef.current.reportValidity();
      if (!isValid) {
        return;
      }
    }
    if (!state) return;
    try {
      setIsSubmitting(true);
      const {
        firstName,
        lastName,
        age,
        playerID,
        jerseyNumber,
        kitSize,
        work,
        teamName,
        phoneNumber,
        email,
      } = state;
      const playerData = {
        firstName,
        lastName,
        age: Number(age),
        playerID,
        jerseyNumber: jerseyNumber ? Number(jerseyNumber) : 6,
        kitSize: kitSize ? kitSize : 'Medium',
        work,
        phoneNumber,
        email,
        hometown: 'New Cairo',
        position: position[0],
        secondPosition: position[0],
        ability: ability[0],
        skill: skills[0],
        preferredFoot: preferredFoot[0],
        nationalIdImage: {
          upload: photo as string | Blob,
        },
        photo: {
          upload: photo as string | Blob,
        },
        isCaptain: incomingTeamId ? false : true,
        knowsUsFrom:
          from && from.toLowerCase() === 'whatsapp'
            ? 'WhatsApp'
            : 'Social Media',
      };

      await checkExistanceCallback({ phoneNumber, email, playerID });
      if (!currentSeason || !currentCompetition)
        throw new Error('Season or Competition not found');
      const playerRes = await createPlayer({ variables: { data: playerData } });
      const playerId = playerRes?.data?.createPlayer?.id;
      if (!playerId) throw new Error('Player ID not found');
      let teamId;

      if (incomingTeamId) {
        teamId = incomingTeamId;
      } else {
        if (teamName.toLowerCase().includes('transfer')) {
          throw new Error('Team name cannot contain the word "transfer"');
        }
        await checkExistanceCallback({ teamName });
        const teamRes = await createTeam({
          variables: { data: { name: teamName } },
        });
        teamId = teamRes?.data?.createTeam?.id;
      }

      if (!teamId) throw new Error('Team ID not found');

      let partId;

      if (incomingParticipationId) {
        partId = incomingParticipationId;
      } else {
        const participationData = {
          name: `${currentSeason?.name} ${teamName} ${compName}`,
          seasons: { connect: [{ id: currentSeason?.id }] },
          teams: { connect: [{ id: teamId }] },
          competition: { connect: { id: currentCompetition?.id } },
          type: state.sportType,
          teamAdmin: {
            connect: { id: playerId },
          },
          isVerified: false,
        };
        const pRes = await createParticipation({
          variables: { data: participationData },
        });
        partId = pRes?.data.createParticipation?.id;
      }

      if (!partId) throw new Error('Participation ID not found');

      const updatedPlayerData = {
        participation: {
          connect: [{ id: partId }],
        },
      };
      await updatePlayer({
        variables: {
          id: playerId,
          data: updatedPlayerData,
        },
      });

      if (onComplete) {
        toast({
          title: 'Registration complete!',
          status: 'success',
          isClosable: true,
        });
        await timeout(1000);
        onComplete();
      } else {
        toast({
          title:
            'Registration complete!, an email with your login password has been sent to your email',
          status: 'success',
          isClosable: true,
        });
        await timeout(1000);
        navigate(`/team/${partId}`);
      }
    } catch (error) {
      console.log(error.message);
      if (error.message.includes('Unique constraint')) {
        toast({
          title: `${error.message.split('_')[1]} already exists!`,
          status: 'error',
          isClosable: true,
        });
        return;
      }
      toast({
        title: error.message,
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <Box>
      <form ref={formRef}>
        <Box>
          {unwantedFields?.includes('sportType') ? null : (
            <Box mt="15px" w="100%" p="10px">
              <FormLabel>{'Sport Type'}</FormLabel>
              <Select
                name="sportTtpe"
                placeholder="Sport Type"
                value={state.sportType}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    sportType: e.target.value,
                  }))
                }
              >
                {sportTypes.map((x) => (
                  <option value={x} key={x}>
                    {x}
                  </option>
                ))}
              </Select>
            </Box>
          )}
          <Flex flexWrap={'wrap'}>
            {inputs.map((input, index) => {
              if (unwantedFields?.includes(input.stateName)) return null;

              return (
                <Box key={index} mt="15px" flex="50%" w="100%" p="10px">
                  <FormLabel>{input.label}</FormLabel>
                  <Input
                    type={input.type}
                    required={input.required}
                    name={input.stateName}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        [input.stateName]: e.target.value,
                      }));
                    }}
                  />
                </Box>
              );
            })}
            {state.sportType !== 'Football' ? null : (
              <>
                {' '}
                {unwantedFields?.includes('jerseyNumber') ? null : (
                  <Box mt="15px" flex="50%" w="100%" p="10px">
                    <FormLabel>{'Jersey Number'}</FormLabel>
                    <Select
                      name="jerseyNumber"
                      placeholder="Jersey Number"
                      value={state.jerseyNumber}
                      required
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          jerseyNumber: e.target.value,
                        }))
                      }
                    >
                      {jNumbers.map((x) => (
                        <option value={x} key={x}>
                          {x}
                        </option>
                      ))}
                    </Select>
                  </Box>
                )}
                {unwantedFields?.includes('kitSize') ? null : (
                  <Box mt="15px" flex="50%" w="100%" p="10px">
                    <FormLabel>{'Kit Size'}</FormLabel>
                    <Select
                      name="kitSize"
                      placeholder="Kit Size"
                      value={state.kitSize}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          kitSize: e.target.value,
                        }))
                      }
                    >
                      {kitSize.map((x) => (
                        <option value={x} key={x}>
                          {x}
                        </option>
                      ))}
                    </Select>
                  </Box>
                )}
              </>
            )}

            <Box w="50%" mt="15px" p="10px">
              <FormLabel>Please Upload your ID photo</FormLabel>
              <Input
                ref={photoRef}
                type="file"
                required
                onChange={(e) => {
                  if (e.target.files) {
                    if (e.target.files.length) {
                      setPhoto(e.target.files[0]);
                    }
                  }
                }}
              />
            </Box>
          </Flex>

          <Box mt="50px" textAlign={'center'}>
            <Button
              type="submit"
              bg={greenishColor}
              color="white"
              size="lg"
              _hover={{ bg: 'teal.400' }}
              onClick={handleRegister}
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
            >
              {btnText}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default RegistrationComponent;
