import { createTypedHooks, createStore } from 'easy-peasy';
import state, { StoreModel } from './state';

const store = createStore(state);

const typedHooks = createTypedHooks<StoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export default store;
