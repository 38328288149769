import AdminNav from './AdminNav';

const Layout = () => {
  return (
    <div>
      <AdminNav />
    </div>
  );
};

export default Layout;
