import { Box, Button, Heading } from '@chakra-ui/react';
import { greenishColor } from '../utils/theme';

const NotFound = ({ needsLogin }: { needsLogin?: boolean }) => {
  return (
    <Box textAlign={'center'}>
      <Box>
        <Heading as="h2" fontSize="lg">
          {needsLogin
            ? 'You need to be logged in to visit this page'
            : '404 - Page not found'}
        </Heading>
      </Box>
      <Box>
        <Button
          as="a"
          href={needsLogin ? '/login' : '/'}
          color={greenishColor}
          mt="15px"
          variant={'link'}
        >
          {needsLogin ? 'Go to Login page' : 'Go to Home'}
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
