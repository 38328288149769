import { useQuery } from '@apollo/client';
import { FETCH_PUBLIC_PLAYERS_DATA } from '../utils/queries';
import { PlayerOrderByInput, PlayerWhereInput } from '../__generated__/graphql';
import { PlayerInterface } from '../containers/Player';
import { useMemo } from 'react';

export default function usePlayers({
  where,
  orderBy,
  take,
  skip,
}: {
  where: PlayerWhereInput;
  orderBy?: PlayerOrderByInput;
  take?: number;
  skip?: number;
}) {
  const { data, loading, error } = useQuery(FETCH_PUBLIC_PLAYERS_DATA, {
    variables: {
      where,
      orderBy,
      take,
      skip,
    },
  });

  const players = useMemo(() => {
    return (data?.players as Array<PlayerInterface>) || null;
  }, [data]);

  return {
    players,
    loading,
    error,
  };
}
