import { Heading, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

const HeadingTitle = ({
  children,
}: {
  children: ReactNode;
  color?: string;
}) => {
  return (
    <Heading as="h1" fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}>
      <Text
        as={'span'}
        position={'relative'}
        zIndex="1"
        _after={{
          content: "''",
          width: '100%',
          height: '10px',
          position: 'absolute',
          bottom: 2,
          left: 0,
          bg: 'teal.400',
          zIndex: -1,
        }}
      >
        {children}
      </Text>
    </Heading>
  );
};

export default HeadingTitle;
