import { ReactNode } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  Image,
  FlexProps,
  Stack,
  HStack,
  useMediaQuery,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react';

import { FiHome, FiMenu } from 'react-icons/fi';
import { BiTransfer, BiTrophy } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import { IconType } from 'react-icons';
import WhiteLogo from '../assets/white-logo.png';
import BlueLogo from '../assets/AL-Blue-Logo.png';

import { greenishColor, redishColor } from '../utils/theme';
import { useStoreState } from '../store';
import { Link, useNavigate } from 'react-router-dom';
import usePlayerInfo from '../hooks/usePlayerInfo';
import { SERVER_URL } from '../utils/constants';

interface LinkItemProps {
  name: string;
  icon: IconType;
  to: string;
}

export const LinkItems: Array<LinkItemProps> = [
  { name: 'Home', icon: FiHome, to: '/' },
  { name: 'Payments', icon: MdEmail, to: '/payments' },
  { name: 'Register', icon: BiTrophy, to: '/register' },
  { name: 'Standings', icon: BiTrophy, to: '/league' },
  { name: 'Transfer Market', icon: BiTransfer, to: '/transfer-market' },
  { name: 'Contact us', icon: MdEmail, to: '/contact' },
];

export default function Sidebar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const backgroundColor = useColorModeValue('black', 'gray.900');
  return (
    <Box minH="100vh" bg={backgroundColor}>
      {/* <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      /> */}
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size={'xs'}
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box>{children}</Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const playerInfo = usePlayerInfo();
  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderLeft="1px"
      borderLeftColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full' }}
      h="full"
      {...rest}
    >
      <Stack h="100%" justifyContent="space-between">
        <Box>
          <Flex
            h="20"
            alignItems="center"
            mx="8"
            justifyContent="space-between"
          >
            <CloseButton onClick={onClose} ml="auto" size="lg" />
          </Flex>

          {LinkItems.map((link) => (
            <NavItem
              key={link.name}
              to={link.to}
              icon={link.icon}
              onClick={onClose}
            >
              {link.name}
            </NavItem>
          ))}

          {/* <Box pl="30px" mt="30px">
            <Text mb="10px">Follow us:</Text>
            <Stack direction={'row'} spacing={6}>
              <SocialButton
                label={'Facebook'}
                href={'https://www.facebook.com/EALofficial?_rdc=1&_rdr'}
              >
                <FaFacebook />
              </SocialButton>
              <SocialButton
                label={'YouTube'}
                href={
                  'https://www.youtube.com/channel/UCZveky4E3MWCl-J5p7Gn0Iw'
                }
              >
                <FaYoutube />
              </SocialButton>
              <SocialButton
                label={'Instagram'}
                href={'https://www.instagram.com/_ealofficial/'}
              >
                <FaInstagram />
              </SocialButton>
            </Stack>
          </Box> */}
        </Box>
        {playerInfo ? null : (
          <Box textAlign={'center'} pb="30px">
            <Link to="/login" style={{ maxWidth: '80%' }} onClick={onClose}>
              <Box
                w="80%"
                margin="0 auto"
                rounded={'full'}
                fontWeight={'normal'}
                p={3}
                bg={greenishColor}
                _hover={{ bg: greenishColor }}
                color="white"
              >
                Log In
              </Box>
            </Link>
            <Box h="20px" />
            <Link to="/register" style={{ maxWidth: '80%' }} onClick={onClose}>
              <Box
                w="80%"
                margin="0 auto"
                rounded={'full'}
                fontWeight={'normal'}
                p={3}
                bg={redishColor}
                _hover={{ bg: redishColor }}
                color="white"
              >
                Register
              </Box>
            </Link>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactNode;
  to: string;
  isDesktop?: boolean;
}
const NavItem = ({ icon, to, children, ...rest }: NavItemProps) => {
  return (
    <Link to={to}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: redishColor,
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const DesktopMenu = ({ isDark }: { isDark: boolean }) => {
  const playerInfo = usePlayerInfo();
  const [mediaMin500] = useMediaQuery('(min-width: 500px)');
  const handleLogout = () => {
    localStorage.removeItem('playerInfo');
    window.location.href = `/`;
    window.location.reload();
  };
  return (
    <HStack mt="15px" ml="60px" mr="40px" fontWeight="bold">
      {LinkItems.map((link) => (
        <Link key={link.name} to={link.to}>
          <Box
            mr="20px"
            color={isDark ? 'white' : '#272727'}
            transition="all 0.3s ease"
            _hover={{ color: greenishColor }}
          >
            {link.name}
          </Box>
        </Link>
      ))}
      {playerInfo ? (
        <Menu>
          <MenuButton as={Button} colorScheme="gray" rounded="full">
            <Flex alignItems="center" gap="10px">
              <Avatar
                size="sm"
                name={`${playerInfo.firstName} ${playerInfo.lastName}`}
                src={SERVER_URL + playerInfo.photo?.url}
              />
              {mediaMin500
                ? `${playerInfo.firstName} ${playerInfo.lastName}`
                : null}
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuGroup title="Profile">
              <MenuItem>
                <Link
                  to={`/account`}
                  style={{ display: 'block', width: '100%' }}
                >
                  <Box w="100%">My Account</Box>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to={`/joined-leagues`}
                  state={{ private: true }}
                  style={{ display: 'block', width: '100%' }}
                >
                  <Box w="100%">My Teams</Box>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to={`/register`}
                  style={{ display: 'block', width: '100%' }}
                >
                  <Box w="100%">Join / Migrate League</Box>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Box w="100%">Log out</Box>
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title="Help">
              <MenuItem>
                <Link to={`/season-details`}>
                  <Box w="100%">Season Details</Box>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={`/league-rules`}>
                  <Box w="100%">League Rules</Box>
                </Link>
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
          </MenuList>
        </Menu>
      ) : (
        <Link to="/login">
          <Box
            minW="130px"
            textAlign={'center'}
            rounded={'full'}
            fontWeight={'normal'}
            p={'7px 20px'}
            transition="all 0.3s ease"
            bg={greenishColor}
            _hover={{ bg: greenishColor, opacity: 0.8 }}
            color="white"
          >
            Log In
          </Box>
        </Link>
      )}
    </HStack>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const playerInfo = usePlayerInfo();

  const handleLogout = () => {
    localStorage.removeItem('playerInfo');
    window.location.href = `/`;
  };
  const [isLargerThan850] = useMediaQuery('(min-width: 850px)');
  const [mediaMin500] = useMediaQuery('(min-width: 500px)');
  const navigate = useNavigate();
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;

  const logo = isDark ? WhiteLogo : BlueLogo;

  return (
    <Flex
      alignItems="center"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between' }}
      {...rest}
      w="full"
      zIndex="30"
      position="absolute"
    >
      <Box
        w={{ md: '180px', base: '146px' }}
        h={{ base: '55px', md: '73px' }}
        overflow="hidden"
        mt={{ base: 5, md: '8px' }}
        ml={{ base: 5, md: 35 }}
      >
        <Image
          w={{ base: 180, md: 180 }}
          src={logo}
          position="relative"
          top="-50px"
          cursor="pointer"
          onClick={() => navigate('/')}
        />
      </Box>
      {isLargerThan850 ? (
        <DesktopMenu isDark={isDark} />
      ) : (
        <Box display="flex" alignItems="center">
          {playerInfo ? (
            <Menu>
              <MenuButton
                as={Button}
                colorScheme="gray"
                m={{ base: '10px 5px 0 0', md: '20px 5px 0 0' }}
              >
                <Flex alignItems="center" gap="10px">
                  <Avatar
                    size="sm"
                    name={`${playerInfo.firstName} ${playerInfo.lastName}`}
                    src={SERVER_URL + playerInfo.photo?.url}
                  />
                  {mediaMin500
                    ? `${playerInfo.firstName} ${playerInfo.lastName}`
                    : null}
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuGroup title="Profile">
                  <MenuItem>
                    <Link
                      to={`/account`}
                      state={{ private: true }}
                      style={{ display: 'block', width: '100%' }}
                    >
                      My Account
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to={`/joined-leagues`}
                      style={{ display: 'block', width: '100%' }}
                    >
                      My Teams
                    </Link>
                  </MenuItem>

                  <MenuItem>
                    <Link
                      to={`/register`}
                      style={{ display: 'block', width: '100%' }}
                    >
                      Join / Migrate League
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleLogout}>Log out</MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title="Help">
                  <MenuItem>
                    <Link
                      to={`/season-details`}
                      style={{ display: 'block', width: '100%' }}
                    >
                      Season Details
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to={`/league-rules`}
                      style={{ display: 'block', width: '100%' }}
                    >
                      League Rules
                    </Link>
                  </MenuItem>
                </MenuGroup>
                <MenuDivider />
              </MenuList>
            </Menu>
          ) : (
            <Link to="/login">
              <Box
                m={[
                  '10px 10px 0 0',
                  '10px 10px 0 0',
                  '20px 15px 0 0',
                  '20px 15px 0 0',
                ]}
                minW={{ md: '130px', base: '100px' }}
                textAlign={'center'}
                rounded={'full'}
                fontWeight={'normal'}
                p={['7px 10px', '7px 20px', '7px 20px', '7px 20px']}
                transition="all 0.3s ease"
                bg={greenishColor}
                _hover={{ bg: greenishColor, opacity: 0.8 }}
                color="white"
              >
                Log In
              </Box>
            </Link>
          )}
          <IconButton
            pt={{ base: 3, md: 30 }}
            pr={{ base: 5, md: 50 }}
            variant="ghost"
            onClick={onOpen}
            aria-label="open menu"
            size="lg"
            _hover={{
              background: 'none',
            }}
            _active={{
              background: 'none',
            }}
            icon={<FiMenu size="35" color={isDark ? 'white' : 'black'} />}
          />
        </Box>
      )}
    </Flex>
  );
};
