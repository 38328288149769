import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { greenishColor, redishColor } from '../utils/theme';
import { useStoreState, useStoreActions } from '../store';

const RegisterPopup = () => {
  const { popupsModel: popupsState } = useStoreState((state) => state);
  const { popupsModel: popupsActions } = useStoreActions((state) => state);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);
  useEffect(() => {
    if (popupsState.showRegisterPopup) {
      onOpen();
    }
  }, [onOpen, popupsState.showRegisterPopup]);

  const handleClose = () => {
    popupsActions.setShowRegisterPopup(false);
    onClose();
  };
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              User Required
            </AlertDialogHeader>

            <AlertDialogBody>
              You seem to either not registered or not logged in. Please{' '}
              <Text
                as="a"
                href="/login"
                color={greenishColor}
                textDecoration={'underline'}
              >
                click here go login
              </Text>{' '}
              or{' '}
              <Text
                as="a"
                href="/register"
                color={redishColor}
                textDecoration={'underline'}
              >
                register a new account.
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleClose}>
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default RegisterPopup;
