import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  Select,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { MAIN_LEAGUE, SERVER_URL } from '../../utils/constants';
import { lowerCaseNoSpaces, returnImage } from '../../utils/helper';
import { Link } from 'react-router-dom';
import BlueLogo from '../../assets/theme-icon.png';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useLeaguesState from '../../hooks/useLeaguesState';
import { LeagueTableType } from '../../utils/types';
import useLeagueTables, {
  orderedTable,
  tableToGroups,
} from '../../hooks/useLeagueTables';
import { greenishColor } from '../../utils/theme';
import Loader from '../Loader';
import {
  Carousel,
  Slide,
  Slider,
  SliderBarDotGroup,
  renderDotsDynamicCircle,
} from 'react-scroll-snap-anime-slider';
import PlayerImg from '../../assets/placeholder-player.png';
import RegisterImg from '../../assets/register-img.jpg';
import { ArrowRightIcon } from '@chakra-ui/icons';
import usePrevious from '../../hooks/usePrevious';

interface LeagueTables {
  leagueTables: LeagueTableType[];
  loading: boolean;
}
const LeagueStanding = ({
  isFull,
  removeFP,
  removeGD,
  removeLinks,
  fallbackLogo,
  returnData,
}: {
  fallbackLogo?: string;
  isFull?: boolean;
  removeGD?: boolean;
  removeFP?: boolean;
  removeLinks?: boolean;
  returnData?: ({ leagueTables, loading }: LeagueTables) => void;
}) => {
  const [mediaMin991] = useMediaQuery('(min-width: 991px)');
  const [selectedCompetition, setSelectedCompetition] = useState(MAIN_LEAGUE);
  const { currentCompetition, currentSeason, publicCompetitions } =
    useLeaguesState(selectedCompetition);

  const [selectedSeason, setSelectedSeason] = useState('');

  const seasonId = useMemo(() => {
    if (selectedSeason) {
      const foundSeason = currentCompetition?.seasons?.find(
        (season) => season?.id === selectedSeason
      );
      if (foundSeason) return foundSeason?.id;
    }
    return currentSeason?.id;
  }, [currentSeason, selectedSeason, currentCompetition]);

  const { leagueTables, loading } = useLeagueTables({
    competition: { id: { equals: currentCompetition?.id } },
    season: { id: { equals: seasonId } },
  });

  const groups = useMemo(() => {
    return tableToGroups(leagueTables?.[0]?.table);
  }, [leagueTables]);

  const [group, setGroup] = useState<string>('');

  const previousGroup = usePrevious(group);

  useEffect(() => {
    if (
      (!group && groups && Object.keys(groups).length > 0) ||
      (groups && selectedCompetition && group === previousGroup)
    ) {
      setGroup(Object.keys(groups)?.sort()?.[0]);
    }
  }, [group, groups, selectedCompetition, previousGroup]);

  useEffect(() => {
    if (leagueTables && returnData) {
      returnData({
        leagueTables: leagueTables as LeagueTableType[],
        loading,
      });
    }
  }, [leagueTables, loading, returnData]);

  return (
    <Box>
      <HStack justifyContent={'space-between'} px="25px" fontStyle="italic">
        <Text>League</Text>
        <Text>Group</Text>
      </HStack>
      <Box bg={greenishColor} height="90px" borderRadius="30px 30px 0 0">
        <HStack alignItems="center" justifyContent={'space-between'} pt="20px">
          <Box pl={{ base: '15px', md: '25px' }}>
            <HStack>
              <Select
                w="fit-content"
                maxW={{ base: '120px', md: '150px' }}
                textTransform={'uppercase'}
                variant={'unstyled'}
                color="white"
                fontWeight="bold"
                fontSize="md"
                value={selectedCompetition}
                onChange={(e) => {
                  setSelectedCompetition(e.target.value);
                }}
              >
                {publicCompetitions?.map((comp) => {
                  return <option key={comp.id}>{comp.name}</option>;
                })}
              </Select>

              <Select
                w="60px"
                textTransform={'uppercase'}
                variant={'unstyled'}
                color="white"
                fontWeight="bold"
                fontSize="md"
                value={(seasonId as string) ?? ''}
                onChange={(e) => {
                  setSelectedSeason(e.target.value);
                }}
              >
                {currentCompetition?.seasons?.map((season) => {
                  return (
                    <option key={season.id} value={season.id}>
                      S{season?.seasonNumber}
                    </option>
                  );
                })}
              </Select>
            </HStack>
          </Box>
          {groups && Object.keys(groups).length > 0 && !groups.null ? (
            <Box pr="10px">
              <HStack>
                <Text color="white">
                  <Text as="span">{mediaMin991 ? 'Group' : 'G'}</Text>/
                </Text>
                <Select
                  textTransform={'uppercase'}
                  variant={'unstyled'}
                  color="white"
                  fontWeight="bold"
                  fontSize="lg"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                >
                  {Object.keys(groups)
                    .sort()
                    .map((key: string) => {
                      return <option key={key}>{key}</option>;
                    })}
                </Select>
              </HStack>
            </Box>
          ) : null}
        </HStack>
      </Box>
      <Box
        bg="white"
        borderRadius="30px"
        mt="-30px"
        boxShadow="md"
        pb="10px"
        minH="556px"
      >
        <Box>
          {loading && !leagueTables ? (
            <Loader />
          ) : groups && Object.keys(groups).length > 0 ? (
            <Box>
              <Box>
                <Box
                  p="5px 5px 5px 10px"
                  fontSize={['14px', '14px', '16px', '16px']}
                >
                  <Flex
                    justifyContent="space-between"
                    padding="25px 10px 10px"
                    alignItems="center"
                  >
                    <HStack>
                      <Box>POS</Box>
                      <Box ml="20px">TEAM</Box>
                    </HStack>

                    <HStack
                      spacing="0"
                      fontSize={['14px', '14px', '16px', '16px']}
                    >
                      <Box w="30px" textAlign={'center'}>
                        PTS
                      </Box>
                      {isFull ? (
                        <>
                          <Box w="30px" textAlign={'center'}>
                            W
                          </Box>
                          <Box w="30px" textAlign={'center'}>
                            L
                          </Box>
                          <Box w="30px" textAlign={'center'}>
                            D
                          </Box>
                        </>
                      ) : null}

                      {isFull && !removeFP ? (
                        <Box w="30px" textAlign={'center'}>
                          FP
                        </Box>
                      ) : null}
                      <Box w="40px" textAlign={'center'}>
                        PLD
                      </Box>
                      {removeGD ? null : (
                        <Box w="30px" textAlign={'center'}>
                          GD
                        </Box>
                      )}
                    </HStack>
                  </Flex>
                </Box>
              </Box>

              {orderedTable(groups[group]).map((p, index) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const [_f, _s, ...rest] = p.team?.split(' ').filter(Boolean);
                const joinedName = rest.join(' ');
                const name = joinedName?.includes('EAL')
                  ? joinedName.replace(`E${selectedCompetition}`, '')
                  : joinedName.replace(selectedCompetition, '');

                return (
                  <Box key={p.partId + 'league table'}>
                    <Link
                      to={`/team/${p.partId}`}
                      style={{ pointerEvents: removeLinks ? 'none' : 'auto' }}
                    >
                      <HStack
                        alignItems="center"
                        justifyContent="space-between"
                        cursor="pointer"
                        _hover={{ bg: 'teal.50', color: 'inherit' }}
                        padding="0 10px 0 20px"
                        borderTop="1px solid"
                        borderColor="gray.400"
                      >
                        <HStack alignItems="center">
                          <Flex
                            h={'58px'}
                            w={'58px'}
                            justifyContent="center"
                            alignItems="center"
                            fontSize="md"
                            textAlign="center"
                          >
                            <Box mr="10px">{index + 1}.</Box>
                            <Box>
                              {p.participation?.teams?.[0]?.teamLogo?.url ? (
                                <Image
                                  src={
                                    SERVER_URL +
                                    returnImage(p.participation?.teams?.[0])
                                  }
                                  boxSize={'40px'}
                                  objectFit="cover"
                                  m="0 auto"
                                  cursor="pointer"
                                  fallbackSrc={fallbackLogo ?? BlueLogo}
                                />
                              ) : (
                                <Avatar
                                  size="sm"
                                  name={`${p?.team} `}
                                  src={''}
                                />
                              )}
                            </Box>
                          </Flex>

                          <Flex
                            flexDirection="column"
                            h="58px"
                            justifyContent="center"
                          >
                            <Flex
                              noOfLines={[1, 1, 2, 2]}
                              fontSize={['14px', '14px', '16px', '16px']}
                              fontWeight={'bold'}
                              alignItems="center"
                              textTransform={'capitalize'}
                              maxW={['102px', '102px', 'auto', 'auto']}
                            >
                              {name}
                            </Flex>
                          </Flex>
                        </HStack>
                        <Box px="5px" fontWeight={'bold'}>
                          {p ? (
                            <HStack
                              spacing="0"
                              fontSize={['14px', '14px', '16px', '16px']}
                            >
                              <Box
                                w="30px"
                                textAlign={'center'}
                                fontWeight={'bold'}
                              >
                                {p.PTS}
                              </Box>
                              <Box w="40px" textAlign={'center'}>
                                {p.MP}
                              </Box>
                              {isFull ? (
                                <>
                                  <Box w="30px" textAlign={'center'}>
                                    {p.W}
                                  </Box>
                                  <Box w="30px" textAlign={'center'}>
                                    {p.L}
                                  </Box>
                                  <Box w="30px" textAlign={'center'}>
                                    {p.D}
                                  </Box>
                                </>
                              ) : null}

                              {isFull && !removeFP ? (
                                <Box w="30px" textAlign={'center'}>
                                  {p.FP}
                                </Box>
                              ) : null}

                              {removeGD ? null : (
                                <Box w="30px" textAlign={'center'}>
                                  {p.GD}
                                </Box>
                              )}
                            </HStack>
                          ) : null}
                        </Box>
                      </HStack>
                    </Link>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Center py="50px">Standing is not available</Center>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const LeagueStats = ({ fetchedData }: { fetchedData: LeagueTables }) => {
  const [mediaMin1800] = useMediaQuery('(min-width: 1900px)');
  const [mediaMin1700] = useMediaQuery('(min-width: 1700px)');
  const [mediaMin1600] = useMediaQuery('(min-width: 1600px)');
  const [mediaMin1200] = useMediaQuery('(min-width: 1200px)');
  const [mediaMin991] = useMediaQuery('(min-width: 991px)');
  const [mediaMin500] = useMediaQuery('(min-width: 500px)');

  function returnVisibleSlides() {
    if (mediaMin1800) {
      return 3;
    } else if (mediaMin1700) {
      return 3;
    } else if (mediaMin1600) {
      return 3;
    } else if (mediaMin1200) {
      return 3;
    } else if (mediaMin991 || mediaMin500) {
      return 2;
    }
    return 1;
  }

  const { leagueTables, loading } = fetchedData;

  const carouselCards = useMemo(() => {
    const cards: any[] = [];
    if (leagueTables?.[0]?.topScorer?.length) {
      cards.push({
        ...leagueTables?.[0].topScorer[0],
        slideName: 'Top Scorer',
      });
    }
    if (leagueTables?.[0]?.topAssist?.length) {
      cards.push({
        ...leagueTables?.[0].topAssist[0],
        slideName: 'Top Assist',
      });
    }
    if (leagueTables?.[0]?.topGoalie?.length) {
      cards.push({
        ...[...leagueTables?.[0].topGoalie][0],
        slideName: 'Top Goalie',
      });
    }
    if (leagueTables?.[0]?.topCard?.length) {
      cards.push({
        ...[...leagueTables?.[0].topCard][0],
        slideName: 'Cards',
      });
    }
    return cards;
  }, [leagueTables]);

  return (
    <Box>
      <Box>
        <Heading
          fontSize="4xl"
          fontWeight="extrabold"
          color={greenishColor}
          position="relative"
          _after={{
            content: '""',
            display: 'block',
            width: 'calc(100% - 120px)',
            height: '45px',
            background: greenishColor,
            position: 'absolute',
            right: '0',
            top: '-1px',
            borderRadius: '25px',
          }}
        >
          STATS
          {leagueTables?.[0]?.competition?.name ? (
            <Box
              w="fit-content"
              position="absolute"
              right="20px"
              top="-17%"
              zIndex="3"
            >
              <Link
                to={`/league/${lowerCaseNoSpaces(
                  leagueTables?.[0]?.competition?.name
                )}?tab=stats&season=${lowerCaseNoSpaces(
                  leagueTables?.[0]?.season?.name
                )}`}
              >
                <Button
                  variant="link"
                  color="white"
                  rightIcon={<ArrowRightIcon width="10px" />}
                >
                  More Stats
                </Button>
              </Link>
            </Box>
          ) : null}
        </Heading>
      </Box>

      <Box mt="25px">
        {loading && !carouselCards.length ? (
          <Box>
            <Loader />
          </Box>
        ) : carouselCards?.length ? (
          <Carousel
            totalSlides={carouselCards.length + 1}
            visibleSlides={returnVisibleSlides()}
            step={1}
            slideMargin={'10px'}
            trayPadding={'0 100px'}
          >
            <Slider>
              {carouselCards.map((card) => {
                const name = card?.name.split('(')[0];

                return (
                  <Slide key={card.playerId + card.slideName}>
                    <Link to={`/player/${card.playerId}`}>
                      <Box
                        position="relative"
                        _hover={{
                          boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
                          borderRadius: '25px',
                        }}
                        transition="all 0.3s ease"
                      >
                        <Text
                          position="absolute"
                          top="20px"
                          right="20px"
                          fontSize="4xl"
                          fontWeight={'extrabold'}
                          color={'#D2DD6A'}
                        >
                          {card.slideName}
                        </Text>
                        <Box>
                          <Box
                            borderRadius="30px"
                            overflow={'hidden'}
                            maxH={'450px'}
                            h={'450px'}
                            minH="100%"
                          >
                            <Image
                              src={
                                SERVER_URL + (card.photo || card.playerPhoto)
                              }
                              objectFit="cover"
                              fallbackSrc={PlayerImg}
                              height="100%"
                            />
                          </Box>

                          <Box mt="10px">
                            <Box p={'0 12px'}>
                              <HStack
                                alignItems="center"
                                justifyContent={'space-between'}
                              >
                                <Box>NAME</Box>

                                <Text>TEAM</Text>
                                <Text textTransform="uppercase">
                                  {card.slideName === 'Top Goalie'
                                    ? 'In Goals'
                                    : card.slideName === 'Top Scorer'
                                    ? 'Goals'
                                    : card.slideName === 'Top Assist'
                                    ? 'Assists'
                                    : 'Cards'}
                                </Text>
                              </HStack>
                            </Box>
                            <Box
                              p={'15px 12px'}
                              boxShadow={'md'}
                              m="0px 0 10px"
                              borderRadius="20px"
                            >
                              <HStack
                                alignItems="center"
                                justifyContent={'space-between'}
                              >
                                <Box
                                  fontSize="lg"
                                  fontWeight="bold"
                                  noOfLines={1}
                                >
                                  {name}
                                </Box>

                                <HStack>
                                  <Image
                                    src={
                                      SERVER_URL +
                                      card?.teamData?.teams?.[0]?.teamLogo?.url
                                    }
                                    boxSize="30px"
                                    fallbackSrc={BlueLogo}
                                    mr="10px"
                                  />
                                  <Text
                                    color={greenishColor}
                                    fontSize="xl"
                                    fontWeight="extrabold"
                                    mr="20px"
                                  >
                                    {card.slideName === 'Top Goalie'
                                      ? card.inGoals
                                      : card.slideName === 'Top Scorer'
                                      ? card.goals
                                      : card.slideName === 'Top Assist'
                                      ? card.assists
                                      : Number(card.redCard) +
                                        Number(card.yellowCard)}
                                  </Text>
                                </HStack>
                              </HStack>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Link>
                  </Slide>
                );
              })}
              <Slide>
                <Box borderRadius="30px" overflow={'hidden'}>
                  <Link to="/register">
                    <Box
                      _hover={{ opacity: 0.95 }}
                      transition="all 0.3s ease"
                      height={'523px'}
                      bg={'#32499d'}
                      display="flex"
                      borderRadius="30px"
                      alignItems="center"
                    >
                      <Box
                        borderRadius="30px"
                        overflow={'hidden'}
                        bg={greenishColor}
                      >
                        <Image
                          src={RegisterImg}
                          objectFit="cover"
                          fallbackSrc={PlayerImg}
                          height="100%"
                        />
                      </Box>
                    </Box>
                  </Link>
                </Box>
              </Slide>
            </Slider>

            <SliderBarDotGroup
              id="my-slider-dot-group"
              className="my-class-name"
              aria-label="slider bar"
              dotGroupProps={{
                id: 'my-slider-bar-dot-group',
              }}
              renderDots={renderDotsDynamicCircle}
            />
          </Carousel>
        ) : (
          <Center py="45px">Stats is not available</Center>
        )}
      </Box>
    </Box>
  );
};

const Leagues = () => {
  const [fetchedData, setFetchedData] = useState<LeagueTables>({
    leagueTables: [],
    loading: false,
  });

  const getFetchedData = useCallback(
    ({ leagueTables, loading }: LeagueTables) => {
      setFetchedData({ leagueTables, loading });
    },
    []
  );

  return (
    <Box maxW="1440px" margin="30px auto 100px" p="0 20px">
      <HStack
        flexDir={['column', 'column', 'column', 'row']}
        gap={50}
        alignItems="stretch"
      >
        <Box w="100%" width={['100%', '400px', '450px', '450px']} margin="auto">
          <LeagueStanding returnData={getFetchedData} removeFP />
        </Box>
        <Box flex="1" mt="25px">
          <LeagueStats fetchedData={fetchedData} />
        </Box>
      </HStack>
    </Box>
  );
};

export default Leagues;
