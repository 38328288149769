import { MatchInterface } from '../../utils/types';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  HStack,
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useMediaQuery,
  Text,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { GET_TEAM_PLAYERS } from '../../utils/queries';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UPDATE_MATCH } from '../../utils/mutations';
import { timeout } from '../../utils/helper';
import useAdminState from '../../hooks/useAdminState';
import { useNavigate } from 'react-router-dom';

interface Props {
  match: MatchInterface;
  refetch: (
    variables?:
      | Partial<{
          id: string | undefined;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
}

interface ActionDetail {
  team: string;
  type: string;
  time: string;
  name: string;
  isHomeTeam: boolean;
  isFirstHalf: boolean;
  photo?: any;
  position?: string;
  playerId?: string;
  assistName?: string;
  assistPhoto?: any;
  assistId?: string;
  isOwnGoal?: boolean;
  isInjurySub?: boolean;
  isDirectRedCard?: boolean;
  isPenaltyGoal?: boolean;
}

const types = [
  'Goal',
  'Substitution',
  'Red Card',
  'Yellow Card',
  'Penalty Miss',
];

const ActionDetails = ({ match: matchData, refetch: refetchMatch }: Props) => {
  const [isLessThan500] = useMediaQuery('(max-width: 500px)');
  const navigate = useNavigate();
  const { authenticatedUser } = useAdminState();
  const match = useMemo(() => {
    return matchData;
  }, [matchData]);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: openAlert,
    onClose: closeAlert,
  } = useDisclosure();
  const [type, setType] = useState('');
  const [team, setTeam] = useState('');
  const [name, setName] = useState('');

  const [player, setPlayer] = useState<any>(null);
  const [time, setTime] = useState('');
  const [assistName, setAssistName] = useState('');
  const [assistPlayer, setAssistPlayer] = useState<any>(null);
  const [isFirstHalf, setIsFirstHalf] = useState(true);
  const [isDirectRedCard, setIsDirectRedCard] = useState(true);
  const [inValue, setInValue] = useState('');
  const [outValue, setOutValue] = useState('');
  const [isInjurySub, setIsInjurySub] = useState(false);
  const [isOwnGoal, setIsOwnGoal] = useState(false);
  const [isPenaltyGoal, setIsPenaltyGoal] = useState(false);

  const [updateMatch] = useMutation(UPDATE_MATCH);

  const [index, setIndex] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const homeTeamName = match?.homeTeam?.teams?.[0].name;
  const awayTeamName = match?.awayTeam?.teams?.[0].name;
  const homeTeamId = match?.homeTeam?.id;
  const awayTeamId = match?.awayTeam?.id;

  const { data: homeTeamPlayers } = useQuery(GET_TEAM_PLAYERS, {
    variables: { id: homeTeamId },
  });

  const { data: awayTeamPlayers } = useQuery(GET_TEAM_PLAYERS, {
    variables: { id: awayTeamId },
  });

  const cancelRef = useRef<any>(null);

  const returnTeamPlayers = useMemo(() => {
    const players =
      team === match?.homeTeam?.name
        ? homeTeamPlayers?.players
        : awayTeamPlayers?.players;
    if (players?.length) {
      return players.map((player: any) => {
        const value = `${player.firstName} ${player.lastName} (${player.jerseyNumber})`;
        return (
          <option
            key={player?.id}
            value={value}
            disabled={player?.suspended ? true : false}
          >
            {value}
          </option>
        );
      });
    }
    return [];
  }, [team, homeTeamPlayers, awayTeamPlayers, match]);

  const saveActions = useCallback(
    async (action?: 'delete' | 'edit') => {
      if (!authenticatedUser) {
        toast({
          title: `Login Required!, You don't have permission to perform this action`,
          status: 'error',
          position: 'top-right',
        });
        await timeout(1000);
        navigate('/admin');
        return;
      }
      setIsSubmitting(true);
      try {
        let newActionDetails: Array<ActionDetail>;
        const actionDetailsCopy = [...match?.actionDetails];
        if (index !== null && action === 'delete') {
          actionDetailsCopy.splice(index, 1);
          newActionDetails = actionDetailsCopy;
        } else {
          const modActions = {
            team,
            type,
            time,
            name,
            isHomeTeam: team === match?.homeTeam?.name,
            isFirstHalf,
            isInjurySub,
            isOwnGoal,
            isDirectRedCard,
            isPenaltyGoal,
            assistName,
            position: type === 'Goal' && player?.position,
            photo: type === 'Goal' && player?.photo,
            playerId: type === 'Goal' && player?.id,
            assistId: type === 'Goal' && assistPlayer?.id,
            assistPhoto: type === 'Goal' && assistPlayer?.photo,
          };

          if (index !== null) {
            actionDetailsCopy[index] = modActions;
            newActionDetails = actionDetailsCopy;
          } else {
            newActionDetails = [...match?.actionDetails, modActions];
          }
        }
        const homeTeamGoals = newActionDetails.filter(
          (x: any) =>
            (x.type === 'Goal' && x.isHomeTeam && !x.isOwnGoal) ||
            (x.type === 'Goal' && !x.isHomeTeam && x.isOwnGoal)
        );
        const awayTeamGoals = newActionDetails.filter(
          (x: any) =>
            (x.type === 'Goal' && !x.isHomeTeam && !x.isOwnGoal) ||
            (x.type === 'Goal' && x.isHomeTeam && x.isOwnGoal)
        );
        await updateMatch({
          variables: {
            id: match?.id,
            data: {
              actionDetails: newActionDetails,
              homeTeamScore: homeTeamGoals.length,
              awayTeamScore: awayTeamGoals.length,
            },
          },
        });

        toast({
          title: `Actions saved Successfully!`,
          status: 'success',
          position: 'top-right',
        });
        refetchMatch();
        onClose();
        closeAlert();
        await timeout(1000);
      } catch (error) {
        console.log(error);
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          position: 'top-right',
        });
      } finally {
        setIsSubmitting(false);
        onClose();
        closeAlert();
      }
    },
    [
      player,
      assistName,
      assistPlayer,
      navigate,
      authenticatedUser,
      isDirectRedCard,
      isOwnGoal,
      isFirstHalf,
      isInjurySub,
      isPenaltyGoal,
      index,
      refetchMatch,
      time,
      match,
      name,
      onClose,
      closeAlert,
      team,
      toast,
      type,
      updateMatch,
    ]
  );
  const clearAll = () => {
    setTeam('');
    setName('');
    setType('');
    setInValue('');
    setOutValue('');
    setTime('');
    setIndex(null);
    setIsOwnGoal(false);
    setIsFirstHalf(true);
    setIsInjurySub(false);
    setIsDirectRedCard(true);
    setPlayer(null);
    setAssistPlayer(null);
    setAssistName('');
  };

  const returnPlayerData = useCallback(
    (name: string) => {
      const players =
        team === match?.homeTeam?.name
          ? homeTeamPlayers?.players
          : awayTeamPlayers?.players;

      if (players?.length) {
        const foundPlayer = players.find((player: any) => {
          const value = `${player.firstName} ${player.lastName} (${player.jerseyNumber})`;
          return name === value;
        });
        if (foundPlayer) {
          return foundPlayer;
        }
      }
      return null;
    },
    [awayTeamPlayers, homeTeamPlayers, match, team]
  );

  const returnName = (action: ActionDetail) => {
    if (action.type === 'Goal') {
      return (
        <>
          <Box>{action.name}</Box>
          {action.assistName ? (
            <Box>
              <Text as="span" color="green">
                Assist: {action.assistName}
              </Text>
            </Box>
          ) : null}
          {action.isOwnGoal ? (
            <Box>
              <Text as="span" color="red">
                Own Goal
              </Text>
            </Box>
          ) : null}
        </>
      );
    }
    if (action.type === 'Substitution') {
      return (
        <>
          <Box color={'green'}>{action.name.split(' - ')[0]}</Box>
          <Box color={'red'}>
            {action.name.split(' - ')[1]}
            {action.isInjurySub ? ' - Injury' : ''}
          </Box>
        </>
      );
    }

    if (action.type === 'Red Card') {
      return (
        <>
          <Box>{action.name}</Box>
          {action.isDirectRedCard ? (
            <Box color={'red'}>Direct Red Card</Box>
          ) : (
            <Box color={'red'}>Second Yellow Card</Box>
          )}
        </>
      );
    }

    return action.name;
  };

  const selectPlayer = () => {
    if (type === 'Substitution') {
      return (
        <>
          <Select
            mt="10px"
            onChange={(e) => setInValue(e.target.value)}
            value={inValue}
            disabled={!team}
          >
            <option value="">Select In Player</option>
            {returnTeamPlayers}
          </Select>
          <Select
            mt="10px"
            onChange={(e) => setOutValue(e.target.value)}
            value={outValue}
            disabled={!team}
          >
            <option value="">Select Out Player</option>
            {returnTeamPlayers}
          </Select>
          <Select
            mt="10px"
            onChange={(e) =>
              setIsInjurySub(e.target.value === 'Injury' ? true : false)
            }
            value={isInjurySub ? 'Injury' : 'Player'}
            disabled={!outValue || !inValue}
          >
            <option value="">Select Sub Type</option>
            <option value="Player">Player</option>
            <option value="Injury">Injury</option>
          </Select>
        </>
      );
    }
    return (
      <Select
        mt="10px"
        onChange={(e) => setName(e.target.value)}
        value={name}
        disabled={!team}
      >
        <option value="">Select Player</option>
        {returnTeamPlayers}
      </Select>
    );
  };

  const returnGoalTypes = () => {
    if (type === 'Goal') {
      return (
        <>
          <Select
            mt="10px"
            value={isOwnGoal ? 'Own' : isPenaltyGoal ? 'Penalty' : 'Player'}
            onChange={(event) => {
              const val = event.target.value;
              if (val === 'Own') {
                setIsOwnGoal(true);
                setIsPenaltyGoal(false);
              } else if (val === 'Penalty') {
                setIsPenaltyGoal(true);
                setIsOwnGoal(false);
              } else {
                setIsOwnGoal(false);
                setIsPenaltyGoal(false);
              }
            }}
            disabled={!name}
          >
            <option value="">Select Goal Type</option>
            <option value="Player">Player Goal</option>
            <option value="Own">Own Goal</option>
            <option value="Penalty">Penalty</option>
          </Select>

          {!isOwnGoal && !isPenaltyGoal ? (
            <Select
              mt="10px"
              value={assistName}
              onChange={(event) => {
                setAssistName(event.target.value);
                const foundAssistPlayer = returnPlayerData(event.target.value);
                if (foundAssistPlayer) {
                  setAssistPlayer(foundAssistPlayer);
                }
              }}
            >
              <option value="">Select Assist Player</option>
              {returnTeamPlayers}
            </Select>
          ) : null}
        </>
      );
    }
  };

  const returnRedCardTypes = () => {
    if (type === 'Red Card') {
      return (
        <Select
          value={isDirectRedCard ? 'Direct Red Card' : 'Second Yellow Card'}
          onChange={(event) => {
            setIsDirectRedCard(
              event.target.value === 'Direct Red Card' ? true : false
            );
          }}
        >
          <option value="">Select Card Type</option>
          <option value="Direct Red Card">Direct Red Card</option>
          <option value="Second Yellow Card">Second Yellow Card</option>
        </Select>
      );
    }
  };

  useEffect(() => {
    if (type === 'Goal' && name) {
      const foundPlayer = returnPlayerData(name);

      setPlayer(foundPlayer);
      if (assistName) {
        const assistPlayer = returnPlayerData(assistName);
        setAssistPlayer(assistPlayer);
      }
    }
    if (type === 'Substitution' && inValue && outValue) {
      const foundInPlayer = returnPlayerData(inValue);
      const foundOutPlayer = returnPlayerData(outValue);
      setName(
        `${foundInPlayer?.firstName} ${foundInPlayer?.lastName} (${foundInPlayer?.jerseyNumber}) - ${foundOutPlayer?.firstName} ${foundOutPlayer?.lastName} (${foundOutPlayer?.jerseyNumber})`
      );
    }
  }, [type, name, returnPlayerData, assistName, inValue, outValue]);

  return (
    <Box>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Action
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeAlert}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => saveActions('delete')}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add/Edit Action</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select onChange={(e) => setTeam(e.target.value)} value={team}>
              <option value="">Select Team</option>
              <option value={match?.homeTeam.name}>
                {match?.homeTeam.name}
              </option>
              <option value={match?.awayTeam.name}>
                {match?.awayTeam.name}
              </option>
            </Select>
            <Select
              mt="10px"
              onChange={(e) => setType(e.target.value)}
              value={type}
              disabled={!team}
            >
              <option value="">Select Type</option>
              {types.map((t) => {
                return (
                  <option value={t} key={t}>
                    {t}
                  </option>
                );
              })}
            </Select>
            {selectPlayer()}
            {returnGoalTypes()}
            {returnRedCardTypes()}

            <Select
              mt="10px"
              onChange={(e) =>
                e.target.value === 'First Half'
                  ? setIsFirstHalf(true)
                  : setIsFirstHalf(false)
              }
              value={isFirstHalf ? 'First Half' : 'Second Half'}
              disabled={!type}
            >
              <option value={'First Half'}>First Half</option>
              <option value={'Second Half'}>Second Half</option>
            </Select>
            <Select
              mt="10px"
              onChange={(e) => setTime(e.target.value)}
              value={time}
              disabled={!type}
            >
              <option value="">Select Time</option>
              {Array.from(Array(45)).map((_x, i) => {
                return (
                  <option key={'time' + i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                );
              })}
              {Array.from(Array(12)).map((_x, i) => {
                return (
                  <option key={'time' + i + 1} value={`45+${i + 1}`}>
                    {`45+${i + 1}`}
                  </option>
                );
              })}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
            <Button
              ml="10px"
              colorScheme="blue"
              disabled={!team || !name || !type || isSubmitting}
              isLoading={isSubmitting}
              onClick={() => saveActions()}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <TableContainer>
        <HStack mb="10px" justifyContent={'space-between'}>
          <Box>
            <Button
              size={isLessThan500 ? 'sm' : 'md'}
              onClick={() => {
                clearAll();
                onOpen();
              }}
              colorScheme="blue"
            >
              Add Action
            </Button>
          </Box>
        </HStack>
        <Table variant="simple" size="md">
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>Team</Th>
              <Th>Name</Th>
              <Th>Which Half</Th>
              <Th>Time</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {match?.actionDetails?.map((action, i) => {
              return (
                <Tr
                  key={
                    action.name + action.type + action.time + action.isFirstHalf
                  }
                >
                  <Td>{action.type}</Td>
                  <Td fontWeight="bold">
                    {action.isHomeTeam ? homeTeamName : awayTeamName}
                  </Td>
                  <Td>{returnName(action)}</Td>
                  <Td>{action.isFirstHalf ? 'First Half' : 'Second Half'}</Td>
                  <Td>{action.time}</Td>
                  <Td>
                    <IconButton
                      colorScheme="blue"
                      aria-label="Edit"
                      onClick={() => {
                        setIndex(i);
                        setTeam(action.team);
                        setName(action.name);
                        setType(action.type);
                        setTime(action.time);
                        setIsFirstHalf(action.isFirstHalf);
                        setIsDirectRedCard(action.isDirectRedCard ?? false);
                        setIsOwnGoal(action.isOwnGoal ?? false);
                        setIsInjurySub(action.isInjurySub ?? false);
                        setIsPenaltyGoal(action.isPenaltyGoal ?? false);
                        if (action.type === 'Substitution') {
                          setInValue(action.name.split(' - ')[0]);
                          setOutValue(action.name.split(' - ')[1]);
                        }
                        setAssistName(action.assistName ?? '');
                        onOpen();
                      }}
                      icon={<EditIcon />}
                    />

                    <IconButton
                      ml="10px"
                      colorScheme="red"
                      aria-label="Delete"
                      icon={<DeleteIcon />}
                      onClick={() => {
                        setIndex(i);
                        openAlert();
                      }}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ActionDetails;
