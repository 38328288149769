import { Box, Flex, Image, Text } from '@chakra-ui/react';
import Cup from '../../components/Icons/Cup';
import { SERVER_URL } from '../../utils/constants';
import { MatchInterface } from '../../utils/types';
import BlueLogo from '../../assets/AL-Blue-Logo.png';
import { Link } from 'react-router-dom';

interface Props {
  matches: Array<MatchInterface>;
}

const returnImage = (team: {
  mascotLogo: string;
  teamLogo: {
    url: string;
  };
}) => {
  return (
    SERVER_URL +
    (team?.teamLogo && team?.teamLogo?.url
      ? team?.teamLogo.url
      : team?.mascotLogo)
  );
};
const teamName = (name: string) => {
  const hasUnderscore = name?.split('_').filter(Boolean);

  if (hasUnderscore?.length > 1) {
    return hasUnderscore[1];
  }
  return name;
};

const KnockoutStage = ({ matches }: Props) => {
  const groups = matches
    .map((m) => {
      let newMatch = m;
      const homeTeam = {
        ...m.homeTeam,
        teams: [
          { ...m.homeTeam.teams[0], name: teamName(m.homeTeam.teams[0].name) },
        ],
      };
      const awayTeam = {
        ...m.awayTeam,
        teams: [
          { ...m.awayTeam.teams[0], name: teamName(m.awayTeam.teams[0].name) },
        ],
      };
      newMatch = { ...m, homeTeam, awayTeam };
      return newMatch;
    })
    ?.reduce(
      (
        acc: {
          [key: string]: Array<MatchInterface>;
        },
        item: MatchInterface
      ) => {
        acc[item.stage]
          ? acc[item.stage].push(item)
          : (acc[item.stage] = [item]);
        return acc;
      },
      {}
    );

  return (
    <Box overflow-y="hidden" pb="10px">
      <Flex>
        <Box position="relative" w="fit-content">
          <Flex>
            <Box
              position="relative"
              w="fit-content"
              visibility={
                groups['Quarter-finals-A']?.length ? 'visible' : 'hidden'
              }
            >
              <Box position="relative" w="fit-content" zIndex="3">
                <Box position="relative" zIndex="2">
                  <Link
                    to={`/match/${groups['Quarter-finals-A']?.[0]?.id}`}
                    style={{ display: 'block' }}
                  >
                    <Flex
                      w="130px"
                      h="40px"
                      alignItems={'center'}
                      bg="gray.300"
                      px="5px"
                    >
                      <Image
                        w="35px"
                        h="35px"
                        src={returnImage(
                          groups['Quarter-finals-A']?.[0].homeTeam?.teams[0]
                        )}
                        fallbackSrc={BlueLogo}
                      />
                      <Text fontSize="sm" ml="5px" fontWeight="bold">
                        {
                          groups['Quarter-finals-A']?.[0].homeTeam?.teams[0]
                            .name
                        }
                      </Text>
                    </Flex>

                    <Flex
                      w="130px"
                      h="40px"
                      alignItems={'center'}
                      bg="gray.300"
                      px="5px"
                      mt="8px"
                    >
                      <Image
                        w="35px"
                        h="35px"
                        src={returnImage(
                          groups['Quarter-finals-A']?.[0].awayTeam?.teams[0]
                        )}
                        fallbackSrc={BlueLogo}
                      />
                      <Text fontSize="sm" ml="5px" fontWeight="bold">
                        {
                          groups['Quarter-finals-A']?.[0].awayTeam?.teams[0]
                            .name
                        }
                      </Text>
                    </Flex>
                  </Link>
                </Box>
                <Box
                  position="absolute"
                  h="48px"
                  w="15px"
                  border="1px solid #272727"
                  borderLeft="0"
                  top="20px"
                  right="-10px"
                />
              </Box>

              <Box position="relative" w="fit-content" zIndex="3" mt="15px">
                <Box position="relative" zIndex="2">
                  <Link
                    to={`/match/${groups['Quarter-finals-A']?.[1]?.id}`}
                    style={{ display: 'block' }}
                  >
                    <Flex
                      w="130px"
                      h="40px"
                      alignItems={'center'}
                      bg="gray.300"
                      px="5px"
                    >
                      <Image
                        w="35px"
                        h="35px"
                        src={returnImage(
                          groups['Quarter-finals-A']?.[1].homeTeam?.teams[0]
                        )}
                        fallbackSrc={BlueLogo}
                      />
                      <Text fontSize="sm" ml="5px" fontWeight="bold">
                        {
                          groups['Quarter-finals-A']?.[1].homeTeam?.teams[0]
                            .name
                        }
                      </Text>
                    </Flex>
                    <Flex
                      w="130px"
                      h="40px"
                      alignItems={'center'}
                      bg="gray.300"
                      px="5px"
                      mt="8px"
                    >
                      <Image
                        w="35px"
                        h="35px"
                        src={returnImage(
                          groups['Quarter-finals-A']?.[1].awayTeam?.teams[0]
                        )}
                        fallbackSrc={BlueLogo}
                      />
                      <Text fontSize="sm" ml="5px" fontWeight="bold">
                        {
                          groups['Quarter-finals-A']?.[1].awayTeam?.teams[0]
                            .name
                        }
                      </Text>
                    </Flex>
                  </Link>
                </Box>
                <Box
                  position="absolute"
                  h="48px"
                  w="15px"
                  border="1px solid #272727"
                  borderLeft="0"
                  top="20px"
                  right="-10px"
                />
              </Box>
              <Box
                position="absolute"
                h="111px"
                w="15px"
                border="1px solid #272727"
                borderLeft="0"
                top="44px"
                right="-25px"
              />
              <Box
                position="absolute"
                h="1px"
                w="25px"
                bg="#272727"
                borderLeft="0"
                top="95px"
                right="-50px"
              />
            </Box>

            <Box position="relative" w="fit-content" ml="50px" top="51px">
              <Box position="relative" w="fit-content" zIndex="3">
                <Box position="relative" zIndex="2">
                  <Link
                    to={`/match/${groups['Semi-finals']?.[0]?.id}`}
                    style={{
                      display: 'block',
                      pointerEvents: groups['Semi-finals']?.length
                        ? 'auto'
                        : 'none',
                    }}
                  >
                    <Box w="130px" h="40px" bg="gray.300" px="5px">
                      {groups['Semi-finals']?.length ||
                      groups['Quarter-finals-A']?.[0].isMatchEnded ? (
                        <Flex alignItems={'center'} w="full" h="full">
                          <Image
                            w="35px"
                            h="35px"
                            src={returnImage(
                              groups['Semi-finals']?.[0].homeTeam.teams[0] ||
                                (groups['Quarter-finals-A']?.[0].homeTeamScore >
                                  groups['Quarter-finals-A']?.[0]
                                    .awayTeamScore ||
                                groups[
                                  'Quarter-finals-A'
                                ]?.[0]?.penalties?.filter(
                                  (x) => x.isHomeTeam && x.type === 'Scored'
                                ).length >
                                  groups[
                                    'Quarter-finals-A'
                                  ]?.[0]?.penalties?.filter(
                                    (x) => !x.isHomeTeam && x.type === 'Scored'
                                  ).length
                                  ? groups['Quarter-finals-A']?.[0].homeTeam
                                      .teams[0]
                                  : groups['Quarter-finals-A']?.[0].awayTeam
                                      .teams[0])
                            )}
                            fallbackSrc={BlueLogo}
                          />
                          <Text fontSize="sm" ml="5px" fontWeight="bold">
                            {groups['Semi-finals']?.[0].homeTeam.teams[0]
                              .name ||
                              (groups['Quarter-finals-A']?.[0].homeTeamScore >
                                groups['Quarter-finals-A']?.[0].awayTeamScore ||
                              groups[
                                'Quarter-finals-A'
                              ]?.[0]?.penalties?.filter(
                                (x) => x.isHomeTeam && x.type === 'Scored'
                              ).length >
                                groups[
                                  'Quarter-finals-A'
                                ]?.[0]?.penalties?.filter(
                                  (x) => !x.isHomeTeam && x.type === 'Scored'
                                ).length
                                ? groups['Quarter-finals-A']?.[0].homeTeam
                                    .teams[0].name
                                : groups['Quarter-finals-A']?.[0].awayTeam
                                    .teams[0].name)}
                          </Text>
                        </Flex>
                      ) : null}
                    </Box>
                    <Box w="130px" h="40px" bg="gray.300" px="5px" mt="8px">
                      {groups['Semi-finals']?.length ||
                      groups['Quarter-finals-A']?.[1].isMatchEnded ? (
                        <Flex alignItems={'center'} w="full" h="full">
                          <Image
                            w="35px"
                            h="35px"
                            src={returnImage(
                              groups['Semi-finals']?.[0].awayTeam.teams[0] ||
                                (groups['Quarter-finals-A']?.[1].homeTeamScore <
                                  groups['Quarter-finals-A']?.[1]
                                    .awayTeamScore ||
                                groups[
                                  'Quarter-finals-A'
                                ]?.[1]?.penalties?.filter(
                                  (x) => x.isHomeTeam && x.type === 'Scored'
                                ).length >
                                  groups[
                                    'Quarter-finals-A'
                                  ]?.[1]?.penalties?.filter(
                                    (x) => !x.isHomeTeam && x.type === 'Scored'
                                  ).length
                                  ? groups['Quarter-finals-A']?.[1].homeTeam
                                      .teams[0]
                                  : groups['Quarter-finals-A']?.[1].awayTeam
                                      .teams[0])
                            )}
                            fallbackSrc={BlueLogo}
                          />
                          <Text fontSize="sm" ml="5px" fontWeight="bold">
                            {groups['Semi-finals']?.[0].awayTeam.teams[0]
                              .name ||
                              (groups['Quarter-finals-A']?.[1].homeTeamScore <
                                groups['Quarter-finals-A']?.[1].awayTeamScore ||
                              groups[
                                'Quarter-finals-A'
                              ]?.[1]?.penalties?.filter(
                                (x) => x.isHomeTeam && x.type === 'Scored'
                              ).length >
                                groups[
                                  'Quarter-finals-A'
                                ]?.[1]?.penalties?.filter(
                                  (x) => !x.isHomeTeam && x.type === 'Scored'
                                ).length
                                ? groups['Quarter-finals-A']?.[1].homeTeam
                                    .teams[0].name
                                : groups['Quarter-finals-A']?.[1].awayTeam
                                    .teams[0].name)}
                          </Text>
                        </Flex>
                      ) : null}
                    </Box>
                  </Link>
                </Box>
                <Box
                  position="absolute"
                  h="48px"
                  w="15px"
                  border="1px solid #272727"
                  borderLeft="0"
                  top="20px"
                  right="-10px"
                />
              </Box>
            </Box>
          </Flex>

          <Flex mt="40px">
            <Box
              position="relative"
              w="fit-content"
              visibility={
                groups['Quarter-finals-A']?.length ? 'visible' : 'hidden'
              }
            >
              <Box position="relative" w="fit-content" zIndex="3">
                <Box position="relative" zIndex="2">
                  <Link
                    to={`/match/${groups['Quarter-finals-B']?.[0]?.id}`}
                    style={{ display: 'block' }}
                  >
                    <Flex
                      w="130px"
                      h="40px"
                      alignItems={'center'}
                      bg="gray.300"
                      px="5px"
                    >
                      <Image
                        w="35px"
                        h="35px"
                        src={returnImage(
                          groups['Quarter-finals-B']?.[0].homeTeam?.teams[0]
                        )}
                        fallbackSrc={BlueLogo}
                      />
                      <Text fontSize="sm" ml="5px" fontWeight="bold">
                        {
                          groups['Quarter-finals-B']?.[0].homeTeam?.teams[0]
                            .name
                        }
                      </Text>
                    </Flex>
                    <Flex
                      w="130px"
                      h="40px"
                      alignItems={'center'}
                      bg="gray.300"
                      px="5px"
                      mt="8px"
                    >
                      <Image
                        w="35px"
                        h="35px"
                        src={returnImage(
                          groups['Quarter-finals-B']?.[0].awayTeam?.teams[0]
                        )}
                        fallbackSrc={BlueLogo}
                      />
                      <Text fontSize="sm" ml="5px" fontWeight="bold">
                        {
                          groups['Quarter-finals-B']?.[0].awayTeam?.teams[0]
                            .name
                        }
                      </Text>
                    </Flex>
                  </Link>
                </Box>
                <Box
                  position="absolute"
                  h="48px"
                  w="15px"
                  border="1px solid #272727"
                  borderLeft="0"
                  top="20px"
                  right="-10px"
                />
              </Box>

              <Box position="relative" w="fit-content" zIndex="3" mt="15px">
                <Box position="relative" zIndex="2">
                  <Link
                    to={`/match/${groups['Quarter-finals-B']?.[1]?.id}`}
                    style={{ display: 'block' }}
                  >
                    <Flex
                      w="130px"
                      h="40px"
                      alignItems={'center'}
                      bg="gray.300"
                      px="5px"
                    >
                      <Image
                        w="35px"
                        h="35px"
                        src={returnImage(
                          groups['Quarter-finals-B']?.[1].homeTeam?.teams[0]
                        )}
                        fallbackSrc={BlueLogo}
                      />
                      <Text fontSize="sm" ml="5px" fontWeight="bold">
                        {
                          groups['Quarter-finals-B']?.[1].homeTeam?.teams[0]
                            .name
                        }
                      </Text>
                    </Flex>
                    <Flex
                      w="130px"
                      h="40px"
                      alignItems={'center'}
                      bg="gray.300"
                      px="5px"
                      mt="8px"
                    >
                      <Image
                        w="35px"
                        h="35px"
                        src={returnImage(
                          groups['Quarter-finals-B']?.[1].awayTeam?.teams[0]
                        )}
                        fallbackSrc={BlueLogo}
                      />
                      <Text fontSize="sm" ml="5px" fontWeight="bold">
                        {
                          groups['Quarter-finals-B']?.[1].awayTeam?.teams[0]
                            .name
                        }
                      </Text>
                    </Flex>
                  </Link>
                </Box>
                <Box
                  position="absolute"
                  h="48px"
                  w="15px"
                  border="1px solid #272727"
                  borderLeft="0"
                  top="20px"
                  right="-10px"
                />
              </Box>
              <Box
                position="absolute"
                h="111px"
                w="15px"
                border="1px solid #272727"
                borderLeft="0"
                top="44px"
                right="-25px"
              />
              <Box
                position="absolute"
                h="1px"
                w="25px"
                bg="#272727"
                borderLeft="0"
                top="95px"
                right="-50px"
              />
            </Box>

            <Box position="relative" w="fit-content" ml="50px" top="43px">
              <Box position="relative" w="fit-content" zIndex="3">
                <Box position="relative" zIndex="2">
                  <Link
                    to={`/match/${groups['Semi-finals']?.[1]?.id}`}
                    style={{
                      display: 'block',
                      pointerEvents: groups['Semi-finals']?.length
                        ? 'auto'
                        : 'none',
                    }}
                  >
                    <Box w="130px" h="40px" bg="gray.300" px="5px" mt="8px">
                      {groups['Semi-finals']?.length ||
                      groups['Quarter-finals-B']?.[0].isMatchEnded ? (
                        <Flex alignItems={'center'} w="full" h="full">
                          <Image
                            w="35px"
                            h="35px"
                            src={returnImage(
                              groups['Semi-finals']?.[1].homeTeam.teams[0] ||
                                (groups['Quarter-finals-B']?.[0].homeTeamScore >
                                  groups['Quarter-finals-B']?.[0]
                                    .awayTeamScore ||
                                groups[
                                  'Quarter-finals-B'
                                ]?.[0]?.penalties?.filter(
                                  (x) => x.isHomeTeam && x.type === 'Scored'
                                ).length >
                                  groups[
                                    'Quarter-finals-B'
                                  ]?.[0]?.penalties?.filter(
                                    (x) => !x.isHomeTeam && x.type === 'Scored'
                                  ).length
                                  ? groups['Quarter-finals-B']?.[0].homeTeam
                                      .teams[0]
                                  : groups['Quarter-finals-B']?.[0].awayTeam
                                      .teams[0])
                            )}
                            fallbackSrc={BlueLogo}
                          />
                          <Text fontSize="sm" ml="5px" fontWeight="bold">
                            {groups['Semi-finals']?.[1].homeTeam.teams[0]
                              .name ||
                              (groups['Quarter-finals-B']?.[0].homeTeamScore >
                                groups['Quarter-finals-B']?.[0].awayTeamScore ||
                              groups[
                                'Quarter-finals-B'
                              ]?.[0]?.penalties?.filter(
                                (x) => x.isHomeTeam && x.type === 'Scored'
                              ).length >
                                groups[
                                  'Quarter-finals-B'
                                ]?.[0]?.penalties?.filter(
                                  (x) => !x.isHomeTeam && x.type === 'Scored'
                                ).length
                                ? groups['Quarter-finals-B']?.[0].homeTeam
                                    .teams[0].name
                                : groups['Quarter-finals-B']?.[0].awayTeam
                                    .teams[0].name)}
                          </Text>
                        </Flex>
                      ) : null}
                    </Box>
                    <Box w="130px" h="40px" bg="gray.300" px="5px" mt="8px">
                      {groups['Semi-finals']?.length ||
                      groups['Quarter-finals-B']?.[1].isMatchEnded ? (
                        <Flex alignItems={'center'} w="full" h="full">
                          <Image
                            w="35px"
                            h="35px"
                            src={returnImage(
                              groups['Semi-finals']?.[1].awayTeam.teams[0] ||
                                (groups['Quarter-finals-B']?.[1].homeTeamScore >
                                  groups['Quarter-finals-B']?.[1]
                                    .awayTeamScore ||
                                groups[
                                  'Quarter-finals-B'
                                ]?.[1]?.penalties?.filter(
                                  (x) => x.isHomeTeam && x.type === 'Scored'
                                ).length >
                                  groups[
                                    'Quarter-finals-B'
                                  ]?.[1]?.penalties?.filter(
                                    (x) => !x.isHomeTeam && x.type === 'Scored'
                                  ).length
                                  ? groups['Quarter-finals-B']?.[1].homeTeam
                                      .teams[0]
                                  : groups['Quarter-finals-B']?.[1].awayTeam
                                      .teams[0])
                            )}
                            fallbackSrc={BlueLogo}
                          />
                          <Text fontSize="sm" ml="5px" fontWeight="bold">
                            {groups['Semi-finals']?.[1].awayTeam.teams[0]
                              .name ||
                              (groups['Quarter-finals-B']?.[1].homeTeamScore >
                                groups['Quarter-finals-B']?.[1].awayTeamScore ||
                              groups[
                                'Quarter-finals-B'
                              ]?.[1]?.penalties?.filter(
                                (x) => x.isHomeTeam && x.type === 'Scored'
                              ).length >
                                groups[
                                  'Quarter-finals-B'
                                ]?.[1]?.penalties?.filter(
                                  (x) => !x.isHomeTeam && x.type === 'Scored'
                                ).length
                                ? groups['Quarter-finals-B']?.[1].homeTeam
                                    .teams[0].name
                                : groups['Quarter-finals-B']?.[1].awayTeam
                                    .teams[0].name)}
                          </Text>
                        </Flex>
                      ) : null}
                    </Box>
                  </Link>
                </Box>
                <Box
                  position="absolute"
                  h="48px"
                  w="15px"
                  border="1px solid #272727"
                  borderLeft="0"
                  top="20px"
                  right="-10px"
                />
              </Box>
            </Box>
          </Flex>
          <Box
            position="absolute"
            h="232px"
            w="15px"
            border="1px solid #272727"
            borderLeft="0"
            top="94px"
            right="-25px"
          />
          <Box
            position="absolute"
            h="1px"
            w="25px"
            bg="#272727"
            borderLeft="0"
            top="210px"
            right="-50px"
          />
        </Box>

        <Box position="relative" zIndex="2" top="158px" ml="50px">
          <Box
            position="absolute"
            top="-100px"
            left="4px"
            minW="120px"
            textAlign={'center'}
          >
            <Cup boxSize="50px" color="orange.500" />
            <Text fontWeight={'extrabold'} mt="5px">
              FINAL MATCH
            </Text>
          </Box>
          <Link
            to={`/match/${groups['Final']?.[0]?.id}`}
            style={{
              display: 'block',
              pointerEvents: groups['Final']?.length ? 'auto' : 'none',
            }}
          >
            <Box w="130px" h="40px" bg="gray.300" px="5px" mt="8px">
              {groups['Final']?.length ||
              groups['Semi-finals']?.[0].isMatchEnded ? (
                <Flex alignItems={'center'} w="full" h="full">
                  <Image
                    w="35px"
                    h="35px"
                    src={returnImage(
                      groups['Final']?.[0].homeTeam.teams[0] ||
                        (groups['Semi-finals']?.[0].homeTeamScore >
                        groups['Semi-finals']?.[0].awayTeamScore
                          ? groups['Semi-finals']?.[0].homeTeam.teams[0]
                          : groups['Semi-finals']?.[0].awayTeam.teams[0])
                    )}
                    fallbackSrc={BlueLogo}
                  />
                  <Text fontSize="sm" ml="5px" fontWeight="bold">
                    {groups['Final']?.[0].homeTeam.teams[0].name ||
                      (groups['Semi-finals']?.[0].homeTeamScore >
                      groups['Semi-finals']?.[0].awayTeamScore
                        ? groups['Semi-finals']?.[0].homeTeam.teams[0].name
                        : groups['Semi-finals']?.[0].awayTeam.teams[0].name)}
                  </Text>
                </Flex>
              ) : null}
            </Box>
            <Box w="130px" h="40px" bg="gray.300" px="5px" mt="8px">
              {groups['Final']?.length ||
              groups['Semi-finals']?.[1].isMatchEnded ? (
                <Flex alignItems={'center'} w="full" h="full">
                  <Image
                    w="35px"
                    h="35px"
                    src={returnImage(
                      groups['Final']?.[0].awayTeam.teams[0] ||
                        (groups['Semi-finals']?.[1].homeTeamScore >
                        groups['Semi-finals']?.[1].awayTeamScore
                          ? groups['Semi-finals']?.[1].homeTeam.teams[0]
                          : groups['Semi-finals']?.[1].awayTeam.teams[0])
                    )}
                    fallbackSrc={BlueLogo}
                  />
                  <Text fontSize="sm" ml="5px" fontWeight="bold">
                    {groups['Final']?.[0].awayTeam.teams[0].name ||
                      (groups['Semi-finals']?.[1].homeTeamScore >
                      groups['Semi-finals']?.[1].awayTeamScore
                        ? groups['Semi-finals']?.[1].homeTeam.teams[0].name
                        : groups['Semi-finals']?.[1].awayTeam.teams[0].name)}
                  </Text>
                </Flex>
              ) : null}
            </Box>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default KnockoutStage;
