import { Box, Image, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  getImageFile: (file: Array<File>) => void;
  preview?: string;
  height?: string;
  width?: string;
  maxW?: string;
  placeholder?: string;
  borderRadius?: string;
}

const FileUpload = ({
  getImageFile,
  preview,
  height,
  width,
  maxW,
  placeholder,
  borderRadius,
}: Props) => {
  const [files, setFiles] = useState<Array<any>>([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const customFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles(customFiles);
      getImageFile(customFiles);
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const returnPreview = (): string | null => {
    if (files && files.length > 0) {
      return files[0].preview;
    }
    if (preview) {
      return preview;
    }
    return null;
  };

  return (
    <Box
      width={width || '100%'}
      height={height || '200px'}
      display="flex"
      bg={'gray.100'}
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      overflow="hidden"
      borderRadius={borderRadius}
      {...getRootProps({
        className: 'dropzone',
      })}
    >
      <input
        {...getInputProps({
          onClick: (e) => e.stopPropagation(),
        })}
      />

      {returnPreview() ? (
        <Box maxW="100%">
          <Image
            boxSize={width || '100%'}
            src={returnPreview() as string}
            fit="cover"
            maxH={height || '200px'}
          />
        </Box>
      ) : (
        <Text textAlign="center" maxW={maxW || '80%'}>
          {placeholder
            ? placeholder
            : 'Include a good image in your profile to make it more inviting to other teams.'}
        </Text>
      )}
    </Box>
  );
};

export default FileUpload;
