import { action, Action } from 'easy-peasy';
import { ApplicationDataType } from '../utils/types';

export interface AppModel {
  applicationData: ApplicationDataType | null;
  competitionName: string;
  setApplicationData: Action<AppModel, ApplicationDataType | null>;
  setCompetitionName: Action<AppModel, string>;
}

const applicationData: AppModel = {
  applicationData: null,
  competitionName: '',
  setApplicationData: action((state, payload) => {
    state.applicationData = payload;
  }),
  setCompetitionName: action((state, payload) => {
    state.competitionName = payload;
  }),
};

export default applicationData;
