import dayjs from 'dayjs';
import { useMemo } from 'react';
import { MatchInterface } from '../../utils/types';
import Match from './Match';

interface Props {
  list: Array<MatchInterface>;
}
const Matches = ({ list }: Props) => {
  const sortedMatches = useMemo(() => {
    return [...list]
      ?.filter(
        (m: MatchInterface) =>
          m.stage === 'Group Stage' ||
          m.stage === 'Knockout Stage - Round of 16'
      )
      .sort((a: MatchInterface, b: MatchInterface) => {
        return dayjs(a.matchDate).isAfter(dayjs(b.matchDate)) ? 1 : -1;
      });
  }, [list]);

  return list && list.length ? (
    <>
      {sortedMatches.map((match: MatchInterface) => {
        return <Match match={match} key={match.id} />;
      })}
    </>
  ) : null;
};

export default Matches;
