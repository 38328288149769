import { useLazyQuery } from '@apollo/client';
import { GET_TEAM_UNIQUE, GET_PLAYERS_UNIQUE } from '../utils/queries';
import { useCallback } from 'react';

interface RegistrationChecker {
  email?: string;
  phoneNumber?: string;
  playerID?: string;
  teamName?: string;
}
export function useRegistration(): {
  checkExistanceCallback: ({
    email,
    phoneNumber,
    playerID,
    teamName,
  }: RegistrationChecker) => Promise<void>;
} {
  const [getPlayers] = useLazyQuery(GET_PLAYERS_UNIQUE);
  const [getTeam] = useLazyQuery(GET_TEAM_UNIQUE);

  const checkExistanceCallback = useCallback(
    async ({ email, phoneNumber, playerID, teamName }: RegistrationChecker) => {
      if (teamName) {
        const foundTeam = await getTeam({
          variables: { where: { name: teamName } },
        });
        if (foundTeam?.data?.team?.id) {
          throw new Error(
            'Team already exists with this name, please choose another name'
          );
        }
      }
      if (email) {
        const { data } = await getPlayers({
          variables: { where: { email: { equals: email } } },
        });
        if (data?.players.length) {
          throw new Error(
            'Player already exists with this email address, Please login if you want to continue or use another email'
          );
        }
      }
      if (phoneNumber) {
        const { data } = await getPlayers({
          variables: { where: { phoneNumber: { equals: phoneNumber } } },
        });
        if (data?.players.length) {
          throw new Error('Player already exists with this phone number');
        }
      }

      if (playerID) {
        const { data } = await getPlayers({
          variables: { where: { playerID: { equals: playerID } } },
        });
        if (data?.players.length) {
          throw new Error('Player already exists with this player ID');
        }
      }
    },
    [getPlayers, getTeam]
  );
  return { checkExistanceCallback };
}
