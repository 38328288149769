import { useQuery } from '@apollo/client';
import { Box, Flex, Stack, Tab, TabList, Tabs } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { useStoreActions, useStoreState } from '../../store';
import { FETCH_MATCH_BY_ID } from '../../utils/queries';
import { MatchInterface } from '../../utils/types';
import Footer from '../Footer';
import Loader from '../Loader';
import Sidebar from '../Navigation';
import Lineup from './Lineup';
import MatchInfo from './MarchInfo';
import MatchBanner from './MatchBanner';
import Stats from './Stats';
import YouTube from 'react-youtube';
import { youtube_parser } from '../../utils/helper';

const MatchPage = () => {
  const { id } = useParams();
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);
  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeIndex = (index: number) => setTabIndex(index);
  const { data: matchData, loading } = useQuery(FETCH_MATCH_BY_ID, {
    variables: { id },
  });

  const match: MatchInterface | null = matchData?.match;

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);
  useEffect(() => {
    let interval: any;
    if (matchData) {
      setTabIndex(1);
      interval = setTimeout(() => {
        setTabIndex(0);
      }, 10);
    }
    return () => {
      clearInterval(interval);
    };
  }, [matchData]);

  console.log(match?.videosUrls);

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt={{ base: '125px', md: '125px' }}
        display="flex"
        justifyContent={'space-between'}
      >
        {!match && loading ? (
          <Loader />
        ) : (
          <Box>
            {match ? <MatchBanner match={match} /> : null}
            <Box p={3} w="100%" maxW={'600px'} m={'auto'} mb="100px">
              <Tabs
                variant="line"
                colorScheme="green"
                onChange={handleChangeIndex}
                index={tabIndex}
              >
                <TabList>
                  <Tab
                    fontSize={['xs', 'sm', 'md', 'md']}
                    padding={['10px', '15px']}
                  >
                    {'Match Info'}
                  </Tab>
                  <Tab
                    fontSize={['xs', 'sm', 'md', 'md']}
                    padding={['10px', '15px']}
                  >
                    {'Lineup'}
                  </Tab>
                  <Tab
                    fontSize={['xs', 'sm', 'md', 'md']}
                    padding={['10px', '15px']}
                  >
                    {'Videos'}
                  </Tab>
                  <Tab
                    fontSize={['xs', 'sm', 'md', 'md']}
                    padding={['10px', '15px']}
                  >
                    {'Statistics'}
                  </Tab>
                </TabList>
              </Tabs>
              <Box>
                <SwipeableViews
                  index={tabIndex}
                  onChangeIndex={handleChangeIndex}
                  axis={'x'}
                  animateHeight
                >
                  <Box py={'20px'} h={'fit-content'}>
                    {match ? <MatchInfo match={match} /> : null}
                  </Box>
                  <Box py={'20px'} h={'fit-content'}>
                    {match ? <Lineup match={match} /> : null}
                  </Box>
                  <Box py={'20px'} h={'fit-content'}>
                    <Flex direction={{ base: 'column', md: 'column' }} gap="3">
                      {match?.videosUrls &&
                        match?.videosUrls.split(',')?.map((url) => {
                          const id = youtube_parser(url);
                          if (!id) return null;
                          return (
                            <Box w="100%" h="fit-content">
                              <YouTube
                                videoId={id}
                                opts={{
                                  height: '400',
                                  width: '100%',
                                  playerVars: {
                                    autoplay: 0,
                                  },
                                }}
                              />
                            </Box>
                          );
                        })}
                    </Flex>
                  </Box>
                  <Box py={'20px'} h={'fit-content'}>
                    {match ? <Stats match={match} /> : null}
                  </Box>
                </SwipeableViews>
              </Box>
            </Box>
          </Box>
        )}
        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default MatchPage;
