import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import HeadingTitle from '../components/HeadingTitle';
import { useStoreState, useStoreActions } from '../store';
import { FETCH_TRANSFER_MARKET } from '../utils/queries';
import Footer from './Footer';
import Sidebar from './Navigation';
import Loader from './Loader';
import { PlayerInterface } from './Player';
import { greenishColor, redishColor } from '../utils/theme';
import useLeaguesState from '../hooks/useLeaguesState';
import useDebounce from '../hooks/useDebounce';
import { REQUEST_PLAYER } from '../utils/mutations';
import { timeout } from '../utils/helper';
import usePlayerInfo from '../hooks/usePlayerInfo';

const TransferMarket = () => {
  const toast = useToast();
  const playerInfo = usePlayerInfo();
  const [searchName, setSearchName] = useState('');
  const [comp, setComp] = useState('');
  const [requestPlayer] = useMutation(REQUEST_PLAYER);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const {
    currentSeason,
    currentTeam,
    currentParticipation,
    notInCurrentSeason,
    publicCompetitions,
  } = useLeaguesState(comp);
  const debouncedPageSize = useDebounce(pageSize, 300);
  const debouncedSearchName = useDebounce(searchName, 500);

  const { data: playersData, loading: playersLoading } = useQuery(
    FETCH_TRANSFER_MARKET,
    {
      variables: {
        name: currentSeason?.name,
        transferMarketName: 'Transfer Market',
        firstName: debouncedSearchName?.split(' ')[0] ?? '',
        lastName: debouncedSearchName?.split(' ')[1]
          ? debouncedSearchName?.split(' ')[1]
          : debouncedSearchName?.split(' ')[0] ?? '',
        take: debouncedPageSize,
        skip: page * debouncedPageSize,
      },
    }
  );

  const teamPlayers: Array<PlayerInterface> | null = playersData?.players;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [playerEmail, setPlayerEmail] = useState('');
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const isLoading = playersLoading;
  const handleChange = (e: any) => {
    setPageSize(Number(e.target.value));
  };

  const handleSelectComp = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setComp(e.target.value);
  };

  const handleRequestPlayer = async (email: string) => {
    if (!currentTeam || !email) return;
    setIsSubmitting(true);
    try {
      const res = await requestPlayer({
        variables: {
          email: email.toLowerCase(),
          teamName: currentTeam?.name,
          teamSecurityCode: currentParticipation?.id,
        },
      });

      if (res.data && res.data.requestPlayer && res.data.requestPlayer.id) {
        toast({
          title: 'Request has been sent to the player',
          status: 'success',
          isClosable: true,
        });

        await timeout(2000);
        setIsSubmitting(false);
      } else {
        toast({
          title: 'Something went wrong',
          status: 'error',
          isClosable: true,
        });
        setIsSubmitting(false);
      }
    } catch (error: any) {
      console.log(error);
      toast({
        title: error.message,
        status: 'error',
        isClosable: true,
      });
      setIsSubmitting(false);
    } finally {
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
    setComp('');
    setPlayerEmail('');
  };

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt={{ base: '125px', md: '200px' }}
        display="flex"
        justifyContent={'space-between'}
      >
        <Modal
          isOpen={isOpen}
          onClose={handleClose}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Join / Migrate League</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box maxW="350px" m="0 auto 20px">
                <Select fontWeight={'bold'} onChange={handleSelectComp}>
                  <option value="">Choose League</option>
                  {publicCompetitions?.map((comp) => (
                    <option
                      key={comp.id}
                      value={comp.name}
                      disabled={comp.disableRegistration}
                    >
                      {comp.name}
                    </option>
                  ))}
                </Select>
              </Box>
              {comp && !notInCurrentSeason ? (
                <Box>
                  You want to request this player for team{' '}
                  <Text as="span" fontWeight="bold" color={redishColor}>
                    {currentSeason?.name} {currentTeam?.name}
                  </Text>
                </Box>
              ) : (
                <Box>
                  <b>Unfortunately!</b> you are not pariticpating in any team
                  this season therefore you cannot request this player.
                </Box>
              )}
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" onClick={handleClose}>
                Cancel
              </Button>

              <Button
                ml={3}
                bg={greenishColor}
                fontWeight="normal"
                color={'white'}
                onClick={() => handleRequestPlayer(playerEmail)}
                isDisabled={notInCurrentSeason || isSubmitting}
                _hover={{ bg: greenishColor, opacity: '0.9' }}
              >
                Add Player
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {isLoading ? (
          <Loader />
        ) : (
          <Box>
            <Box textAlign={'center'} mb="50px">
              <HeadingTitle>{'Transfer Market'}</HeadingTitle>
            </Box>

            {teamPlayers ? (
              <Box padding="0 15px" mt="20px">
                <Box mt="15px" maxW="400px" margin={'20px auto'}>
                  <Input
                    size="lg"
                    value={searchName}
                    fontWeight="bold"
                    onChange={(e) => setSearchName(e.target.value)}
                    placeholder="Search Players"
                    textAlign={'center'}
                  />
                </Box>
                <Box
                  border="1px"
                  borderColor={borderColor}
                  maxW="1200px"
                  margin="0 auto"
                  rounded="10px"
                >
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Player</Th>
                          <Th>Position</Th>
                          <Th>Strongest Skill</Th>
                          <Th>Ability</Th>
                          <Th>Preferred Foot</Th>
                          {playerInfo?.isCaptain ? <Th>Action</Th> : null}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {teamPlayers.map((player: PlayerInterface) => {
                          return (
                            <Tr key={player.id}>
                              <Td
                                textTransform={'capitalize'}
                                fontWeight="bold"
                              >
                                {player.firstName} {player.lastName}{' '}
                                {player.isCaptain ? '(C)' : null}
                              </Td>
                              <Td>
                                {player.position}/{player.secondPosition}
                              </Td>
                              <Td>{player.skill}</Td>
                              <Td>{player.ability}</Td>
                              <Td>{player.preferredFoot}</Td>
                              {playerInfo?.isCaptain ? (
                                <Td>
                                  <Button
                                    bg={redishColor}
                                    color={'white'}
                                    _hover={{ bg: 'red.700' }}
                                    fontWeight="normal"
                                    onClick={() => {
                                      setPlayerEmail(player.email);
                                      onOpen();
                                    }}
                                    isLoading={isSubmitting}
                                    disabled={isSubmitting}
                                  >
                                    Request Player
                                  </Button>
                                </Td>
                              ) : null}
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
                <Flex justifyContent={'flex-end'} m="20px auto" maxW="1200px">
                  <Flex gap={'10px'} alignItems="center">
                    <Text>{`${page > 0 ? pageSize * (page + 1) : pageSize} of ${
                      playersData?.playersCount
                    }`}</Text>
                    <Button
                      disabled={!page}
                      background={greenishColor}
                      color={'white'}
                      _hover={{ bg: 'green.600' }}
                      onClick={() => setPage((p) => p - 1)}
                    >
                      Prev
                    </Button>
                    <Input
                      type="number"
                      onChange={handleChange}
                      value={pageSize}
                      w="80px"
                      textAlign={'center'}
                    />
                    <Button
                      disabled={
                        pageSize * (page + 1) >= playersData?.playersCount
                      }
                      color={'white'}
                      _hover={{ bg: 'green.600' }}
                      background={greenishColor}
                      onClick={() => setPage((p) => p + 1)}
                    >
                      Next
                    </Button>
                  </Flex>
                </Flex>
              </Box>
            ) : null}
          </Box>
        )}

        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default TransferMarket;
