import { Box, Button, Center, Heading, Stack, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Sidebar from './Navigation';
import usePlayerInfo from '../hooks/usePlayerInfo';
import Footer from './Footer';

export default function Thankyou() {
  const playerInfo = usePlayerInfo();
  const params = useParams();
  const [searchParams] = useSearchParams();

  return (
    <Sidebar>
      <Box bg="white" w="full" minH="100vh" pt={{ base: '84px', md: '95px' }}>
        <Stack justifyContent={'space-between'} minH="92vh">
          <Box
            textAlign="center"
            py={10}
            px={6}
            maxW="991px"
            margin="80px auto"
          >
            <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
            <Heading as="h2" size="xl" mt={6} mb={2}>
              Thank you for being a part of {params?.comp}{' '}
              {searchParams.get('season')}
            </Heading>
            <Text color={'gray.500'}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </Text>
            <Center mt="30px">
              {playerInfo?.id ? (
                <Link to={`/joined-teams`}>
                  <Button colorScheme="teal" rounded="full">
                    Visit your joined leagues
                  </Button>
                </Link>
              ) : (
                <Link to="/login">
                  <Button colorScheme="teal" rounded="full">
                    Go to Login
                  </Button>
                </Link>
              )}
            </Center>
          </Box>
          <Footer />
        </Stack>
      </Box>
    </Sidebar>
  );
}
