import {
  Box,
  Container,
  Heading,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import {
  DocumentRenderer,
  DocumentRendererProps,
} from '@keystone-6/document-renderer';

export default function LeagueRules({ data }: { data: any }) {
  const renderers: DocumentRendererProps['renderers'] = {
    block: {
      heading({ level, children }) {
        return (
          <Heading as={`h${level}`} fontSize="3xl" mt="30px" mb="10px">
            {children}
          </Heading>
        );
      },
      list({ children }) {
        return (
          <UnorderedList>
            {children.map((x) => (
              <ListItem fontSize="lg" key={x.key} mb="10px">
                {x}
              </ListItem>
            ))}
          </UnorderedList>
        );
      },
    },
  };
  return (
    <Box p={4}>
      <Container maxW={'5xl'} mt={10}>
        {data ? (
          <DocumentRenderer document={data?.document} renderers={renderers} />
        ) : null}
      </Container>
    </Box>
  );
}
