import WhiteLogo from '../assets/white-logo.png';

import {
  Box,
  chakra,
  Container,
  Image,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { ReactNode } from 'react';
import { greenishColor } from '../utils/theme';
import { LinkItems } from './Navigation';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('whiteAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('whiteAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer({ marginTop = '100px' }) {
  return (
    <Box bg={greenishColor} color={'white'} mt={marginTop}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        spacing={4}
        justify={'center'}
        align={'center'}
      >
        <Box overflow={'hidden'} height="80px" mt="20px">
          <Image
            w={{ base: 180, md: 180 }}
            src={WhiteLogo}
            position="relative"
            top="-50px"
          />
        </Box>
        <Stack direction={'row'} spacing={6} flexWrap="wrap">
          {LinkItems.map((link) => {
            return (
              <Box as="a" href={link.to} key={link.name}>
                {link.name}
              </Box>
            );
          })}
        </Stack>
      </Container>

      <Box
        borderTopWidth={0.5}
        borderStyle={'solid'}
        borderColor={'gray.400'}
        mt="20px"
      >
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}
        >
          <Text>
            © {new Date().getFullYear()} Amateurs League. All rights reserved -
            Created by 3Bont
          </Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton
              label={'Facebook'}
              href="https://www.facebook.com/amateurlseague?mibextid=LQQJ4d"
            >
              <FaFacebook />
            </SocialButton>
            <SocialButton
              label={'Instagram'}
              href={
                'https://www.instagram.com/_amateursleague?igsh=NXMwNm1oNmZ0bjJx'
              }
            >
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
